import {
    //TYPES
    ADD_FILE_SEGUIMIENTO_OPEN,
    ADD_FILE_SEGUIMIENTO_CLOSE,
    ADD_FILE_SEGUIMIENTO_SAVE_RESULT,
    ADD_FILE_SEGUIMIENTO_SAVE
} from 'config/redux/actionTypes';

export const addFileSeguimientoOpen = (fileData, track) => ({
    type: ADD_FILE_SEGUIMIENTO_OPEN,
    payload: { fileData, track }
});

export const addFileSeguimientoClose = () => ({
    type: ADD_FILE_SEGUIMIENTO_CLOSE,
});

export const addFileSeguimientoSave = (data) => ({
    type: ADD_FILE_SEGUIMIENTO_SAVE,
    payload: {data}
});

export const addFileSeguimientoSaveResult = (result) => ({
    type: ADD_FILE_SEGUIMIENTO_SAVE_RESULT,
    payload: result
});