import {
    //TYPES
    ADD_FILE_SEGUIMIENTO_OPEN,
    ADD_FILE_SEGUIMIENTO_CLOSE,
    ADD_FILE_SEGUIMIENTO_SAVE_RESULT,
    ADD_FILE_SEGUIMIENTO_SAVE,
    CLEAR_REDUX
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    fileData: null,
    track: null,
    result: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ADD_FILE_SEGUIMIENTO_OPEN:
            return { ...state, isOpen: true, fileData: action.payload.fileData, track: action.payload.track }

        case ADD_FILE_SEGUIMIENTO_CLOSE:
            return { ...state, ...INIT_STATE }

        case ADD_FILE_SEGUIMIENTO_SAVE:
            return { ...state, loading: true, result: null, }

        case ADD_FILE_SEGUIMIENTO_SAVE_RESULT:
            return { ...state, loading: false, result: action.payload }

        case CLEAR_REDUX:
            return ["", "ADD_FILE_SEGUIMIENTO"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
