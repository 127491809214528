//imports
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { clientQuery, clientMutation } from "config/helpers/GraphQLApi";
import { endpoints } from "config/constants";

import { ExceptionManager } from "config/helpers/Logging";

//action types
import {
  LOGIN_LOGIN,
  LOGIN_RECOVER_PASSWORD,
} from "config/redux/actionTypes";

//actions
import {
  loginSingInResult,
  recoverPasswordResult,
  clearRedux,
} from "config/redux/actions";

function* loginLoginRequest() {
    yield takeEvery(LOGIN_LOGIN, function* ({ payload }) {
        try {
            const { email, password, history } = payload;
            let data = yield call(
                clientMutation,
                `mutation login ($email: String, $password: String) {
                    login: login(email: $email, password: $password) {
                        token
                    }
                }`,
                {
                    email,
                    password
                },
                endpoints.GRAPHQL_AUTH,
                {}
            );

            if (data && data.login?.token) {
                localStorage.setItem("token", data.login.token)
                history && history.push('/'); 
                //yield put(loginSingInResult({ status: true }));
                yield put(clearRedux("LOGIN"));
            }
            else
                throw Error("Incorrect Username or Password");

        } catch (exc) {
            ExceptionManager(exc, 'security/views/login/redux/saga', 'loginLoginRequest');
            yield put(loginSingInResult({ status: false, error: exc }));

        }
    });
}

function* recoverPasswordSendEmailRequest() {
  yield takeEvery(LOGIN_RECOVER_PASSWORD, function* ({ payload }) {
    try {
      const { email } = payload;
      let result = yield call(
        clientMutation,
        `mutation sendPasswordByEmail(
          $email: String,
          ){
        isEmailSent: sendPasswordByEmail(
          email: $email
          )
        }`,
        {
          email,
        },
        endpoints.GRAPHQL_AUTH,
        {}
      );
      if (result.isEmailSent){
        yield put(recoverPasswordResult({status: true}));
        console.log("enviado")
      } 
      else {
        yield put(recoverPasswordResult({status: false, error: "Ocurrió un error, por favor inténtelo de nuevo."}));
        console.log("error")
      }
    } catch (exc) {
      ExceptionManager(
        exc,
        "security/views/login/redux/saga",
        "recoverPasswordSendEmailRequest"
      );
      console.log("error API")
        yield put(recoverPasswordResult({status: false, error: exc.error}));
    }
  });
}

//MERGE SAGA
export default function* rootSaga() {
  yield all([
    fork(recoverPasswordSendEmailRequest),
    fork(loginLoginRequest),
  ]);
}
