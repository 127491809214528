import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { Button, Dropdown, Menu, Tag, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditUser from "../../components/editUser";
import ExcelUpload from "../../components/excelUpload";
import SidePanelFilters from "components/SidePanelFilters";

import { validateRoles } from "config/helpers/Utils";

import { userListSearch, editUserOpen, getCatalog, editUserSave } from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";

const UserListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();
  const [statusFilter, setStatusFilter] = useState(1)

  const { user } = useSelector((state) => state.authRedux);
  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.userListRedux);
  const { companies, roles } = useSelector((state) => state.commonRedux);

  //Inicio del componente
  useEffect(() => {
    dispatch(getCatalog(["companies", "roles"]));
    tableviewRef.current?.refresh();
  }, []);
  
  //definicion de columnas Tabla
  const columns = useMemo(() => [
    {
      title: messages["usersList_name"],
      dataIndex: "Nombre",
      key: "Nombre",
    },
    {
      title: messages["usersList_email"],
      dataIndex: "Correo",
      key: "Correo",
    },
    {
      title: messages["usersList_identification"],
      dataIndex: "Identificacion",
      key: "Identificacion",
    },
    {
      title: messages["usersList_phone"],
      dataIndex: "Telefono",
      key: "Telefono",
    },
    {
      title: messages["usersList_client"],
      dataIndex: "client",
      key: "client",
      render: (v, values) => <>{values?.ClienteInfo?.RazonSocial}</>,
      width: 150,
    },
    {
      title: messages["usersList_position"],
      dataIndex: "Cargo",
      key: "Cargo",
    },
    {
      title: messages["usersList_city"],
      dataIndex: "CiudadId",
      key: "CiudadId",
      render: (v, values) => <span>{values?.Ciudad?.CityName.charAt(0).toUpperCase() + values?.Ciudad?.CityName.slice(1).toLowerCase()}</span>,

    },
    {
      title: messages["usersList_rol"],
      dataIndex: "rol",
      key: "rol",
      render: (v, values) => <span>{values?.RolInfo?.Rol}</span>
    },
    {
      title: messages["usersList_status"],
      dataIndex: "estado",
      key: "estado",
      render: (val, values) =>
        (val > 0) ? (
          <Tag color="blue">Activo</Tag>
        ) : (
          <Tag color="red">Inactivo</Tag>
        ),
    },
    {
      title: messages["createdBy"],
      dataIndex: "UsuarioCreacionInfo",
      key: "createdBy",
      render: (val, values) => val?.Nombre
    },
    {
      key: "options",
      width: 50,
      render: (v, value) =>
        /* validateRoles([1], user) && */ (
          <Dropdown
            overlay={() => (
              <Menu>
                {value.estado > 0 && (
                  <Menu.Item key="mu1">
                    <Button
                      type="text"
                      onClick={() => dispatch(editUserOpen(value.IdUsuario))}
                    >
                      {messages["edit"]}
                    </Button>
                  </Menu.Item>
                )}

                <Menu.Item key="mu2">
                  <Button
                  onClick={() => dispatch(editUserOpen(value.IdUsuario, true))}
                  type="text">{messages["delete"]}</Button>
                </Menu.Item>
              </Menu>
            )}
            placement="bottomCenter"
          >
            <MoreOutlined className="icon" />
          </Dropdown>
        ),
    },
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(userListSearch(openSearch, limit, offset, statusFilter));
  });

  const onSearch = useCallback((openSearch, limit, offset) => {
    dispatch(userListSearch(openSearch, limit, offset, statusFilter));
  });

  const onClear = useCallback(() => {
    dispatch(userListSearch('', 50,0, 1));
  });

  console.log("Data: ", data.items)

  return (
    <div className="container">
      <HeaderPage title={messages["usersList_title"]}>
        <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlinePlus" />}
            onClick={() => dispatch(editUserOpen())}
          >
            {messages["usersList_add"]}
          </Button>
        </div>
      </HeaderPage>

      <SidePanelFilters
        loading={loading}
        onRefreshTable={onSearch}
        onClear={onClear}
        searchTitle="Nombres/Email/Identificacion"
      >
        <div className="col-12 mt-2">
          <div>{messages["usersList_status"]}</div>
          <Select
            className="w-100"
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          >
                <Select.Option key={1} value={1}>
                  Activo
                </Select.Option>
                <Select.Option key={2} value={0}>
                  Inactivo
                </Select.Option>
          </Select>
        </div>
      </SidePanelFilters>

      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            "Nombres y Apellidos": value?.Nombre,
            Email: value?.Correo,
            "Número de identificación": value?.Identificacion,
            "Teléfono": value?.Telefono,
            Empresa: value?.ClienteInfo?.RazonSocial,
            Cargo: value?.Cargo,
            Ciudad: value?.Ciudad?.CityName.charAt(0).toUpperCase() + value?.Ciudad?.CityName.slice(1).toLowerCase(),
            Rol: value?.RolInfo?.Rol,
            Estado: value?.estado > 0 ? "Activo" : "Inactivo",
          };
        })}
      />

      <EditUser
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
      <ExcelUpload refresh={() => tableviewRef.current?.refresh()} />
    </div>
  );
};

export default UserListView;
