import {
    //TYPES
    SEGUIMIENTO_FILES_LIST,
    SEGUIMIENTO_FILES_LIST_RESULT,
    DELETE_SEGUIMIENTO_FILE,
    DELETE_SEGUIMIENTO_FILE_RESULT,
} from 'config/redux/actionTypes';

export const seguimientoFilesListSearch = (idSeguimiento, openSearch, limit, offset) => ({
    type: SEGUIMIENTO_FILES_LIST,
    payload: { idSeguimiento, openSearch, limit, offset }
});

export const seguimientoFilesListSearchResult = (data) => ({
    type: SEGUIMIENTO_FILES_LIST_RESULT,
    payload: data
});

export const deleteSeguimientoFile = (idSeguimiento, idFile) => ({
    type: DELETE_SEGUIMIENTO_FILE,
    payload: { idSeguimiento, idFile }
});

export const deleteSeguimientoFileResult = (result) => ({
    type: DELETE_SEGUIMIENTO_FILE_RESULT,
    payload: result
});