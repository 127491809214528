//imports
import { all } from 'redux-saga/effects';

import seguimientosListSagas from '../../views/seguimientosList/redux/sagas';
import seguimientosDetailSagas from '../../views/seguimientosDetail/redux/sagas';
import editDetalleSeguimientoSagas from '../../views/seguimientosDetail/components/editDetalleSeguimiento/redux/sagas';
import editSeguimientoSagas from '../../components/editSeguimiento/redux/sagas';
import seguimientoFilesSagas from '../../views/seguimientosDetail/components/files/redux/sagas';
import seguimientoAddFileSagas from '../../views/seguimientosDetail/components/addFile/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        seguimientosListSagas(),
        editSeguimientoSagas(),
        seguimientosDetailSagas(),
        editDetalleSeguimientoSagas(),
        seguimientoFilesSagas(),
        seguimientoAddFileSagas()
    ]);
}
