let locales = {
    accompanistsList_title: 'Escoltas',
    accompanistsList_add: 'Agregar Escolta',
    accompanistsList_uploadExcel: 'Cargar excel',
    accompanistsList_name: 'Nombre',
    accompanistsList_identification: 'Cédula',
    accompanistsList_phone: 'Teléfono',
    accompanistsList_vehicle: 'Vehículo',
    accompanistsList_status: "Estado"
};

export default locales;