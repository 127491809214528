import userListRedux from '../../views/usersList/redux/reducer'
import clientListRedux from '../../views/clientsList/redux/reducer'
import vehiclesListRedux from '../../views/vehiclesList/redux/reducer'
import accompanistsListRedux from '../../views/accompanistsList/redux/reducer'
import editUserRedux from '../../components/editUser/redux/reducer'
import editVehicleRedux from '../../components/editVehicle/redux/reducer'
import editAccompanistRedux from '../../components/editAccompanist/redux/reducer'
import editClientRedux from '../../components/editClient/redux/reducer'
import excelUploadRedux from '../../components/excelUpload/redux/reducer'
import excelUploadContractorsRedux from '../../components/excelUploadContractors/redux/reducer'
import vehicleDetailRedux from '../../views/vehicleDetail/redux/reducer'
import vehicleAddFileRedux from '../../views/vehicleDetail/components/addFile/redux/reducer'
import vehicleFilesRedux from '../../views/vehicleDetail/components/files/redux/reducer'
//export redux objects
export default {
	userListRedux,
	editUserRedux,
	excelUploadRedux,
	excelUploadContractorsRedux,
	clientListRedux,
	editClientRedux,
	editVehicleRedux,
	vehiclesListRedux,
	editAccompanistRedux,
	accompanistsListRedux,
	vehicleDetailRedux,
	vehicleAddFileRedux,
	vehicleFilesRedux
}; 
