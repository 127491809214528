import React, { useState, useEffect, useRef } from "react";
import { Image } from "antd";
import "./styles.css";
// Import css files
function Gallery({ images = [] }) {
  const [showImage, setImages] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleWheel = (event) => {
      event.preventDefault();
      container.scrollTo({
        left: container.scrollLeft + event.deltaY,
        behavior: "smooth",
      });
    };

    container.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    setImages(images[0]);
  }, [images]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX;
    const scrollX = x - startX;
    containerRef.current.scrollLeft = scrollLeft - scrollX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const onClickImage = (image) => () => {
    setImages(image);
  };

  return (
    <div className="row p-2" >
      <div className="col-12">
        <div className="d-flex justify-content-center align-items-center">
          <Image
            width={400}
            height={220}
            style={{objectFit: "contain",}}
            className="rounded shadow-lg"
            src={showImage}
          />
        </div>
        <div
          className="image-list-container mt-2"
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="image-list">
            {images.map((v, i) => (
              <a>
                <img
                  key={i}
                  onClick={onClickImage(v)}
                  alt={`image-${i}`}
                  style={{objectFit: "contain", height: 65, width: 100}}
                  className="rounded border m-2"
                  src={v}
                />
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* <Slider {...settings}>
        {images.map(v => <div className="d-flex justify-content-center glide__slide p-0 glide__slide--active"> 
          <img className="responsive border-0 rounded img-fluid" src={v} />
        </div>)}
      </Slider> */}
    </div>
  );
}

export default Gallery;
