let locales = {
    editSeguimiento_title: 'Crear seguimiento',
    editSeguimiento_titleUpdate: 'Editar seguimiento',
    editSeguimiento_client: 'Empresa',
    editSeguimiento_order: "Sello Satelital",
    editSeguimiento_vehicle: 'Vehículo',
    editSeguimiento_manifest: 'Manifiesto',
    editSeguimiento_insurance: 'Aseguradora',
    editSeguimiento_originCity: 'Ciudad Origen',
    editSeguimiento_destinyCity: 'Ciudad Destino',
    editSeguimiento_route: 'Ruta',
    editSeguimiento_kilometers: 'Kilómetros',
    editSeguimiento_estimatedTime: 'Tiempo estimado',
    editSeguimiento_startDate: 'Fecha de Salida',
    editSeguimiento_endDate: 'Fecha de Llegada',
    editSeguimiento_driverName: 'Nombre del Conductor',
    editSeguimiento_driverPhone: 'Teléfono del Conductor',
    editSeguimiento_accompanist: 'Escolta',
    editSeguimiento_restriction: 'Restricción',
    editSeguimiento_link: 'Link',
    editSeguimiento_observation: 'Observación',
    editSeguimiento_status: 'Estado',
};

export default locales;