let locales = {
    editDetalleSeguimiento_title: 'Crear detalle de seguimiento',
    editDetalleSeguimiento_track: "Seguimiento",
    editDetalleSeguimiento_titleUpdate: 'Editar detalle de seguimiento',
    editDetalleSeguimiento_reportPoint: "Punto de Reporte",
    editDetalleSeguimiento_withoutNovedad: "Novedad",
    editDetalleSeguimiento_observation: "Observación",
    editDetalleSeguimiento_seguimientoInactive: "No puede crear nuevo detalle de seguimiento ya que el seguimiento está cancelado o terminado"
};

export default locales;