//Global keys
let locales = {
    "track_menu": "Track",
    "track_seguimientosList_menu": "Seguimientos",
};

//Merge Modules
import seguimientosList from '../../views/seguimientosList/lan/es';
import seguimientosDetail from '../../views/seguimientosDetail/lan/es';
import editDetalleSeguimiento from '../../views/seguimientosDetail/components/editDetalleSeguimiento/lan/es';
import editSeguimiento from '../../components/editSeguimiento/lan/es';
import seguimientoFiles from '../../views/seguimientosDetail/components/files/lan/es';
import addFiles from '../../views/seguimientosDetail/components/addFile/lan/es';

locales = {
    ...locales,
    ...seguimientosList,
    ...editSeguimiento,
    ...seguimientosDetail,
    ...editDetalleSeguimiento,
    ...seguimientoFiles,
    ...addFiles
};

export default locales;