import {
    //TYPES
    VEHICLE_FILES_LIST,
    VEHICLE_FILES_LIST_RESULT,
    DELETE_VEHICLE_FILE,
    DELETE_VEHICLE_FILE_RESULT,
    VEHICLE_PHOTOS,
    VEHICLE_PHOTOS_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    resultDelete: null,
    vehiclePhotos: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case VEHICLE_FILES_LIST:
            return { ...state, loading: true, data: null }

        case VEHICLE_FILES_LIST_RESULT:
            return { ...state, loading: false, data: action.payload }
        
        case DELETE_VEHICLE_FILE:
            return { ...state, loading: true, resultDelete: null }

        case DELETE_VEHICLE_FILE_RESULT:
            return { ...state, loading: false, resultDelete: action.payload }
        
        case VEHICLE_PHOTOS:
            return { ...state, loading: true, vehiclePhotos: null }

        case VEHICLE_PHOTOS_RESULT:
            return { ...state, loading: false, vehiclePhotos: action.payload }

        case CLEAR_REDUX:
            return ["", "VEHICLE_FILES"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
