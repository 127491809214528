import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader
} from "antd";
import { InputFile } from "components";

import { getCatalog, editClientClose, editClientSave, clearRedux } from "config/redux/actions";

import "./styles.css"
import { useCitiesCascader } from "../../../../config/helpers/Hooks";

//otros componentes

const EditClientComp = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [id, setId] = useState();
  const [cityId, setCityId] = useState(0);

  const { isOpen, data, loading, result, isDelete } = useSelector(
    (state) => state.editClientRedux
  );

  const {cascaderOptions, cities, loadingCities, states, loadingStates} = useCitiesCascader();
  const isEdit = data ? true : false;

  
  
  //carga inicial de un valor de edicion
  useEffect(() => {
    if (data && !id) {
      //Se guarda el id
      setId(data.IdCliente);
      //Se hace set al formulario
      setCityId(data?.CiudadId);
      form.setFields([
        { name: "RazonSocial", value: data?.RazonSocial },
        { name: "Nit", value: data?.Nit },
        { name: "Telefono", value: data?.Telefono },
        { name: "Direccion", value: data?.Direccion },
        { name: "Correo", value: data?.Correo },
        { name: "PaisId", value: data?.PaisId == 170 ? "Colombia" : data?.PaisId },
        { name: "CiudadId", value: [cities.find(v => v.CityCode == data?.CiudadId)?.DepartmentCode, data?.CiudadId]},
      ]);
  }
}, [data, cities]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result.status) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(editClientClose());
    form.resetFields();
    setId();
    if (refresh) onClose(refresh);
  });

  return (
    <Modal
      title={
        isDelete ? messages["editClient_titleDesactivate"] : isEdit ? messages["editClient_titleUpdate"] : messages["editClient_title"]
      }
      visible={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin
                tip="Loading..."
                spinning={loading || loadingCities || loadingStates}
              >
                {
                  isDelete
                  ? <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(
                      editClientSave({ IdCliente: data?.IdCliente}, true)
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <p>
                        {messages["editClient_clientWishDesactivate"]}
                      </p>
                    </div>
                  </div>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                      loading={loading}
                      danger
                      type="primary"
                      htmlType="submit"
                      className="btn btn-danger btn-end"
                    >
                      {messages["delete"]}
                    </Button>
                  </div>
                </Form>
                  : <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    console.log(form.getFieldValue("CiudadId"))
                    const { RazonSocial, Nit, Telefono, Direccion, Correo } = values
                    dispatch(
                      editClientSave({ 
                          IdCliente: data?.IdCliente,
                          PaisId: 170,
                          RazonSocial,
                          Nit,
                          Telefono,
                          Direccion,
                          Correo,
                          CiudadId: parseInt(cityId)})
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="RazonSocial"
                        label={messages["editClient_socialReason"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Nit"
                        label={messages["editClient_nit"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Telefono"
                        label={messages["editClient_phone"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Direccion"
                        label={messages["editClient_address"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                    <Form.Item
                        name="Correo"
                        label={messages["editClient_email"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                          {                            
                            type: "email",
                            message: messages["invalid_email"],
                            
                          }
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="PaisId"
                        label={messages["editClient_country"]}
                        initialValue="Colombia"
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input readOnly />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="CiudadId"
                        label={messages["editClient_city"]}
                      >
                        <Cascader 
                        showSearch={{filter: (inputValue, path) => path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,)}} 
                        options={cascaderOptions} 
                        onChange={v => v !== null && v !== undefined && v.length>1 && setCityId(prev => v[1])} 
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button
                      loading={loading}
                      type="primary"
                      className="btn btn-alternate btn-end"
                      onClick={() => close()}
                    >
                      {messages["cancel"]}
                    </Button>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="btn btn-primary btn-end"
                    >
                      {messages["save"]}
                    </Button>
                  </div>
                </Form>
                }
                
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditClientComp;
