let locales = {
    editUser_title: 'Crear usuario',
    editUser_titleUpdate: 'Editar usuario',
    editUser_titleDelete: 'Desactivar usuario',
    editUser_name: 'Nombres y Apellidos',
    editUser_email: 'Email',
    editUser_identification: 'Número de identificación',
    editUser_phone: 'Teléfono',
    editUser_client: 'Empresa',
    editUser_position: 'Cargo',
    editUser_city: 'Ciudad',
    editUser_rol: 'Rol',
    editUser_status: 'Estado',
    editUser_password: 'Contraseña',
    editUser_userExists: 'El usuario, email o número de documento ya se encuentran registrados',
    editUser_userHasSeguimientos: 'El usuario no puede desactivarse ya que tiene seguimientos activos asignados.',
    editUser_userWishDesactivate: '¿Desea desactivar el usuario? Si hace esto, este usuario ya no podrá autenticarse en la aplicación.',

};

export default locales;