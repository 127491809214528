import React from "react";
import "./css/styles.css";

export default function ({ icon, title }) {
  return (
    <div className="dashboard-little-card">
      <div className="icon-dashboard">{icon}</div>
      <div className="title-number">
        <h5>{title}</h5>
      </div>
    </div>
  );
}
