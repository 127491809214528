import {
    //TYPES
    SEGUIMIENTO_LIST_SEARCH,
    SEGUIMIENTO_LIST_SEARCH_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SEGUIMIENTO_LIST_SEARCH:
            return { ...state, loading: true }

        case SEGUIMIENTO_LIST_SEARCH_RESULT:
            return { ...state, loading: false, data: action.payload }

        case CLEAR_REDUX:
            return ["", "SEGUIMIENTO_LIST"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
