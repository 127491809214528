import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader,
  DatePicker,
  Upload,
} from "antd";
import { InputFile } from "components";

import {
  addFileSeguimientoClose,
  addFileSeguimientoSave,
  clearRedux,
} from "config/redux/actions";

import moment from "moment";

//otros componentes
const { RangePicker } = DatePicker;

const AddFileSeguimiento = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const { isOpen, fileData, loading, track, result } = useSelector(
    (state) => state.seguimientoAddFiletRedux
  );

  const isEdit = fileData ? true : false;

  //carga inicial de un valor de edicion
  useEffect(() => {
    if (fileData && fileData?.id) {
      //Se hace set al formulario
      form.setFields([
        { name: "name", value: fileData?.name },
        { name: "description", value: fileData?.description},
      ]);
    }
  }, [fileData]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result?.isSuccess) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(addFileSeguimientoClose());
    form.resetFields();
    if (refresh && onClose){
      onClose(refresh);
    };
  });

  const onFinish = (values) => {
    dispatch(
      addFileSeguimientoSave({
        idFile: fileData?.id,
        idSeguimiento: track?.IdSeguimiento,
        type: values?.file?.file?.type,
        ...values,
        file: values.file?.file
      })
    );
  };

  const required = [
    {
      required: true,
      message: messages["required_field"],
    },
  ];

  return (
    <Modal
      title={messages["addFileSeguimiento_title"]}
      open={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={600}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin tip="Loading..." spinning={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="name"
                    label={messages["addFileSeguimiento_fileName"]}
                    rules={required}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label={messages["addFileSeguimiento_fileDescription"]}
                  >
                    <Input.TextArea maxLength={500} />
                  </Form.Item>
                  {!isEdit && <Form.Item name="file" label={messages[""]} rules={required}>
                    <InputFile accept="application/pdf"/>
                  </Form.Item>}
                </Form>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                    loading={loading}
                    type="primary"
                    className="btn btn-alternate btn-end"
                    onClick={() => close()}
                  >
                    {messages["cancel"]}
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    onClick={form.submit}
                    className="btn btn-primary btn-end"
                  >
                    {messages["save"]}
                  </Button>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddFileSeguimiento;
