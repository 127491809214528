//imports
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

//views
import MainView from './views/main';

//router
const DashboardRouter = ({ match }) => {

    return (
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/main`} />
            <Route path={`${match.url}/main`} component={MainView} />
            <Redirect to="/error" />
        </Switch>
    );
};

export default DashboardRouter
