import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { Button, Dropdown, Menu, Tag, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditAccompanist from "../../components/editAccompanist";
import ExcelUpload from "../../components/excelUpload";
import SidePanelFilters from "components/SidePanelFilters";

import { validateRoles } from "config/helpers/Utils";

import { accompanistListSearch, editAccompanistOpen, getCatalog, editAccompanistSave } from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";

const UserListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();

  const [statusFilter, setStatusFilter] = useState(1)
  const { user } = useSelector((state) => state.authRedux);
  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.accompanistsListRedux);
  const { companies, roles } = useSelector((state) => state.commonRedux);

  //Inicio del componente
  useEffect(() => {
    dispatch(getCatalog(["companies", "roles"]));
    tableviewRef.current?.refresh();
  }, []);
  
  //definicion de columnas Tabla
  const columns = useMemo(() => [
    {
      title: messages["accompanistsList_name"],
      dataIndex: "Nombre",
      key: "Nombre",
    },
    {
      title: messages["accompanistsList_identification"],
      dataIndex: "Cedula",
      key: "Cedula",
    },
    {
      title: messages["accompanistsList_phone"],
      dataIndex: "Celular",
      key: "Celular",
    },
    {
      title: messages["accompanistsList_vehicle"],
      dataIndex: "Vehiculo",
      key: "Vehiculo",
      render: (v, values) => <span>{`${values?.VehiculoInfo?.Placa} - ${values?.VehiculoInfo?.Marca + " " + values?.VehiculoInfo?.Modelo + " " + values?.VehiculoInfo?.Color}`}</span>
    },
    {
      title: messages["accompanistsList_status"],
      dataIndex: "estado",
      key: "estado",
      render: (val) =>
      (val > 0) ? (
        <Tag color="blue">Activo</Tag>
        ) : (
          <Tag color="red">Inactivo</Tag>
          ),
        },
        {
          title: messages["createdBy"],
          dataIndex: "UsuarioCreacionInfo",
          key: "createdBy",
          render: (val, values) => val?.Nombre
        },
        {
          key: "options",
          width: 50,
          render: (v, values) =>
          /* validateRoles([1], user) && */ (
          <Dropdown
            overlay={() => (
              <Menu>
                {values?.estado === 1 && (
                  <Menu.Item key="mu1">
                    <Button
                      type="text"
                      onClick={() => dispatch(editAccompanistOpen(values?.IdAcompanante))}
                    >
                      {messages["edit"]}
                    </Button>
                  </Menu.Item>
                )}

                <Menu.Item key="mu2">
                  <Button 
                  onClick={() => dispatch(editAccompanistSave({IdAcompanante: values?.IdAcompanante, estado: 0}))}
                  type="text">{messages["delete"]}</Button>
                </Menu.Item>
              </Menu>
            )}
            placement="bottomCenter"
          >
            <MoreOutlined className="icon" />
          </Dropdown>
        ),
    },
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(accompanistListSearch(openSearch || "", limit, offset, statusFilter));
  });

  const onSearch = useCallback((openSearch, limit, offset) => {
    dispatch(accompanistListSearch(openSearch || "", limit, offset, statusFilter));
  });

  const onClear = useCallback(() => {
    dispatch(accompanistListSearch('', 50,0, 1));
  });


  console.log("Data: ", data.items)

  return (
    <div className="container">
      <HeaderPage title={messages["accompanistsList_title"]}>
        <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlinePlus" />}
            onClick={() => dispatch(editAccompanistOpen())}
          >
            {messages["accompanistsList_add"]}
          </Button>
        </div>
      </HeaderPage>

      <SidePanelFilters
        loading={loading}
        onRefreshTable={onSearch}
        onClear={onClear}
        searchTitle="Placa/Marca/Modelo"
      >
        <div className="col-12 mt-2">
          <div>{messages["accompanistsList_status"]}</div>
          <Select
            className="w-100"
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          >
                <Select.Option key={1} value={1}>
                  Activo
                </Select.Option>
                <Select.Option key={2} value={0}>
                  Inactivo
                </Select.Option>
          </Select>
        </div>
      </SidePanelFilters>

      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            Nombre: value?.Nombre,
            "Cédula": value?.Cedula,
            "Teléfono": value?.Celular,
            "Vehículo": `${value?.VehiculoInfo?.Placa} - ${value?.VehiculoInfo?.Marca + " " + value?.VehiculoInfo?.Modelo + " " + value?.VehiculoInfo?.Color}`,
            Estado: value?.estado == 0 ? "Inactivo" : "Activo",
          };
        })}
      />

      <EditAccompanist
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
      <ExcelUpload refresh={() => tableviewRef.current?.refresh()} />
    </div>
  );
};

export default UserListView;
