import {
    COMMON_GET_CATALOG,
    COMMON_GET_CATALOG_RESULT,
    COMMON_CATALOG_LOADINGS,
    CLEAR_REDUX
} from 'config/redux/actionTypes';

export const getCatalog = (keys, force = false, params) => ({
    type: COMMON_GET_CATALOG,
    payload: { keys, force, params }
});

export const getCatalogResult = (data) => ({
    type: COMMON_GET_CATALOG_RESULT,
    payload: data
});

export const catalogLoadigns = (loadings) => ({
    type: COMMON_CATALOG_LOADINGS,
    payload: loadings,
});

export const clearRedux = (option, parameters) => ({
    type: CLEAR_REDUX,
    payload: { option: option || "", parameters }
});
