import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { Select, Menu } from 'antd';
import { Icons } from 'components'
import { validateRoles } from 'config/helpers/Utils'

import {
    changeThemeConfig,
    authLogout
} from 'config/redux/actions'

import './sidebar.scss'
import { Link } from 'react-router-dom';


const Sidebar = ({ history }) => {
    const { SubMenu } = Menu;

    const dispatch = useDispatch()
    const { messages } = useIntl()
    const theme = useSelector(state => state.themeRedux)
    const { user } = useSelector(state => state.authRedux)
    const [showUserMenu, setShowUserMenu] = useState(false)
    const { pathname } = useLocation()

    const items = useMemo(() => [
        {
            label: 'Dashboard',
            path: '/app/dashboard',
            icon: <Icons family='md' name='MdDashboard' className="icon" />,
            /*items: [
                { label: 'Users', path: '/app/admin/userlist' },
            ]*/
        },
        {
            label: messages['admin_menu'],
            path: '/app/admin',
            icon: <Icons family='md' name='MdSettings' className="icon" />,
            items: [
                { label: messages['admin_usersList_menu'], path: '/app/admin/userslist', roles: [1] },
                { label: messages['admin_customerList_menu'], path: '/app/admin/clientslist', roles: [1] },
                { label: messages['admin_vehicleList_menu'], path: '/app/admin/vehicleslist', roles: [1,2] },
                { label: messages['admin_accompanistList_menu'], path: '/app/admin/accompanistslist', roles: [1,2] },
            ],
            roles: [1,2]
        },
        {
            label: messages['track_menu'],
            path: '/app/track',
            icon: <Icons family='md' name='MdTrendingUp' className="icon" />,
            items: [
                { label: messages['track_seguimientosList_menu'], path: '/app/track/seguimientosList', roles: [1, 2, 3] },
            ],
            roles: [1, 2, 3]
        },
    ])

    const createMenuItems = useCallback((menuItems) => {

        return menuItems.map((item, index) => {

            if (item.items  && validateRoles(item.roles, user)) {
                return (
                    <SubMenu key={item.path} icon={item.icon} title={item.label}>
                        {createMenuItems(item.items)}
                    </SubMenu>
                )
            }
            else if (validateRoles(item.roles, user))
                return (
                    <Menu.Item key={item.path} icon={item.icon} title={item.label}>{item.label}</Menu.Item>
                )
        })
    })

    return (
        <div id="nav" className="nav-container d-flex"
            onMouseEnter={() => { theme["data-behaviour"] == 'unpinned' && dispatch(changeThemeConfig({ "data-menu-animate": 'show' })) }}
            onMouseLeave={() => {
                if (theme["data-behaviour"] == 'unpinned') {
                    dispatch(changeThemeConfig({ "data-menu-animate": 'hidden' }))
                    setShowUserMenu(false)
                }
            }}
        >
            <div className="nav-content d-flex">
                {/* Logo Start */}
                <div className="logo position-relative">
                    <Link to={"/app/dashboard"}>
                        {/* Or added via css to provide different ones for different color themes */}
                        {/* <div className="img"></div> */}
                        <img src="/assets/images/logo/logo_white.png" alt="logo" style={{ width: '100%', height: 'auto' }} />
                    </Link>
                </div>
                {/* Logo End */}

                {/* Language Switch Start *}
                <div className="language-switch-container">
                    {/* <button className="btn btn-empty language-button dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">EN</button> *}
                    <Select defaultValue="ES" onChange={() => { }}>
                        <Select.Option value="ES">ES</Select.Option>
                        <Select.Option value="EN">EN</Select.Option>
                    </Select>

                </div>
                {/* Language Switch End */}

                {/* User Menu Start */}
                <div id="user-container" tabIndex="0" className="user-container d-flex" onBlur={() => { setShowUserMenu(false) }}>
                    <a className="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {
                        // setShowUserMenu(!showUserMenu)
                        // if (!showUserMenu) {
                        //     document.getElementById("user-container").focus();
                        // }
                    }}>
                        {/* <img className="profile" alt="profile" src={user?.imageUrl || "/assets/images/avatar.png"} /> */}
                        <div className="name">{user && user.Nombre}</div>
                    </a>
                    <div className={cx("dropdown-menu dropdown-menu-end user-menu wide", showUserMenu && 'show')}>
                        {/* <div className="row mb-3 ms-0 me-0">
                            <div className="col-12 ps-1 mb-2">
                                <div className="text-extra-small text-primary">ACCOUNT</div>
                            </div>
                            <div className="col-6 ps-1 pe-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">User Info</a>
                                    </li>
                                    <li>
                                        <a href="#">Preferences</a>
                                    </li>
                                    <li>
                                        <a href="#">Calendar</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 pe-1 ps-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">Security</a>
                                    </li>
                                    <li>
                                        <a href="#">Billing</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mb-1 ms-0 me-0">
                            <div className="col-12 p-1 mb-2 pt-2">
                                <div className="text-extra-small text-primary">APPLICATION</div>
                            </div>
                            <div className="col-6 ps-1 pe-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">Themes</a>
                                    </li>
                                    <li>
                                        <a href="#">Language</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 pe-1 ps-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">Devices</a>
                                    </li>
                                    <li>
                                        <a href="#">Storage</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mb-1 ms-0 me-0">
                            <div className="col-12 p-1 mb-3 pt-3">
                                <div className="separator-light"></div>
                            </div>
                            <div className="col-6 ps-1 pe-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">
                                            <Icons family='ai' name='AiOutlineRead' className="me-2" />
                                            <span className="align-middle">Help</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <Icons family='ai' name='AiOutlineFileText' className="me-2" />
                                            <span className="align-middle">Docs</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 pe-1 ps-1">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#">
                                            <Icons family='ai' name='AiOutlineSetting' className="me-2" />
                                            <span className="align-middle">Settings</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => { dispatch(authLogout(history)) }}>
                                            <Icons family='ai' name='AiOutlineImport' className="me-2" />
                                            <span className="align-middle">Logout</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* User Menu End */}

                {/* Icons Menu Start */}
                <ul className="list-unstyled list-inline text-center menu-icons">
                    {/* <li className="list-inline-item">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
                            <i data-cs-icon="search" data-cs-size="18"></i>
                            <Icons family='ai' name='AiOutlineSearch' />
                        </a>
                    </li> */}
                    <li className="list-inline-item">
                        <a className="pin-button" onClick={(e) => {
                            let dataBehaviour = theme["data-behaviour"] == 'pinned' ? 'unpinned' : 'pinned'
                            let dataMenuAnimate = theme["data-behaviour"] == 'pinned' ? 'hidden' : 'show'
                            dispatch(changeThemeConfig({ "data-behaviour": dataBehaviour, "data-menu-animate": dataMenuAnimate }))
                        }}>
                            <Icons family='md' name='MdLockOpen' className="unpin" />
                            <Icons family='md' name='MdLock' className="pin" />
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a onClick={(e) => { dispatch(authLogout(history)) }}>
                            <Icons family='md' name='MdLogout' />
                        </a>
                    </li>
                    {/* <li className="list-inline-item">
                        <a href="#" id="colorButton">
                            <Icons family='ai' name='AiTwotoneBulb' className="light" />
                            <Icons family='ai' name='AiOutlineBulb' className="dark" />
                        </a>
                    </li> */}
                    {/* <li className="list-inline-item">
                        <a href="/app/notification" data-bs-toggle="dropdown" data-bs-target="#notifications" aria-haspopup="true" aria-expanded="false" className="notification-button">
                            <div className="position-relative d-inline-flex">
                                <Icons family='ai' name='AiOutlineBell' className="dark" />
                                <span className="position-absolute notification-dot rounded-xl"></span>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out" id="notifications">
                            {/* <div className="scroll">
                                <ul className="list-unstyled border-last-none">
                                    <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                        <img src="img/profile/profile-1.jpg" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                        <div className="align-self-center">
                                            <a href="#">Joisse Kaycee just sent a new comment!</a>
                                        </div>
                                    </li>
                                    <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                        <img src="img/profile/profile-2.jpg" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                        <div className="align-self-center">
                                            <a href="#">New order received! It is total $147,20.</a>
                                        </div>
                                    </li>
                                    <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                        <img src="img/profile/profile-3.jpg" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                        <div className="align-self-center">
                                            <a href="#">3 items just added to wish list by a user!</a>
                                        </div>
                                    </li>
                                    <li className="pb-3 pb-3 border-bottom border-separator-light d-flex">
                                        <img src="img/profile/profile-6.jpg" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                        <div className="align-self-center">
                                            <a href="#">Kirby Peters just sent a new message!</a>
                                        </div>
                                    </li>
                                </ul>
                            </div> *}
                        </div>
                    </li> */}
                </ul>
                {/* Icons Menu End */}

                {/* Menu Start */}
                <div
                    className="menu-container flex-grow-1"
                >
                    <Menu
                        defaultOpenKeys={items.filter(x => pathname.indexOf(x.path) != -1).map(x => x.path)}
                        selectedKeys={[pathname]}
                        mode='inline'
                        inlineCollapsed={theme["data-menu-animate"] == "hidden"}
                        onClick={(item) => { history.push(item.key) }}>
                        {
                            createMenuItems(items)
                        }
                    </Menu>
                    {/* <Menu
                        onClick={() => { }}
                        //className={cx('menu show')}
                        defaultSelectedKeys={[submenu]}
                        defaultOpenKeys={[menu]}
                        mode="inline"
                    >
                        <SubMenu key="admin" icon={<Icons family='ai' name='AiOutlineControl' className="icon" />} title="Administracion">
                            <Menu.Item key="userlist" className="label">Usuarios</Menu.Item>
                            <Menu.Item key="rolelist">Roles</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" icon={<Icons family='ai' name='AiOutlineControl' className="icon" />} title="Navigation Two">
                            <Menu.Item key="5" path>Option 5</Menu.Item>
                            <Menu.Item key="6">Option 6</Menu.Item>
                            <SubMenu key="sub3" title="Submenu">
                                <Menu.Item key="7">Option 7</Menu.Item>
                                <Menu.Item key="8">Option 8</Menu.Item>
                            </SubMenu>
                        </SubMenu>
                        <SubMenu key="sub4" icon={<Icons family='ai' name='AiOutlineControl' className="icon" />} title="Navigation Three">
                            <Menu.Item key="9">Option 9</Menu.Item>
                            <Menu.Item key="10">Option 10</Menu.Item>
                            <Menu.Item key="11">Option 11</Menu.Item>
                            <Menu.Item key="12">Option 12</Menu.Item>
                        </SubMenu>
                    </Menu>*/}
                </div>
                {/* Menu End */}

                {/* Mobile Buttons Start */}
                <div className="mobile-buttons-containFer">
                    {/* Scrollspy Mobile Button Start */}
                    <a href="#" id="scrollSpyButton" className="spy-button" data-bs-toggle="dropdown">
                        <i data-cs-icon="menu-dropdown"></i>
                    </a>
                    {/* Scrollspy Mobile Button End */}

                    {/* Scrollspy Mobile Dropdown Start */}
                    <div className="dropdown-menu dropdown-menu-end" id="scrollSpyDropdown"></div>
                    {/* Scrollspy Mobile Dropdown End */}

                    {/* Menu Button Start */}
                    <a href="#" id="mobileMenuButton" className="menu-button">
                        <i data-cs-icon="menu"></i>
                    </a>
                    {/* Menu Button End */}
                </div>
                {/* Mobile Buttons End */}
            </div>
            <div className="nav-shadow"></div>
        </div>
    )
}

export default Sidebar;