import {
    //TYPES
    EDIT_SEGUIMIENTO_OPEN,
    EDIT_SEGUIMIENTO_OPEN_RESULT,
    EDIT_SEGUIMIENTO_CLOSE,
    EDIT_SEGUIMIENTO_SAVE,
    EDIT_SEGUIMIENTO_SAVE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case EDIT_SEGUIMIENTO_OPEN:
            return { ...state, isOpen: true, loading: true, data: null }

        case EDIT_SEGUIMIENTO_OPEN_RESULT:
            return { ...state, loading: false, data: action.payload }

        case EDIT_SEGUIMIENTO_CLOSE:
            return { ...state, ...INIT_STATE }

        case EDIT_SEGUIMIENTO_SAVE:
            return { ...state, loading: true, result: null }

        case EDIT_SEGUIMIENTO_SAVE_RESULT:
            return { ...state, loading: false, result: action.payload }

        case CLEAR_REDUX:
            return ["", "EDIT_SEGUIMIENTO"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
