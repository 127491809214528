export const adjustColumnsRow = () => {
    //colocar --- en la primera columna para ajustar orden
    let tableHeader = document.querySelector(
        ".ExcelTable2007 tbody tr:first-of-type"
      );
      const th = document.createElement("th");
      th.textContent = "---";
      if (tableHeader?.firstElementChild.textContent !== "---") {
        tableHeader?.insertBefore(th, tableHeader?.firstElementChild);
      }
}

export const changeRowsBgColor = () => {
    //Cambio de color en el fondo segun tipo de error
    let tableRows = document.querySelectorAll(
        ".ExcelTable2007 tr"
      );

      tableRows.forEach(row => {
        let tds = [...row.children]
        if (tds[tds.length-1].children[0]?.classList?.contains("ant-tag-yellow")) {
          row.setAttribute("class", "yellowBg");
        }else if(tds[tds.length-1].children[0]?.classList?.contains("ant-tag-volcano")){
          row.setAttribute("class", "redBg");
        }
      })
}

export const estructureColumnsData = (cols) => {
    //Estructuramos columnas para colocarlas en la prop cols del componente y anadimos status y msg
    let dataColumns = [];
    cols.forEach((col, i) => {
      let dataColumnObj = {
        name: col,
        key: i,
      }
      dataColumns.push(dataColumnObj);
      dataColumnObj = {}
    })
    dataColumns.push({name: "Status", key: dataColumns.length}, {name: "Mensaje", key: dataColumns.length+1})
    return dataColumns;
  }




