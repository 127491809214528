import {
    //TYPES
    CLIENT_LIST_SEARCH,
    CLIENT_LIST_SEARCH_RESULT
} from 'config/redux/actionTypes';

export const clientListSearch = (openSearch, limit, offset, status) => ({
    type: CLIENT_LIST_SEARCH,
    payload: { openSearch, limit, offset, status },
});

export const clientListSearchResult = (data) => ({
    type: CLIENT_LIST_SEARCH_RESULT,
    payload: data,
});
