//Global keys
let locales = {
    "admin_menu": "Administración",
    "admin_contractorList_menu": "Empresas",
    "admin_contractorDetail_menu": "Mi Información",
    "admin_usersList_menu": "Usuarios",
    "admin_customerList_menu": "Clientes",
    "admin_vehicleList_menu": "Vehículos",
    "admin_accompanistList_menu": "Escoltas",
    "admin_roleList_menu": "Roles",
};

//Merge Modules
import usersList from '../../views/usersList/lan/es';
import vehiclesList from '../../views/vehiclesList/lan/es';
import accompanistsList from '../../views/accompanistsList/lan/es';
import clientsList from '../../views/clientsList/lan/es';
import editUser from '../../components/editUser/lan/es';
import editVehicle from '../../components/editVehicle/lan/es';
import editAccompanist from '../../components/editAccompanist/lan/es';
import editClient from '../../components/editClient/lan/es';
import excelUpload from '../../components/excelUpload/lan/es';
import excelUploadContractors from '../../components/excelUploadContractors/lan/es';
import vehicleDetail from '../../views/vehicleDetail/lan/es';
import vehicleDetailHistory from '../../views/vehicleDetail/components/history/lan/es';
import vehicleDetailAddFile from '../../views/vehicleDetail/components/addFile/lan/es';
import vehicleDetailListFile from '../../views/vehicleDetail/components/files/lan/es';

locales = {
    ...locales,
    ...usersList,
    ...editUser,
    ...excelUpload,
    ...excelUploadContractors,
    ...clientsList,
    ...editClient,
    ...editVehicle,
    ...vehiclesList,
    ...editAccompanist,
    ...accompanistsList,
    ...vehicleDetail,
    ...vehicleDetailHistory,
    ...vehicleDetailAddFile,
    ...vehicleDetailListFile
};

export default locales;