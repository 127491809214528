// eslint-disable-next-line no-unused-vars
import React, { Fragment, useState } from 'react';
import { Icons } from "components";
import PropTypes from 'prop-types';
import cx from 'classnames';

import './sidepanel.scss';

export const SidePanelHeader = (props) => {
	return <div className="side-panel-header" style={{ ...props.style }}>{props.children}</div>;
};

export const SidePanelBody = (props) => {
	return <div className={cx("", props.className ? props.className : "side-panel-body")} style={{ ...props.style }}>{props.children}</div>;
};

export const SidePanelFooter = (props) => {
	return <div className={cx("side-panel-footer", props.className)} style={{ ...props.style }}>{props.children}</div>;
};

const SidePanel = (props) => {

	const [isOpen, setIsOpen] = useState(false)

	let icon = isOpen ? 'AiOutlineRight' : 'AiOutlineLeft';

	const SidePanelbutton = (props) => (
		<span
			className={`side-panel-button side-panel-button-${props.direction ? props.direction : 'right'
				}`}
			onClick={() => setIsOpen(!isOpen)}
		>
			<Icons family='ai' name={icon} />
		</span>
	)

	return (
		<Fragment>
			<div className={`${(props.overlay && isOpen) && "overlay-panel"}`}></div>
			<div
				id='id_sidePanel'
				style={{ ...props.style }}
				className={`side-panel ${isOpen ? 'shown-' + (props.direction ? props.direction : 'right') : ''
					} side-panel-${props.direction ? props.direction : 'right'} ${props.classSidePanel ? props.classSidePanel : ''}`}
			>
				<div
					className={props.classPanelContent ? props.classPanelContent : "side-panel-content"}
					style={{ ...props.contentStyle }}
				>
					{props.children}
				</div>

				{!props?.hiddenButton && (
					<SidePanelbutton {...props} />
				)}

				{props?.hiddenButton && isOpen && (
					<SidePanelbutton {...props} />
				)}

			</div>
		</Fragment>

	);
};

export default SidePanel;
