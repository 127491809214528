import seguimientosListRedux from '../../views/seguimientosList/redux/reducer'
import seguimientosDetailRedux from '../../views/seguimientosDetail/redux/reducer'
import editDetalleSeguimientoRedux from '../../views/seguimientosDetail/components/editDetalleSeguimiento/redux/reducer'
import editSeguimientoRedux from '../../components/editSeguimiento/redux/reducer'
import seguimientoFilesListRedux from '../../views/seguimientosDetail/components/files/redux/reducer'
import seguimientoAddFiletRedux from '../../views/seguimientosDetail/components/addFile/redux/reducer'

//export redux objects
export default {
	seguimientosListRedux,
	editSeguimientoRedux,
	seguimientosDetailRedux,
	editDetalleSeguimientoRedux,
	seguimientoFilesListRedux,
	seguimientoAddFiletRedux
}; 
