//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_USER_OPEN,
    EDIT_USER_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editUserOpenResult,
    editUserSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editUserOpenRequest() {
    yield takeEvery(EDIT_USER_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;

            if (!id) {
                yield put(editUserOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getUserById($id: Int){
                    user: getUserById(id: $id){
                        IdUsuario
                        Nombre
                        Correo
                        Identificacion
                        Password
                        Telefono
                        IdCliente
                        Cargo
                        CiudadId
                        IdRol
                    }
                }`,
                {
                    id: id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editUserOpenResult(data.user))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editUser/redux/saga', 'editUserOpenRequest');
            yield put(editUserOpenResult());

        }
    });
}

function* editUserSaveRequest() {
    yield takeEvery(EDIT_USER_SAVE, function* ({ payload }) {
        try {
            const { data, isDelete } = payload;
            let query
            console.log("payload:", payload)

            if (data.IdUsuario) {
                if (!isDelete) {
                    query = yield call(
                        clientMutation,
                        `mutation updateUser(
                            $IdUsuario: Int!
                            $Nombre: String
                            $Correo: String
                            $Identificacion: String
                            $Telefono: String
                            $IdCliente: Int
                            $Cargo: String
                            $CiudadId: Int
                            $IdRol: Int
                            $estado: Int
                          ){
                        clients: updateUser(
                            IdUsuario: $IdUsuario
                            Nombre: $Nombre
                            Correo: $Correo
                            Identificacion: $Identificacion
                            Telefono: $Telefono
                            IdCliente: $IdCliente
                            Cargo: $Cargo
                            CiudadId: $CiudadId
                            IdRol: $IdRol
                            estado: $estado
                            ){
                            IdUsuario
                        }
                    }`,
                        data,
                        endpoints.GRAPHQL_GENERAL
                    );
    
                    yield put(editUserSaveResult({ status: true }));
                }else{
                    query = yield call(
                        clientMutation,
                        `mutation inactivateUser(
                            $IdUsuario: Int!,
                            ){
                          user: inactivateUser(
                            IdUsuario: $IdUsuario
                            ){
                              IdUsuario
                          }
                      }`,
                        {IdUsuario: parseInt(data.IdUsuario)},
                        endpoints.GRAPHQL_GENERAL
                    );
    
                    yield put(editUserSaveResult({ status: true }));
                }

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createUser(
                        $Nombre: String
                        $Correo: String
                        $Identificacion: String
                        $Telefono: String
                        $IdCliente: Int
                        $Cargo: String
                        $CiudadId: Int
                        $IdRol: Int
                      ){
                    user: createUser(
                        Nombre: $Nombre
                        Correo: $Correo
                        Identificacion: $Identificacion
                        Telefono: $Telefono
                        IdCliente: $IdCliente
                        Cargo: $Cargo
                        CiudadId: $CiudadId
                        IdRol: $IdRol
                        ){
                        IdUsuario
                        Nombre
                        Correo
                        Identificacion
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editUserSaveResult({ status: true, id: query.user.IdUsuario }));

            }


        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editUser/redux/saga', 'editUserSaveRequest');
            yield put(editUserSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editUserOpenRequest),
        fork(editUserSaveRequest)
    ]);
}
