let locales = {
    "dashboard_creationDate" : "Fecha Creación",
    "dashboard_semaforo" : "Semáforo",
    "dashboard_link" : "Link",
    "dashboard_cities" : "Ruta",
    "dashboard_lastNovedad" : "Última Novedad",
    "dashboard_orderCar" : "Sello Satelital",
    "dashboard_startService" : "Inicio del Servicio",
    "dashboard_driverPhonerNumber" : "Celular del conductor",
};

export default locales;