let locales = {
    editClient_title: 'Crear cliente',
    editClient_titleUpdate: 'Editar cliente',
    editClient_titleDesactivate: 'Desactivar Cliente',
    editClient_socialReason: 'Razón Social',
    editClient_nit: 'NIT',
    editClient_phone: 'Teléfono',
    editClient_address: 'Dirección',
    editClient_email: 'Correo',
    editClient_country: 'País',
    editClient_city: 'Ciudad',
    editClient_clientExists: 'La Razón Social, Correo o NIT ya se encuentran registrados para otro cliente.',
    editClient_clientHasSeguimientos: 'El cliente no puede desactivarse ya que tiene seguimientos activos asignados.',
    editClient_clientWishDesactivate: '¿Desea desactivar el cliente? Si hace esto, ninguno de los usuarios asignados a este cliente podrá autenticarse en la aplicación.',
};

export default locales;