let locales = {
    seguimientosList_title: 'Seguimientos',
    seguimientosList_add: 'Agregar seguimiento',
    seguimientosList_uploadExcel: 'Cargar por excel',
    seguimientosList_client: 'Empresa',
    seguimientosList_order: "Sello Satelital",
    seguimientosList_vehicle: 'Vehículo',
    seguimientosList_manifest: 'Manifiesto',
    seguimientosList_insurance: 'Aseguradora',
    seguimientosList_originCity: 'Ciudad Origen',
    seguimientosList_destinyCity: 'Ciudad Destino',
    seguimientosList_route: 'Ruta',
    seguimientosList_kilometers: 'Kilómetros',
    seguimientosList_estimatedTime: 'Tiempo estimado',
    seguimientosList_startDate: 'Fecha de Salida',
    seguimientosList_endDate: 'Fecha de Llegada',
    seguimientosList_driverName: 'Nombre del Conductor',
    seguimientosList_driverPhone: 'Teléfono del Conductor',
    seguimientosList_accompanist: 'Escolta',
    seguimientosList_accompanist: 'Escolta',
    seguimientosList_accompanistVehicle: 'Vehículo Escolta',
    seguimientosList_restriction: 'Restricción',
    seguimientosList_observation: 'Observación',
    seguimientosList_status: 'Estado',
    seguimientosList_filters_planned: 'Pendiente',
    seguimientosList_filters_active: 'Activo',
    seguimientosList_filters_cancelled: 'Cancelado',
    seguimientosList_filters_finished: 'Terminado',
    seguimientosList_vehiclePlate: 'Placa del vehículo'
    
};

export default locales;