//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    VEHICLE_LIST_SEARCH
} from 'config/redux/actionTypes';

//actions
import {
    vehicleListSearchResult,
    clearRedux
} from 'config/redux/actions';


function* vehiclesListSearchRequest() {
    yield takeEvery(VEHICLE_LIST_SEARCH, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, status } = payload;
            let data = yield call(
                clientQuery,
                `query searchVehicles(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $status: Int){
                    vehicles: searchVehicles(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        status: $status
                    ){
                        count
                        items{
                            IdCarro
                            IdCliente
                            ClienteInfo{
                                RazonSocial
                                Nit
                            }
                            Placa
                            Marca
                            Clase
                            Modelo
                            Color
                            Capacidad
                            Contenedor
                            Seriales
                            isAccompanist
                            estado
                            UsuarioCreacionInfo {
                                Nombre
                            }
                        }
                        
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    status
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(vehicleListSearchResult(data.vehicles || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/VEHICLE sList/redux/saga', 'vehiclesListSearchRequest');
            yield put(vehicleListSearchResult([]));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(vehiclesListSearchRequest)
    ]);
}
