import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Icons } from "components";

import { Spin, Alert, Menu, Button, Pagination } from "antd";
import "./css/styles.css";
moment.locale("es-co");

import { detalleSeguimientoDetalleSetPrevPage } from "config/redux/actions";

export const Item = (props) => {
  const dispatch = useDispatch();
  const { values, mapRef } = props;
  const { messages } = useIntl();
  

  let semaforoInfo = {
    1: {
      className: "text-danger",
      name: "AiFillCloseCircle",
    },
    2: {
      className: "text-warning",
      name: "AiFillExclamationCircle",
    },
    3: {
      className: "text-success",
      name: "AiFillCheckCircle",
    },
  };
  return (
    <div
      className="border rounded-4 w-100 p-2 my-3"
      style={{ borderColor: "#00a6b0", borderWidth: 2 }}
      onClick={() => {
        mapRef && mapRef.current?.centerMarker(values?.IdSeguimiento);
      }}
    >
      <div className="row">
        <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
          <Link
            onClick={() =>
              dispatch(detalleSeguimientoDetalleSetPrevPage("dashboard"))
            }
            to={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`}
          >
            <Icons
              family="ai"
              name={
                semaforoInfo[values?.SemaforoColor]?.name ||
                "AiFillExclamationCircle"
              }
              className={
                `${
                  semaforoInfo[values?.SemaforoColor]?.className || "text-gray"
                }` + " fs-1"
              }
            />
          </Link>
        </div>
        <div className="col-12 col-md-10">
          <div className="d-flex justify-content-center">
            <p className="mr-0 text-cyan">{messages["dashboard_orderCar"]}:</p>
            <Link
              to={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`}
            >{`${values?.Orden} - ${values?.CarroInfo?.Placa}`}</Link>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div>
                <p className="m-0 text-cyan">
                  {messages["seguimientosList_client"]}:
                </p>
                <span>{values?.ClienteInfo?.RazonSocial}</span>
              </div>
              <div>
                <p className="m-0 text-cyan">{messages["dashboard_cities"]}:</p>
                <span>{`${
                  values?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() +
                  values?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase()
                } - ${
                  values?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() +
                  values?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase()
                }`}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>
                <p className="m-0 text-cyan">
                  {messages["dashboard_startService"]}:
                </p>
                <span>{moment(values?.FechaSalida).format("lll")}</span>
              </div>
              <div>
                <p className="m-0 text-cyan">
                  {messages["dashboard_lastNovedad"]}:
                </p>
                <span className="text-muted text-small">
                  {moment(values?.FechaHoraReporte).format("lll")}
                </span>
                <br />
                <span className={values?.SN == 0 ? "text-danger" : ""}>
                  {values?.UltimoDetalleSeguimiento?.Observacion || "Sin Observación"}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>
                <p className="m-0 text-cyan">
                  {messages["seguimientosList_accompanist"]}:
                </p>
                <span>{values?.AcompananteInfo?.Nombre}</span>
              </div>
              <div>
                <p className="m-0 text-cyan">
                  {messages["dashboard_driverPhonerNumber"]}:
                </p>
                <span>{values?.CelularCond}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const { items, loading = false, onRefresh = () => {}, count = 0, mapRef } = props;

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const refreshData = useCallback((newPage, newPageLimit) => {
    let currentPage = page;
    let currentPageLimit = pageLimit;
    let currentOpenSearch = '';

    if (currentPage != newPage) {
      currentPage = newPage;
      setPage(newPage);
    }

    if (currentPageLimit != newPageLimit) {
      currentPageLimit = newPageLimit;
      setPageLimit(newPageLimit);
    }

    onRefresh &&
    onRefresh(
        currentOpenSearch,
        currentPageLimit,
        (currentPage - 1) * currentPageLimit
      );
  });
  return (
    <Spin spinning={loading}>
      <div className="row">
        <div className="col-12 text-md-end mb-1">
          <div className="d-inline-block">
            <div className="d-inline-block ps-1">
              <Button
                className="btn btn-icon btn-icon-only btn-foreground-alternate shadow"
                icon={<Icons family="ai" name="AiOutlineReload" />}
                onClick={() => refreshData(page, pageLimit)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{overflowY: "auto", maxHeight: 370}}>
          {items &&
            [...items,].map((v) => <Item key={v?.IdSeguimiento} values={v} mapRef={mapRef} />)}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
      <Pagination defaultCurrent={1} onChange={refreshData} current={page} pageSize={pageLimit} total={count} />
      </div>
    </Spin>
  );
};
