import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Button,
  Input,
  notification,
  Spin,
} from "antd";

import {
  clearRedux,
  recoverPasswordRequest,
  verifyCode,
} from "config/redux/actions";

export default function RecoverPassword() {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const {
    loadingRemindPassword,
    resultRemindPassword,
    recoverPasswordModal,
  } = useSelector((state) => state.loginRedux);


  useEffect(() => {
    if (resultRemindPassword) {
      if (resultRemindPassword.status) {
        notification.success({
          message: "Bien!",
          description: "Se ha enviado el correo exitosamente!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[resultRemindPassword.error] || resultRemindPassword.error,
        });
      }
    }
  }, [resultRemindPassword]);

  const close = useCallback(() => {
    form.setFieldsValue({Email: ""});
    dispatch(clearRedux("LOGIN"));
  });

  return (
    <>
      <Modal
        title={messages["login_forgotPassword_title"]}
        visible={recoverPasswordModal}
        onCancel={() => {
          close();
        }}
        width={500}
        footer={null}
        maskClosable={false}
      >
        <Spin spinning={loadingRemindPassword}>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    
                  </div>
                </div>
                <Form
                  labelCol={{ span: 8 }}
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(recoverPasswordRequest(values?.Email));
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <p>{messages["login_forgotPassword_description"]}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                    <Form.Item
                        name="Email"
                        rules={[
                          {type: "email"},
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }} className="row">
                    <div className="col-12 d-flex justify-content-center gap-2">
                      <Button
                        type="primary"
                        className="btn btn-alternate btn-end"
                        onClick={close}
                        loading={loadingRemindPassword}
                      >
                        {messages["cancel"]}
                      </Button>

                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary btn-end"
                        loading={loadingRemindPassword}
                      >
                        {messages["ok"]}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        </Spin>
      </Modal>
    </>
  );
}
