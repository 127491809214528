import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader,
  DatePicker,
  Dropdown,
  Menu,
  Table,
  Card,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { InputFile } from "components";
import TableView from "components/TableView";

import {
  vehicleFilesListSearch,
  addFileVehicleOpen,
  deleteVehicleFile,
  clearRedux,
} from "config/redux/actions";

import moment from "moment";
import AddFileVehicle from "../addFile";

const VehicleFiles = (props) => {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { loading, data, resultDelete } = useSelector(
    (state) => state.vehicleFilesRedux
  );

  useEffect(() => {
    onRefreshTable("", 10, 0);
    return () => {
      dispatch(clearRedux("VEHICLE_FILES"))
    }
  }, []);

  useEffect(() => {
    if (resultDelete) {
      if (resultDelete?.isSuccess) {
        notification.success({
          message: "Bien!!",
          description: "Registro eliminado exitosamente!!",
        });
        onRefreshTable()
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [resultDelete]);


  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(
      vehicleFilesListSearch(
        vehicle?.IdCarro,
        openSearch,
        limit,
        offset
      )
    );
  });

  const openAddFile = (fileData = null) => {
    dispatch(addFileVehicleOpen(fileData, vehicle));
  };

  const columns = useMemo(() => [
    {
      width: 200,
      title: messages["vehicleDetail_fileList_documentName"],
      dataIndex: "name",
      key: "name",
      render: (v, values) => (
        <a
          href={values?.url}
          target="_blank"
          className="m-2 icon-hover"
          style={{ width: 45, padding: 0 }}
        >
          {v}
        </a>
      ),
    },
    {
      title: messages["vehicleDetail_fileList_documentDescripcion"],
      dataIndex: "description",
      key: "description",
    },
    {
      width: 200,
      title: messages["vehicleDetail_fileList_documentDate"],
      key: "createdOn",
      render: (row) => (
        <>{row?.createdOn ? moment(row.createdOn).format("YYYY-MM-DD") : ""}</>
      ),
    },
    {
      key: "options",
      width: 50,
      render: (v,values) => (
        <Dropdown
          menu={{
            items: [{
              key: 'mu1',
              label: messages["edit"],
              onClick: () => openAddFile(values)
            },{
              key: 'mu2',
              label: messages["delete"],
              onClick: () =>
              dispatch(
                deleteVehicleFile(
                  vehicle?.IdCarro,
                  values.id
                )
              )
            }]
          }}
          placement="bottomCenter"
        >
          <MoreOutlined className="icon" />
        </Dropdown>
      ),
    },
  ]);

  return (
    <div className="row">
      <div className="col-12">
        <Card style={{ borderTopColor: "transparent" }}>
          <div className="container">
            <div className="d-inline-block mb-4">
              <Button
                className="btn btn-outline-primary btn-icon btn-icon-start mb-44 ms-2 w-100 w-md-auto"
                onClick={() => openAddFile()}
              >
                {messages["vehicleDetail_fileList_addDocument"]}
              </Button>
            </div>
          </div>
          <Table
            dataSource={data?.items || []}
            loading={loading}
            columns={columns}
            count={data?.count}
            scroll={{ x: 1050 }}
            onRefreshTable={onRefreshTable}
          />
        </Card>
      </div>
      <AddFileVehicle onClose={(refresh) => refresh && onRefreshTable()} />
    </div>
  );
};

export default VehicleFiles;
