import {
    //TYPES
    EDIT_DETALLE_SEGUIMIENTO_OPEN,
    EDIT_DETALLE_SEGUIMIENTO_OPEN_RESULT,
    EDIT_DETALLE_SEGUIMIENTO_CLOSE,
    EDIT_DETALLE_SEGUIMIENTO_SAVE,
    EDIT_DETALLE_SEGUIMIENTO_SAVE_RESULT
} from 'config/redux/actionTypes';

export const editDetalleSeguimientoOpen = (id, justMedia) => ({
    type: EDIT_DETALLE_SEGUIMIENTO_OPEN,
    payload: { id, justMedia }
});

export const editDetalleSeguimientoOpenResult = (data) => ({
    type: EDIT_DETALLE_SEGUIMIENTO_OPEN_RESULT,
    payload: data
});

export const editDetalleSeguimientoClose = () => ({
    type: EDIT_DETALLE_SEGUIMIENTO_CLOSE,
    //payload: data
});

export const editDetalleSeguimientoSave = (data) => ({
    type: EDIT_DETALLE_SEGUIMIENTO_SAVE,
    payload: {data}
});

export const editDetalleSeguimientoSaveResult = (result) => ({
    type: EDIT_DETALLE_SEGUIMIENTO_SAVE_RESULT,
    payload: result
});