import {
    VEHICLE_DETAIL_GET_DETAIL,
    VEHICLE_DETAIL_GET_DETAIL_RESULT,
    // VEHICLE_DETAIL_GET_HISTORY,
    // VEHICLE_DETAIL_GET_HISTORY_RESULT,
    VEHICLE_DETAIL_GET_FILES,
    VEHICLE_DETAIL_GET_FILES_RESULT
} from 'config/redux/actionTypes';

export const vehicleDetailGetDetail = (id) => ({
    type: VEHICLE_DETAIL_GET_DETAIL,
    payload: { id },
});
export const vehicleDetailGetDetailResult = (result) => ({
    type: VEHICLE_DETAIL_GET_DETAIL_RESULT,
    payload: { ...result },
});

// export const vehicleDetailGetHistory = (openSearch, limit, offset, idVehicle) => ({
//     type: VEHICLE_DETAIL_GET_HISTORY,
//     payload: { openSearch, limit, offset, idVehicle },
// });
// export const vehicleDetailGetHistoryResult = (response) => ({
//     type: VEHICLE_DETAIL_GET_HISTORY_RESULT,
//     payload: { result },
// });

export const vehicleDetailGetFiles = (openSearch, limit, offset, id) => ({
    type: VEHICLE_DETAIL_GET_FILES,
    payload: { openSearch, limit, offset, id },
});
export const vehicleDetailGetFilesResult = (response) => ({
    type: VEHICLE_DETAIL_GET_FILES_RESULT,
    payload: { response },
});