import {
    //TYPES
    AUTH_GET_USER_PROFILE,
    AUTH_GET_USER_PROFILE_RESULT,
    AUTH_LOGOUT
} from 'config/redux/actionTypes';

export const authGetUserProfile = (history) => ({
    type: AUTH_GET_USER_PROFILE,
    payload: { history },
});

export const authGetUserProfileResult = (data) => ({
    type: AUTH_GET_USER_PROFILE_RESULT,
    payload: data,
});

export const authLogout = (history) => ({
    type: AUTH_LOGOUT,
    payload: { history },
});