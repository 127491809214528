import {
    //TYPES
    VEHICLE_FILES_LIST,
    VEHICLE_FILES_LIST_RESULT,
    DELETE_VEHICLE_FILE,
    DELETE_VEHICLE_FILE_RESULT,
    VEHICLE_PHOTOS,
    VEHICLE_PHOTOS_RESULT
} from 'config/redux/actionTypes';

export const vehicleFilesListSearch = (idCarro, openSearch, limit, offset) => ({
    type: VEHICLE_FILES_LIST,
    payload: { idCarro, openSearch, limit, offset }
});

export const vehicleFilesListSearchResult = (data) => ({
    type: VEHICLE_FILES_LIST_RESULT,
    payload: data
});

export const deleteVehicleFile = (idCarro, idFile) => ({
    type: DELETE_VEHICLE_FILE,
    payload: { idCarro, idFile }
});

export const deleteVehicleFileResult = (result) => ({
    type: DELETE_VEHICLE_FILE_RESULT,
    payload: result
});

export const getVehiclePhotos = (idCarro) => ({
    type: VEHICLE_PHOTOS,
    payload: { idCarro }
});

export const getVehiclePhotosResult = (data) => ({
    type: VEHICLE_PHOTOS_RESULT,
    payload: data
});