//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'
import moment from 'moment'

//action types
import {
    COMMON_GET_CATALOG
} from 'config/redux/actionTypes';

//actions
import {
    getCatalogResult,
    catalogLoadigns
} from 'config/redux/actions';


const getCatalogInfo = (key, force, query, localData, querys) => {

    let getCatalog = true;
    let tempdata = sessionStorage.getItem('catalog_' + key);
    if (tempdata) tempdata = JSON.parse(tempdata);

    if (tempdata && tempdata.date && !force) {
        let date = moment(tempdata.date).add(60, 'seconds');
        if (date > moment()) {
            getCatalog = false;
        }
    }

    if (getCatalog) {
        querys.push(query);
    }
    else
        localData[key] = tempdata.items;

    return [querys, localData]
}


export function* getCagalogRequest() {
    yield takeEvery(COMMON_GET_CATALOG, function* ({ payload }) {


        try {
            const { keys, force, params } = payload; //Flag force to update elements.
            let querys = [];
            let localData = {};
            let loadings = {};

            keys.forEach((key) => {
                switch (key) {
                    case 'roles':
                        loadings.loadingRoles = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            roles: getRoles {
                                IdRol
                                Rol
                                isAdminRol
                            }`,
                            localData, querys)
                        break;
                    case 'clients':
                        loadings.loadingClients = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        clients: getClients(all: false){
                            IdCliente
                            RazonSocial
                        }`,
                            localData, querys)
                        break;
                    case 'states':
                        loadings.loadingStates = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                                states: searchDepartments
                                ${params && params["states"] 
                                ? `(
                                    ${params["states"].openSearch ? `openSearch: "${params["states"].openSearch}"` : ''}  
                                    ${params["states"].PaisCode ? `PaisCode: ${params["states"].PaisCode}` : ''}  
                                    )`
                                : ''
                            } 
                                {
                                    DepartmentCode
                                    DepartmentName
                                }`,
                            localData, querys)
                        break;
                    case 'cities':
                        loadings.loadingCities = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        cities: searchCities
                        ${params && params["cities"] 
                            ? `(
                                ${params["cities"].openSearch ? `openSearch: "${params["cities"].openSearch}"` : ''}  
                                ${params["cities"].PaisCode ? `PaisCode: ${params["cities"].PaisCode}` : ''}  
                                ${params["cities"].DepartmentCode ? `DepartmentCode: ${params["cities"].DepartmentCode}` : ''}  
                                )`
                            : ''
                        } 
                            {
                                CityCode
                                DepartmentCode
                                CityName
                            }
                    `,
                            localData, querys)
                        break;

                    case 'documentTypes':
                        loadings.loadingDocumentTypes = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            documentTypes: searchDocumentTypes {
                                id
                                name
                            }`,
                            localData, querys)
                        break;

                    case 'vehicles':
                        loadings.loadingVehicles = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            vehicles: getVehicles(all: false){
                                IdCarro
                                IdCliente
                                Placa
                                Marca
                                Modelo
                                Color
                                estado
                            }
                        `,
                            localData, querys)
                        break;
                    case 'accompanistVehicles':
                        loadings.loadingAccompanistVehicles = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        accompanistVehicles: getAccompanistVehicles{
                            IdCarro
                            IdCliente
                            Placa
                            Marca
                            Modelo
                            Color
                            estado
                        }
                        `,
                            localData, querys)
                        break;
                    case 'accompanists':
                        loadings.loadingAccompanist = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        accompanists: getAccompanists(all: false){
                            IdAcompanante
                            Nombre
                            Cedula
                        }
                        `,
                            localData, querys)
                        break;
                    case 'statuses':
                        loadings.loadingStatuses = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        statuses: getEstadoSeguimientos{
                            IdEstado
                            NombreEstado
                        }
                        `,
                            localData, querys)
                        break;
                    case 'places':
                        loadings.loadingPlaces = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            places: getPlaces ${params && params["places"]?.placeType ? `(placeType: "${params["places"]?.placeType}" )` : ''} {
                                id
                                name
                                placeType
                                latitude
                                longitude
                            }`,
                            localData, querys)
                        break;
                    case 'workPermissions':
                        loadings.loadingWorkPermissions = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        workPermissions: getWorkPermissions ${params && params["workPermissions"]?.status ? `(status: ${params["workPermissions"]?.status} )` : ''} {
                                id
                                place{
                                    placeType
                                    name
                                }
                                workorder
                                specificPlace
                                status
                                latitude
                                longitude
                            }`,
                            localData, querys)
                        break;
                    /* case 'epsList':
                        loadings.loadingEpsList = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            epsList: searchEps {
                                id
                                name
                            }`,
                            localData, querys)
                        break; */

                    /* case 'arls':
                        loadings.loadingArls = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            arls: searchArls {
                                id
                                name
                            }`,
                            localData, querys)
                        break; */

                    case 'orderVisitsAvailable':
                        loadings.loadingOrderVisitsAvailable = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            orderVisitsAvailable: getOrderVisitsAvailable (
                                openSearch: "${params["orderVisitsAvailable"]?.openSearch || ""}",
                                placeId: ${params["orderVisitsAvailable"]?.placeId}
                            ) {
                                id
                                activity
                                place {
                                    id
                                    name
                                    placeType
                                }
                            }`,
                            localData, querys)
                        break;

                    case 'arCertificatesAvailable':
                        loadings.loadingArCertificatesAvailable = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            arCertificatesAvailable: getArCertificatesAvailable  (
                                openSearch: "${params["arCertificatesAvailable"]?.openSearch || ""}",
                                placeId: ${params["arCertificatesAvailable"]?.placeId || 0},
                                endDate: "${params["arCertificatesAvailable"]?.endDate}",
                            ) {
                                id
                                workDescription
                                EPP
                                placeId
                                defaultRisk
                                riskCategories
                            }`,
                            localData, querys)
                        break;

                    case 'persons':
                        loadings.loadingPersons = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                            persons: getPersons  (
                                openSearch: ${params && params["persons"]?.openSearch ? '"' + params["persons"]?.openSearch + '"' : '""'}
                            ) {
                                id
                                firstName
                                lastName
                                email
                                phone
                                status
                                imageUrl
                                position
                                document
                                documentType {
                                  name
                                }
                                companyContact {
                                    signatureUrl
                                }
                            }`,
                            localData, querys)
                        break;
                    case 'users':
                        loadings.loadingPlaces = true;
                        [querys, localData] = getCatalogInfo(key, force, `
                        users: getUsers {
                            id
                            firstName
                            lastName
                            username
                            document
                            documentType {
                              name
                            }
                            email
                            signatureURL
                            roles {
                                id
                            }
                        }`,
                            localData, querys)
                        break;
                    case 'contracts':
                        loadings.loadingContracts = true;
                        let query = `
                            contracts: getContracts {
                                id
                                companyId
                                company {
                                    id 
                                    name
                                }
                                placeId
                                place {
                                    id
                                    name
                                }
                                contractNumber
                                description
                                startDate
                                endDate
                            }
                        `;

                        if (params && params["contracts"]) {
                            query = `
                                contracts: searchContracts(
                                    openSearch: "${params["contracts"]?.openSearch}", 
                                    placeId: ${params["contracts"]?.placeId}
                                ) {
                                    items {
                                        id
                                        companyId
                                        placeId
                                        contractNumber
                                        description
                                        startDate
                                        endDate
                                    }
                                    count
                                }
                            `;
                        }

                        [querys, localData] = getCatalogInfo(
                            key,
                            force,
                            query,
                            localData, querys)
                        break;
                }

            });

            yield put(catalogLoadigns(loadings));

            let finalQuery = 'query { ';
            querys.forEach((x) => {
                finalQuery += x;
            });
            finalQuery += ' }';

            let data = {};
            if (querys.length > 0)
                data = yield call(clientQuery, finalQuery, {}, endpoints.GRAPHQL_GENERAL);

            keys.forEach((key) => {
                if (data[key])
                    sessionStorage.setItem(
                        'catalog_' + key,
                        JSON.stringify({ date: moment(), items: data[key] }),
                    );
            });

            for (let i in loadings) {
                loadings[i] = false;
            }

            yield put(getCatalogResult({ ...data, ...localData, ...loadings }));
        } catch (exc) {
            // catch throw
            ExceptionManager(exc, 'config/redux/common/saga', 'getCagalogRequest');
        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(getCagalogRequest)
    ]);
}
