import {
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO_RESULT,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLES,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLES_RESULT,
    DETALLESEGUIMIENTO_DETAIL_SET_PREVPAGE,
    DELETE_DETALLESEGUIMIENTO,
    DELETE_DETALLESEGUIMIENTO_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    dataDetails: null,
    loadingDetails: false,
    resultDelete: null,
    prevPage: "dashboard"
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO:
            return { ...state, loading: true, data: null, error: false };

        case DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO_RESULT:
            return { ...state, loading: false, data: action?.payload?.response };

        case DETALLESEGUIMIENTO_DETAIL_GET_DETALLES:
            return{...state, loadingDetails: true, dataDetails: null}

        case DETALLESEGUIMIENTO_DETAIL_GET_DETALLES_RESULT:
            return { ...state, loadingDetails: false, dataDetails: action?.payload?.response };

        case DETALLESEGUIMIENTO_DETAIL_SET_PREVPAGE:
            return {...state, prevPage: action?.payload?.prevPage}
        
        case DELETE_DETALLESEGUIMIENTO:
            return { ...state, loading: true, resultDelete: null }

        case DELETE_DETALLESEGUIMIENTO_RESULT:
            return { ...state, loading: false, resultDelete: action.payload }

        case CLEAR_REDUX:
            return ["", "DETALLESEGUIMIENTO_DETAIL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
