let locales = {
    editVehicle_title: 'Crear vehículo',
    editVehicle_titleUpdate: 'Editar vehículo',
    editVehicle_client: 'Empresa',
    editVehicle_plate: 'Placa',
    editVehicle_brand: 'Marca',
    editVehicle_class: 'Clase',
    editVehicle_model: 'Modelo',
    editVehicle_color: 'Color',
    editVehicle_capacity: 'Capacidad',
    editVehicle_container: 'Contenedor',
    editVehicle_serials: 'Seriales',
    editVehicle_accompanist: '¿Es Escolta?',
    editVehicle_vehicleExists: 'El vehículo ya se encuentra registrado',
};

export default locales;