import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { OutTable, ExcelRenderer } from "react-excel-renderer";

import { Modal, Form, Input, Button, Spin, Upload, Tag, Card, notification, Progress } from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Dragger } = Upload;

import * as XLSX from "xlsx";


//Funciones de ajustes visuales para excelUpload
import {adjustColumnsRow, 
        changeRowsBgColor, 
        estructureColumnsData
      } from "config/helpers/excelUploadHelpers/excelUploadVisualHelpers"; 

//Funciones de validacion a campos para excelUpload
import { 
  isRowWithEmptyData, 
  compareArrays, 
  doesDataExists, 
  isValidData, 
  isRepeated,
  isRepeatedRow
} from "config/helpers/excelUploadHelpers/excelUploadValidationHelpers";


import { 
  excelUploadClose, 
  excelUploadSave,
  getCatalog,
  getUsers,
  clearRedux
} from "config/redux/actions";


//Estilos de tabla excel
import "config/helpers/excelUploadHelpers/excelUploadStyles.css"; 

const ExcelUpload = ({ refresh }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [cols, setCols] = useState(null);
  const [rows, setRows] = useState(null);
  const [dataArray, setDataArray] = useState();
  const [moreThanFifty, setmoreThanFifty] = useState(false);
  const [allRegistersErrors, setallRegistersErrors] = useState(false);
  const [uploadError, setUploadError] = useState({haveError: false, msg: ''});
  const [containErrors, setcontainErrors] = useState(false);
  const [loadingPercentN, setloadingPercentN] = useState(0);

  const { isOpen, loading, result, loadingPercent, users, loadingUsers } = useSelector(
    (state) => state.excelUploadRedux
  );

  useEffect(() => {
    dispatch(getCatalog(["roles", "companies"]))
    isOpen && dispatch(getUsers())
  }, [isOpen]);

  const { roles, companies } = useSelector(
    (state) => state.commonRedux
  );

  useLayoutEffect(() => {
    //AJUSTES VISUALES
    adjustColumnsRow()
  }, [rows]);
  

  useEffect(() => {
    if (result !== null) {
      if (result?.status) {
        if (refresh) refresh();
        setTimeout(() => {
          notification.success({description: "Se han creado los usuarios con éxito.", message:"Creación exitosa"})
          close()
        }, 500);
      }else{
        notification.error({description: "Ha ocurrido un error, por favor intente nuevamente.", message:  messages[result?.error] || result?.error  })
      }  
    }
  }, [result])

  useEffect(() => {
    if (loadingPercent === 100) {
    setloadingPercentN(loadingPercent)
    }else{
      setloadingPercentN(loadingPercent/10)
    }
  }, [loadingPercent]);
  

  const close = useCallback(() => {
    dispatch(excelUploadClose());
    setCols(null)
    setRows(null)
    setDataArray(null)
    setloadingPercentN(null)
    setUploadError({haveError: false, msg: ''});
    form.resetFields();
    dispatch(clearRedux("EXCEL_UPLOAD"));
  });

  //FILES HANDLER
  //Validamos si el archivo tiene la extensión deseada
  const fileHandler = (e) => {
    setUploadError({haveError: false, msg: ''});
    if (e.file && e.fileList.length > 0) {
      let fileObj = e.file;
      let fileName = fileObj.name;

      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
        fileObj.status = "done";
        renderFile(fileObj.originFileObj, fileObj);
      } else {
        setUploadError({
          haveError: true,
          msg: "El archivo debe tener la extensión .xlsx"
        })
        fileObj.status = "error";
        fileObj.response = "El archivo debe tener extensión .xlsx";
        setRows(null);
      }
    } else {
      setRows(null);
    }
  };
  //RENDER FILE
  const renderFile = (fileObj, fileObjData) => {
    /*
    var reader = new FileReader();

    reader.onload = function(e) {
      console.log("Entre a leer el xlsx #1")
      var data = e.target.result;
      console.log("Entre a leer el xlsx #2")
      try {
        var workbook = XLSX.read(data, {
          type: 'binary'
        });
      } catch(e) {
        console.log("Se cagó está vaina")
      }
      console.log("Entre a leer el xlsx #3")
      workbook.SheetNames.forEach(function(sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log(json_object);

      })

    };

    reader.onerror = function(ex) {
      console.log("Error: ", ex);
    };

    reader.readAsBinaryString(fileObj);
    */
    
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setUploadError({
          haveError: true,
          msg: "El archivo no puede ser leído, es posible que se encuentre corrupto"
        })
        fileObjData.status = "error"
        fileObjData.response = "El archivo no puede ser leído, es posible que se encuentre corrupto";
        setRows(null)
        console.log(err);
      } else {
        //Remuevo la primera fila pues contiene los títulos de las columnas
        let nonEmptyRows = resp.rows.filter(row => row.length>0).slice(1, 51)
        //Tomo los primeros 50 registros
        setmoreThanFifty(resp.rows.filter(row => row.length>0).length > 51)
        //validamos plantilla
        if (compareArrays(resp.rows[0], ["Usuario",	"Email", "Nombre","Apellido", "Roles", "Empresa contratista"])
        ) {
          if (nonEmptyRows[0]) {
            //Agregamos las columnas título "Status" y "Mensaje"
            const cols = estructureColumnsData(resp.rows[0])
            setCols(cols);
            //Válido el contenido de las rows en busca de errores
            const {dataArray, rows} = validateDataTable(nonEmptyRows);

            setRows(rows);
            setDataArray(dataArray)

            //Cambio de color en el fondo
            changeRowsBgColor()

            //Válido si TODOS los registros tienen errores para impedir el cargue
            setallRegistersErrors(nonEmptyRows.every(row => row[row.length-2].props.children === "ERROR"))

          }else{
            setUploadError({
              haveError: true,
              msg: "El archivo no contiene información"
            })
            fileObjData.status = "error"
            fileObjData.response = "El archivo no contiene información";
            setRows(null)
          }
        }else{
          //error en caso de plantilla incorrecta
          setUploadError({
            haveError: true,
            msg: "El archivo no corresponde con la plantilla"
          })
          fileObjData.status = "error"
          fileObjData.response = "El archivo no corresponde con la plantilla";
          setRows(null)
        }
        
      }

    });
    
  };
  //VALIDATE TABLE
  //Validamos las tabla contenida en el Excel
  const validateDataTable = (rows) => {
    let dataArray = [];
    let dataObject;
    //Empezamos a revisar el contenido de las filas
    rows.forEach((row, i) => {
      //Buscamos si tienen un campo vacío
      let isContractor = row[4]?.toLowerCase().includes("contratista");
      //Dependiendo de si el usuario tiene el rol "Contratista" o no se determinan los campos obligatorios
      if(isContractor ?
          !isRowWithEmptyData(row, [0, 1, 2, 3, 4, 5]) :
          !isRowWithEmptyData(row, [0, 1, 2, 3, 4])) {

        //Rellenamos el campo vacío que dejaría una ausencia de empresa
        while(row.length < 6) {
          row.push("");
        }

        //Buscamos si existen campos repetidos dentro de las columnas sensibles 
        const isUserRepeated = isRepeatedRow(rows, row, 0) /* ¿Usuarios repetidos */
        const isEmailRepeated = isRepeatedRow(rows, row, 1) /* ¿Correos repetidos */

        if (isUserRepeated || isEmailRepeated) {
          setcontainErrors(true)
          //Si no tienen campos vacíos le agrego el mensaje de data repetida
          if(row.length >= 6) {
            row.push(
              <Tag className="m-auto" color={"volcano"}>ERROR</Tag>,
              <Tag className="m-auto" color={"yellow"}>{messages["excelUpload_repeatedData"]}</Tag>,
            )
          }
        }
        //Buscamos que no este entre las filas con contenido repetido (dentro del excel)
        if (!isUserRepeated && !isEmailRepeated) {
          //Creamos un objeto con la data de la fila "i"
          dataObject = {
            username: row[0].toString().trim(),
            email: row[1].toString().trim(),
            firstName: row[2].toString().trim(),
            lastName: row[3].toString().trim(),
            roles: row[4].toString().split(","),
            companyId: row[5].toString().trim()
          }
          //Validamos que la data sea correcta
          const { status: statusData, message: messageData } = isValidData(dataObject)
          if(statusData) {
            //Validamos que el usuario no exista y compania roles si
            const {status, rolId, companyId, message} = isContractor ? doesDataExists(dataObject, users, roles, companies) : doesDataExists(dataObject, users, roles, "");
            if (status) {
              if (rolId.includes(3) && rolId.includes(7)) {
                row.push(
                  <Tag className="m-auto" color={"volcano"}>
                    ERROR
                  </Tag>,
                  <Tag className="m-auto" color={"yellow"}>
                    {messages["excelUpload_hseAndSTInvalid"]}
                  </Tag>
                  )
              } else {
                dataObject.roles = rolId;
                dataObject.companyId = companyId;
                row.push(
                  <Tag className="m-auto" color={"green"}>
                    OK
                  </Tag>,
                  <Tag className="m-auto" color={"green"}>
                    {messages[message] || message}
                  </Tag>
                  )
                dataArray.push(dataObject)
                dataObject={}
              }
            } else {
              //User ya existe en db o compania y roles incorrectos
              row.push(
                <Tag className="m-auto" color={"volcano"}>
                  ERROR
                </Tag>,
                <Tag className="m-auto" color={"yellow"}>
                  {messages[message] || message}
                </Tag>,
                ) 
              setcontainErrors(true)
            }
          }
          else {
            //data invalida
            row.push(
              <Tag className="m-auto" color={"volcano"}>
                ERROR
              </Tag>,
              <Tag className="m-auto" color={"volcano"}>
                {messages[messageData] || messageData}
              </Tag>,
            )
            setcontainErrors(true)
          }
        }
      } else {
        //campos vacios
        while(row.length < 6) {
          row.push("");
        }
        row.push(
          <Tag className="m-auto" color={"volcano"}>
            ERROR
          </Tag>,
          <Tag className="m-auto" color={"volcano"}>
            {messages["personalUpload_emptyData"]}
          </Tag>
          )
        setcontainErrors(true)
      }
    })
    return {dataArray, rows};
  };

  return (
    
    <Modal
      title={messages["excelUpload_title"]}
      visible={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={900}
      footer={null}
      maskClosable={false}
    >
      <div className="row mt-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin tip="Loading..." spinning={loading || loadingUsers}>
                {
                  loadingPercent ? (<div className="percentLoadingExcel">
                    <Progress type="circle" percent={(loadingPercentN !== null) ? parseInt(loadingPercentN) : 0} />
                  </div>)
                  : (<> 
                  <p> {messages["excelUpload_templateExample"]} </p>
                  <a type="download" href="https://odcpermisos.ingeniosoft.co/files/templates/ODC%20-%20Plantilla%20Importacion%20masiva%20de%20Usuarios.xlsx"
                   style={{color: "#00BC64"}} >
                    {messages["excelDownload_template"]}
                  </a>
                  <Form
                    name="userUpload"
                    form={form}
                  >
                    <Form.Item
                      name="userUploadDragger"
                    >
                      <Dragger onChange={fileHandler} maxCount={1} multiple={false}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          {" "}
                          {messages["excelUpload_titleUpload"]}{" "}
                        </p>
                        <p className="ant-upload-hint">
                          {" "}
                          {messages["excelUpload_message"]}{" "}
                        </p>
                      </Dragger>
                      {uploadError.haveError && 
                        <p className="pt-2 px-1" style={{color: '#ff4d4f'}}>{uploadError.msg}</p>
                      }
                    </Form.Item>
                  </Form>
                {rows && (
                  <Card className="my-3">
                    <OutTable
                      data={rows}
                      columns={cols}
                      tableClassName="ExcelTable2007 tablaImportUsers"
                      tableHeaderRowClass="heading"
                    />
                    {moreThanFifty && (
                        <div className="my-3">
                        <p> {messages["excelUpload_moreThanFiftyUsers"]} </p>
                      </div>
                    )}
                    {
                      allRegistersErrors ? (
                        <div className="my-3">
                          <p>{messages["excelUpload_fileEveryRegisterInvalid"]}</p>
                        </div>
                      ) :
                      containErrors && (
                        <div className="my-3">
                          <p> {messages["excelUpload_fileContainErrors"]}</p>
                        </div>
                      )
                    }
                      <div className="d-flex">
                      <Button
                        type="primary"
                        className="btn btn-primary mt-3 mx-auto"
                        disabled={allRegistersErrors} 
                        onClick={() => {dispatch(excelUploadSave(dataArray))}}
                      >
                        {messages["excelUpload_save"]}
                      </Button>
                    </div>
                  </Card>
                )}
                </>)
                }
                
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExcelUpload;
