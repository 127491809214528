import {
  //TYPES
  ADD_FILE_VEHICLE_OPEN,
  ADD_FILE_VEHICLE_CLOSE,
  ADD_FILE_VEHICLE_SAVE,
  ADD_FILE_VEHICLE_SAVE_RESULT,
  CLEAR_REDUX,
} from "config/redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  fileData: null,
  isVehiclePhoto: false,
  vehicle: null,
  result: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FILE_VEHICLE_OPEN:
      return {
        ...state,
        isOpen: true,
        fileData: action.payload.fileData,
        vehicle: action.payload.vehicle,
        isVehiclePhoto: action.payload.isVehiclePhoto,
      };

    case ADD_FILE_VEHICLE_CLOSE:
      return { ...state, ...INIT_STATE };

    case ADD_FILE_VEHICLE_SAVE:
      return { ...state, loading: true, result: null };

    case ADD_FILE_VEHICLE_SAVE_RESULT:
      return { ...state, loading: false, result: action.payload };

    case CLEAR_REDUX:
      return ["", "ADD_FILE_VEHICLE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : state;

    default:
      return state;
  }
};
