import {
    //TYPES
    EDIT_SEGUIMIENTO_OPEN,
    EDIT_SEGUIMIENTO_OPEN_RESULT,
    EDIT_SEGUIMIENTO_CLOSE,
    EDIT_SEGUIMIENTO_SAVE,
    EDIT_SEGUIMIENTO_SAVE_RESULT
} from 'config/redux/actionTypes';

export const editSeguimientoOpen = (id) => ({
    type: EDIT_SEGUIMIENTO_OPEN,
    payload: { id }
});

export const editSeguimientoOpenResult = (data) => ({
    type: EDIT_SEGUIMIENTO_OPEN_RESULT,
    payload: data
});

export const editSeguimientoClose = () => ({
    type: EDIT_SEGUIMIENTO_CLOSE,
    //payload: data
});

export const editSeguimientoSave = (data) => ({
    type: EDIT_SEGUIMIENTO_SAVE,
    payload: {data}
});

export const editSeguimientoSaveResult = (result) => ({
    type: EDIT_SEGUIMIENTO_SAVE_RESULT,
    payload: result
});