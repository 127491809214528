import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OpenFile } from 'config/helpers/Utils'

import Icons from '../Icons'
import { Button } from 'antd'

const InputFile = ({ value, onChange, type = 'single', accept, customRender }) => {
    const inputRef = useRef()
    const getItem = useCallback((file, index = 0) => {
        return (
            <div key={index} className="d-flex">
                <div className="my-auto p-0 position-relative image-container">
                    <div className="preview-container">
                        <img data-dz-thumbnail="" className="img-thumbnail border-0" />
                        <Icons className="preview-icon" family="ai" name="AiOutlineFile" />
                    </div>
                </div>
                <div className="ps-3 pt-2 pe-2 pb-1 dz-details position-relative">
                    <a onClick={() => OpenFile(file)}>{file.name}</a>
                </div>
                <a className="remove my-auto"
                    onClick={() => {
                        // let files = value.files.filter(x => x != file)
                        // onChange(files.length > 0 && { files: files } || null)
                        if (type == 'single') {
                            onChange(null)
                        }
                    }}
                >
                    <Icons className="preview-icon" family="ai" name="AiOutlineDelete" />
                </a>
            </div>
        )
    })
    return (
        <Fragment>
            <>
                <div>
                    {
                        type == 'single'
                            ? value?.file && (customRender && customRender(value.file) || getItem(value.file))
                            : value?.files && value.files.map((file, index) => {
                                return (
                                    customRender && customRender(file) || getItem(file, index)
                                )
                            })
                    }
                </div>
                <div className="row">
                    <div>
                        <Button className="m-auto" icon={<Icons className="anticon" family="md" name="MdOutlineFileUpload" />} onClick={(e) => {
                            if (inputRef.current)
                                inputRef.current.click()
                        }}>
                            Cargar archivo
                        </Button>
                    </div>
                    <input ref={inputRef} accept={accept || "*"} className="file-upload d-none" type="file" onChange={(e) => {
                        if (type == 'single') {
                            var reader = new FileReader();
                            reader.onload = function () {
                                // onChange({ file: { file: e.target.files[0], url: reader.result } })
                                onChange({ file: e.target.files[0] })
                            };

                            reader.readAsDataURL(e.target.files[0]);
                        }
                        else {
                            let files = value?.files || []

                            files.push(Array.from(e.target.files))
                            onChange({ file: files })
                        }

                    }} />
                </div>
            </>
        </Fragment>
    )
}

export default InputFile;