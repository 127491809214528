import {
    //TYPES
    EXCEL_UPLOAD_OPEN,
    EXCEL_UPLOAD_CLOSE,
    EXCEL_UPLOAD_SAVE,
    EXCEL_UPLOAD_SAVE_RESULT,
    EXCEL_UPLOAD_GET_USERS_SAVE,
    EXCEL_UPLOAD_GET_USERS_SAVE_RESULT
} from 'config/redux/actionTypes';

export const excelUploadOpen = () => ({
    type: EXCEL_UPLOAD_OPEN,
    // payload: data
});

export const excelUploadClose = () => ({
    type: EXCEL_UPLOAD_CLOSE,
    //payload: data
});

export const excelUploadSave = (data) => ({
    type: EXCEL_UPLOAD_SAVE,
    payload: data
});  

export const excelUploadSaveResult = (result, progress) => ({
    type: EXCEL_UPLOAD_SAVE_RESULT,
    payload: {result, progress}
});

export const getUsers = () => ({
  type: EXCEL_UPLOAD_GET_USERS_SAVE
});  

export const getUsersSaveResult = (users) => ({
  type: EXCEL_UPLOAD_GET_USERS_SAVE_RESULT,
  payload: users
});  