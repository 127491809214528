import React from 'react'
import * as IconsFa from "react-icons/fa";
import * as IconsAI from "react-icons/ai";
import * as IconsMD from "react-icons/md";
import * as IconsHI from "react-icons/hi";

const Icons = ({ name, family, className }) => {
    let IconComponent
    switch (family) {
        case 'fa':
            IconComponent = IconsFa[name];
            break;
        case 'ai':
            IconComponent = IconsAI[name];
            break;
        case 'md':
            IconComponent = IconsMD[name];
            break;

        case 'hi':
            IconComponent = IconsHI[name];
            break;

        default:
            IconComponent = () => <div>No Icon</div>
            break;
    }

    if (!IconComponent) {
        IconComponent = () => <div>No Icon</div>
    }

    return (
        <IconComponent className={className} />
    )
}

export default Icons;