import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader,
  DatePicker,
} from "antd";
import { InputFile } from "components";
import Gallery from "components/Gallery";

import {
  getCatalog,
  editDetalleSeguimientoClose,
  editDetalleSeguimientoSave,
  clearRedux,
} from "config/redux/actions";
import GoogleMaps from "components/GoogleMaps";

import "./styles.css";

import { useCitiesCascader } from "../../../../../../config/helpers/Hooks";

import moment from "moment";

//otros componentes
const { RangePicker } = DatePicker;

const EditDetalleSeguimientoComp = (props) => {
  const { onClose, idSeguimiento } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();
  const mapRef = useRef();

  const [id, setId] = useState();

  const { isOpen, data, loading, result, justMedia } = useSelector(
    (state) => state.editDetalleSeguimientoRedux
  );
  const { loading: isLoadingTracks, data: tracks } = useSelector(
    (state) => state.dashboardRedux
  );
  const isEdit = data ? true : false;

  //carga inicial de un valor de edicion
  useEffect(() => {
    if (data && !id) {
      //Se guarda el id
      setId(data.IdDetSeguimiento);
      //Se hace set al formulario
      console.log("files: ", data);
      form.setFields([
        { name: "PuntoReporte", value: data?.PuntoReporte },
        { name: "SN", value: data?.SN || "" },
        { name: "Observacion", value: data?.Observacion },
      ]);
    }
  }, [data]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result.status) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  useEffect(() => {
    if(data?.Latitude && data?.Longitude && !loading){
      addMarker(data)
    }
  }, [data, loading])

  const addMarker = (values) => {
    if(!values){
      return;
    }
    setTimeout(() => {
      mapRef.current?.addMarker(
        values?.IdDetSeguimiento,
        { lng: values?.Longitude, lat: values?.Latitude },
        "Reporte",
        `<div> 
            <p><b>${messages["seguimientoDetail_tabledetails_creationDate"]}:</b> ${moment(values?.FechaHoraReporte).format("lll")}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_withoutNovedad"]}:</b> ${values?.SN ? "Sin Novedad" : "Con Novedad"}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_reportPoint"]}:</b> ${values?.PuntoReporte}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_observation"]}:</b> ${values?.Observacion}</p>  
        </div>`
      );
      mapRef?.current?.centerMarker(data?.IdDetSeguimiento);
    }, 500);
  }
  

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(editDetalleSeguimientoClose());
    form.resetFields();
    setId();
    if (refresh) onClose(refresh);
  });

  return (
    <Modal
      title={
        justMedia
          ? messages["seguimientoDetail_resources"]
          : isEdit
          ? messages["editDetalleSeguimiento_titleUpdate"]
          : messages["editDetalleSeguimiento_title"]
      }
      open={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              {justMedia ? (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      {data?.Files?.length && (
                        <div className="col-xl-8 col-md-8">
                          <Gallery images={data?.Files?.map((v) => v.url)} />
                        </div>
                      )}
                    </div>
                    {data?.Latitude && data?.Longitude && <div style={{height: 300}}>
                      <GoogleMaps ref={mapRef} />
                    </div>}
                  </div>
                </div>
              ) : (
                <Spin tip="Loading..." spinning={loading}>
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                      dispatch(
                        editDetalleSeguimientoSave({
                          ...values,
                          IdSeguimiento: idSeguimiento
                            ? parseInt(idSeguimiento)
                            : parseInt(values.idSeguimiento),
                          IdDetSeguimiento: data?.IdDetSeguimiento,
                        })
                      );
                    }}
                  >
                    <div className="row">
                      {!idSeguimiento && (
                        <div className="col-12">
                          <Form.Item
                            name="idSeguimiento"
                            label={messages["editDetalleSeguimiento_track"]}
                            rules={[
                              {
                                required: true,
                                message: messages["required_field"],
                              },
                            ]}
                          >
                            <Select
                              loading={isLoadingTracks}
                              options={
                                tracks?.items?.length &&
                                tracks.items?.map((v) => ({
                                  value: v.IdSeguimiento,
                                  label: v.Orden,
                                }))
                              }
                            />
                          </Form.Item>
                        </div>
                      )}
                      <div className="col-12 col-md-6">
                        <Form.Item
                          name="PuntoReporte"
                          label={messages["editDetalleSeguimiento_reportPoint"]}
                          rules={[
                            {
                              required: true,
                              message: messages["required_field"],
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-md-6">
                        <Form.Item
                          name="SN"
                          label={
                            messages["editDetalleSeguimiento_withoutNovedad"]
                          }
                          rules={[
                            {
                              required: true,
                              message: messages["required_field"],
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value={1}>Sin Novedad</Select.Option>
                            <Select.Option value={0}>Con Novedad</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Item
                          name="Observacion"
                          label={messages["editDetalleSeguimiento_observation"]}
                        >
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        loading={loading}
                        type="primary"
                        className="btn btn-alternate btn-end"
                        onClick={() => close()}
                      >
                        {messages["cancel"]}
                      </Button>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary btn-end"
                      >
                        {messages["save"]}
                      </Button>
                    </div>
                  </Form>
                </Spin>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditDetalleSeguimientoComp;
