//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    VEHICLE_FILES_LIST,
    DELETE_VEHICLE_FILE,
    VEHICLE_PHOTOS
} from 'config/redux/actionTypes';

//actions
import {
    vehicleFilesListSearchResult,
    deleteVehicleFileResult,
    getVehiclePhotosResult
} from 'config/redux/actions';


function* vehicleFilesListSearchRequest() {
    yield takeEvery(VEHICLE_FILES_LIST, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, idCarro } = payload;
            let data = yield call(
                clientQuery,
                `query searchVehicleFiles(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $idCarro: Int!){
                    searchVehicleFiles: searchVehicleFiles(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        idCarro: $idCarro
                    ){
                        count
                        items{
                            id
                            idCarro
                            type
                            name
                            description
                            url
                            createdOn
                        }
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    idCarro
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(vehicleFilesListSearchResult(data?.searchVehicleFiles || null))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/vehicleDetail/components/files/redux/saga', 'vehicleFilesListSearchResult');
            yield put(vehicleFilesListSearchResult(null));
        }
    });
}

function* deleteVehicleFileRequest() {
    yield takeEvery(DELETE_VEHICLE_FILE, function* ({ payload }) {
        try {
            const { idCarro, idFile } = payload;
            const result = yield call(
                clientMutation,
                `mutation deleteVehicleFile(
                    $idCarro: Int!
                    $idFile: Int!
                ){
                    result: deleteVehicleFile(
                        idCarro: $idCarro
                        idFile: $idFile
                    )
                }`,
                {
                    idCarro,
                    idFile
                },
                endpoints.GRAPHQL_GENERAL
              );
            yield put(deleteVehicleFileResult({isSuccess: true}));
        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/vehicleDetail/components/files/redux/saga', 'deleteVehicleFileRequest');
            yield put(deleteVehicleFileResult({isError: true, error: exc.error }));
        }
    });
}

function* vehiclePhotosRequest() {
    yield takeEvery(VEHICLE_PHOTOS, function* ({ payload }) {
        try {
            const { idCarro } = payload;
            let data = yield call(
                clientQuery,
                `query getVehiclePhotosByIdCarro(
                    $idCarro: Int!){
                    vehiclePhotos: getVehiclePhotosByIdCarro(
                        idCarro: $idCarro
                    ){
                        id
                        idCarro
                        type
                        name
                        description
                        url
                        createdOn
                    }
                }`,
                {
                    idCarro: parseInt(idCarro)
                },
                endpoints.GRAPHQL_GENERAL
            );
            console.log(data?.vehiclePhotos)
            yield put(getVehiclePhotosResult(data?.vehiclePhotos || null))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/vehicleDetail/components/files/redux/saga', 'vehiclePhotosRequest');
            yield put(getVehiclePhotosResult(null));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(vehicleFilesListSearchRequest),
        fork(deleteVehicleFileRequest),
        fork(vehiclePhotosRequest)
    ]);
}
