import moment from "moment";

const regExpEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
const regExpName = /[0-9!#$%&'"*+/=?^_`{|}~-]/;
const regExpSpecialChars = /[!#$%&'"*+/=¿?^_`{|}~-]/;

const regExpNIT = /^[0-9]{9}-[0-9]{1}$/;
const regExpDocument = /^[0-9]{7,10}$/;
const regExpPhone = /^[0-9]{10,13}$/;
const regExpEpsArlNum = /^[0-9]{9,13}$/;

const regExpSalary = /^[0-9]{1,8}([.][0-9]{0,5})?$/;

const regExpAlphaNum = /^[a-zA-Z0-9_]*$;///[!#$%&'"*+/=?\[\]^_`\{|\}~-\\]/;
const regExpBool = /Sí|No/
const regExpDate = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

const replaceAcents = (text) => {

  const replaceArray = [
    {regexp: /á/g, valueReplace: "a"},
    {regexp: /é/g, valueReplace: "e"},
    {regexp: /í/g, valueReplace: "i"},
    {regexp: /ó/g, valueReplace: "o"},
    {regexp: /ú/g, valueReplace: "u"},
  ]
  
  const textToReplace = text.toString().trim()
        .replace(replaceArray[0].regexp, replaceArray[0].valueReplace)
        .replace(replaceArray[1].regexp, replaceArray[1].valueReplace)
        .replace(replaceArray[2].regexp, replaceArray[2].valueReplace)
        .replace(replaceArray[3].regexp, replaceArray[3].valueReplace)
        .replace(replaceArray[4].regexp, replaceArray[4].valueReplace)
  return textToReplace;
}

export const isRepeated = (rows, column) => {
  let isOk = false;
  let rowsWithError = [];
  rows.map((row, index) => {
      if (row[column] && (row[column] !== '')) {
          const val = row[column].toString().trim().toLowerCase();
          isOk = rows.some((elem) => {
              if (elem[column])
                  return (elem[column].toString().trim().toLowerCase() === val) && (elem !== row)
          });
      }
      if (isOk) {
        rowsWithError.push(index)
      }
  })
  return {isOk, rowsWithError};
}

export const isRepeatedRow = (rows, row, column) => {
  let isOk = false;

  if (row[column] && (row[column] !== '')) {
    const val = row[column].toString().trim().toLowerCase();
    isOk = rows.some((elem) => {
      if (elem[column])
        return (elem[column].toString().trim().toLowerCase() === val) && (elem !== row)
    });
  }
  return isOk;
}

export const isRowWithEmptyData = (row, columns) => {
  return columns.some((val) => row[val] === '' || row[val] === undefined)
}

export const doesDataExists = (rowObj, users, roles, companies) => {
  let errorMsg = "";
  let userExists = false;
  let rolExists = false;
  let rolId = [];
  let companyExists = false;
  let companyId = null;

  const rolNamesRow = rowObj.roles.map(rol => replaceAcents(rol).toLowerCase())

  users.forEach(user => {
    if ((user.username.toLowerCase() === rowObj.username.toLowerCase()) ||
          (user.email === rowObj.email)
        ) {
      userExists = true;
      errorMsg = "excelUpload_userAlreadyExists"
    }
  });

  roles.forEach(rol => {
    if(rol.id !== 1) {
      let rolName = replaceAcents(rol.name.toLowerCase())

      rolNamesRow.forEach(rolNameRow => {
        if (rolName === replaceAcents(rolNameRow.toLowerCase())) {
          rolExists = true;
          rolId.push(rol.id)
        }
      })
    }
  })

  if(companies !== "") {
    companies.forEach(company => {
      if(company.name.toLowerCase() === rowObj.companyId.toLowerCase()) {
        companyExists = true;
        companyId = company.id;
      }
    })
  } else companyExists = true;

  !(rolExists) && errorMsg === "" ? errorMsg = "excelUpload_rolDoesntExists" : "";
  !(companyExists) && errorMsg === "" ? errorMsg = "excelUpload_companyDoesntExists" : "";

  return {
    status: !userExists && rolExists && companyExists, 
    rolId,
    companyId: companyId,
    message: (errorMsg !== "") ? errorMsg : "excelUpload_validDataRow" 
  }
}

export const doesContractorDataExists = (rowObj, users, companies, cities) => {
  let errorMsg = "";
  let userExists = false;
  let companyExists = false;
  let cityExists = false;
  let cityId = null

  const companyNameRow = replaceAcents(rowObj.name).toLowerCase();
  const cityNameRow = replaceAcents(rowObj.cityId).toLowerCase();

  users.forEach(user => {
    if (user.email === rowObj.email) {
      userExists = true;
      errorMsg = "excelUpload_userAlreadyExists"
    }
  });

  companies.forEach(company => {
    let companyName = replaceAcents(company.name).toLowerCase()
    if (companyName === companyNameRow || company.documentNumber === rowObj.documentNumber) { 
      companyExists = true;
    }
  })
  companyExists && errorMsg === "" ? errorMsg = "excelUpload_companyAlreadyExists" : ""

  cities.forEach(city => {
    let cityName = replaceAcents(city.name).toLowerCase()
    if (cityName === cityNameRow) { 
      cityExists = true;
      cityId = city.id;
    }
  })
  !(cityExists) && errorMsg === "" ? errorMsg = "excelUpload_cityDoesntExists" : ""

  return {status: !userExists && !companyExists && cityExists, cityId, 
    message: (errorMsg !== "") ? errorMsg : "excelUpload_validDataRow" 
   }

}

export const compareArrays = (arr1, arr2) => {
    if (arr1.length != arr2.length) return false;

  for (var i = 0; i < arr2.length; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arr1[i].equals(arr2[i])) return false;
    } else if (arr1[i] != arr2[i]) {
      return false;
    }
  }
  return true;
}

export const isValidData = (row) => {
  let resp = "";
  const isValidUsername = row.username.length <= 50;
  const isValidEmail = regExpEmail.test(row.email.toLowerCase()) && (row.email.length <= 50);
  const isValidName = !(regExpName.test(row.firstName)) && (row.firstName.length <= 40);
  const isValidLastName = !(regExpName.test(row.lastName))  && (row.lastName.length <= 40);

  if(!isValidUsername) {
    resp = "excelUpload_invalidUsername";
  } else if(!isValidEmail) {
    resp = "excelUpload_invalidEmail";
  } else if (!isValidName) {
    resp = "excelUpload_invalidName";
  } else if (!isValidLastName) {
    resp = "excelUpload_invalidLastname";
  }
  //return isValidEmail && isValidName && isValidLastName;
  return {
    status: isValidUsername && isValidEmail && isValidName && isValidLastName,
    message: (resp !== "") ? resp : "excelUpload_validDataRow"
  }
}

export const isValidContractorData = (row) => {
  let resp = "";
  const isValidName = !(regExpName.test(row.name)) && (row.name.length <= 40);
  const isValidNIT = regExpNIT.test(row.documentNumber);
  const isValidEmail = regExpEmail.test(row.email.toLowerCase()) && (row.email.length <= 50);
  const isValidPhone = regExpPhone.test(row.phoneNumber);
  const isValidAddress = (row.address.length <= 20);

  if(!isValidName) {
    resp = "excelUpload_invalidCompanyName";
  } else if(!isValidEmail) {
    resp = "excelUpload_invalidEmail";
  } else if (!isValidNIT) {
    resp = "excelUpload_invalidNITNumber";
  } else if (!isValidPhone) {
    resp = "excelUpload_invalidPhoneNumber";
  } else if(!isValidAddress) {
    resp = "excelUpload_invalidAddress";
  }
  return {
    status: isValidName && isValidNIT && isValidEmail && isValidPhone && isValidAddress,
    message: (resp !== "") ? resp : "excelUploadContractors_validDataRow"
  }
}
// ----- CONTRACTS
export const isValidContractData = (row) => {
  let resp = "";
  
  const isValidContractNumber = !regExpSpecialChars.test(row.contractNumber) && (row.contractNumber.length <= 12);
  const isValidDescription = (row.description.length >= 10 && row.description.length <= 500);

  const isValidStartDate = regExpDate.test(row.startDate);
  const isValidEndDate = regExpDate.test(row.endDate);

  const isStartDateBeforeEndDate = moment(row.startDate).isBefore(row.endDate);

  if(!isValidContractNumber) {
    resp = "contractsUpload_invalidNumber";
  } else if(!isValidDescription) {
    resp = "contractsUpload_invalidDescription";
  } else if(!isValidStartDate) {
    resp = "contractsUpload_invalidStartDate";
  } else if(!isValidEndDate) {
    resp = "contractsUpload_invalidEndDate";
  } else if(!isStartDateBeforeEndDate) {
    resp = "contractsUpload_invalidDateDifference";
  }

  return {
    status: isValidContractNumber && isValidDescription && isValidStartDate && isValidEndDate && isStartDateBeforeEndDate,
    message: (resp !== "") ? resp : "excelUpload_validDataRow"}
}

export const doesContractDataExists = (rowObj, contracts, places) => {
  let errorMsg = "";
  let contractExists = false;
  let placeExists = false;
  let placeId = 0

  contracts?.forEach(contract => {
    if (contract.contractNumber === rowObj.contractNumber) {
      contractExists = true;
      errorMsg = "contractUpload_contractAlreadyExists";
    }
  });

  places.forEach(place => {
    if(place.name === rowObj.place) {
      placeExists = true;
      placeId = place.id;
    }
  })

  if(!placeExists) {
    errorMsg = "contractUpload_placeDoesntExists";
  }

  return {status: !contractExists && placeExists,
          placeId,
          message: (errorMsg !== "") ? errorMsg : "contractsUpload_validDataRow" 
         }
}

// ----- PERSONAL
export const isValidPersonalData = (row) => {
  let resp = "";

  const isValidContractorType = (row.contractorType === "Contratista") || (row.contractorType === "Subcontratista") || (row.contractorType === "Proveedor");
  const isValidcontractorNit = ((row.contractorType === "Subcontratista") || (row.contractorType === "Proveedor") ? (regExpNIT.test(row.contractorNIT)) : true);
  const isValidName = !(regExpName.test(row.firstName)) && !(regExpName.test(row.lastName));
  const isValidDocumentType = (row.documentType === "Cédula de Ciudadanía") || (row.documentType === "Cédula de Extranjería") || (row.documentType === "Pasaporte");
  const isValidDocument = regExpDocument.test(row.document);
  /* El departamento de nacimiento no se pone pues se obtiene con la ciudad */
  const isValidBirthCity = !(regExpName.test(row.birthCity));
  const isValidBirthDate = (regExpDate.test(row.birthDate) ?  (moment().diff(moment(row.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'year') >= 18) : false);
  const isValidGender = (row.gender === "Masculino") || (row.gender === "Femenino");

  const isValidEmail = regExpEmail.test(row.email);
  const isValidPhone = regExpPhone.test(row.phone);
  const isValidArea = !(regExpSpecialChars.test(row.area)) && (row.area.length <= 50);
  const isValidAddress = (row.address.length <= 20);
  const isValidEps = !(regExpName.test(row.eps)) && (row.eps.length <= 50);
  const isValidEpsNumber = regExpEpsArlNum.test(row.epsNumber);
  const isValidEpsExpirationDate = regExpDate.test(row.epsExpirationDate);
  const isValidArl = !(regExpName.test(row.arl)) && (row.arl.length <= 50);
  const isValidArlNumber = regExpEpsArlNum.test(row.arlNumber);
  const isValidArlExpirationDate = regExpDate.test(row.arlExpirationDate);
  const isValidRh = (row.rh === "O+") || (row.rh === "O-") || (row.rh === "A+") || (row.rh === "A-") || (row.rh === "B+") || (row.rh === "B-") || (row.rh === "AB+") | (row.rh === "AB-");

  const isValidHeadOfFamily = regExpBool.test(row.headOfFamily);
  const isValidDisability = (row.disability === "No") || (row.disability === "Discapacidad física") || (row.disability === "Discapacidad auditiva") || (row.disability === "Discapacidad visual") || (row.disability === "Sordoceguera") || (row.disability === "Discapacidad intelectual") || (row.disability === "Discapacidad psicosocial (mental)" || (row.disability === "Discapacidad múltiple"));
  const isValidSchooling = (row.schooling === "Emprírico") || (row.schooling === "Primario") || (row.schooling === "Secundario") || (row.schooling === "Técnico Tecnólogo") || (row.schooling === "Universitario") || (row.schooling === "Superior");
  const isValidFirstJob = regExpBool.test(row.firstJob);
  const isValidBCE = regExpBool.test(row.BCE);
  const isValidVLI = regExpBool.test(row.VLI);
  const isValidEthnicGroup = (row.ethnicGroup === "No") || (row.ethnicGroup === "Afrodescendiente") || (row.ethnicGroup === "Indígena") || (row.ethnicGroup === "Otros grupos étnicos");
  const isValidCAC = regExpBool.test(row.CAC);
  /* El departamento de residencia no se pone pues se obtiene con la ciudad */
  const isValidCity = !(regExpName.test(row.city));
  const isValidSCI = regExpBool.test(row.SCI);
  /* El departamento de ejecución de actividades no se pone pues se obtiene con la ciudad */
  const isValidSCICity = !(regExpName.test(row.SCICity));
  const isValidPosition = !(regExpName.test(row.position));
  const isValidContractStart = regExpDate.test(row.contractStart);
  const isValidContractEnd = regExpDate.test(row.contractEnd);
  const isValidLocalLabor = regExpBool.test(row.localLabor);
  const isValidLaborQualification = (row.laborQualification === "Operativa") || (row.laborQualification === "No operativa");
  const isValidLaborIsTechnical = regExpBool.test(row.laborIsTechnical);
  const isValidDailySalary = regExpSalary.test(row.dailySalary);
  const isValidObjectContract = row.objectContract.length <= 500;

  if(!isValidContractorType) {
    resp = "personalUpload_invalidContractorType";
  } else if(!isValidcontractorNit) {
    resp = "personalUpload_invalidContractorNIT";
  } else if(!isValidName) {
    resp = "personalUpload_invalidName";
  } else if(!isValidDocumentType) {
    resp = "personalUpload_invalidDocumentType";
  } else if(!isValidDocument) {
    resp = "personalUpload_invalidDocument";
  } else if(!isValidBirthDate) {
    resp = "personalUpload_invalidBirthDate";
  } else if(!isValidBirthCity) {
    resp = "personalUpload_invalidBirthCity";
  } else if(!isValidGender) {
    resp = "personalUpload_invalidGender";
  } else if(!isValidEmail) {
    resp = "personalUpload_invalidEmail";
  } else if(!isValidPhone) {
    resp = "personalUpload_invalidPhone";
  } else if(!isValidArea) {
    resp = "personalUpload_invalidArea";
  } else if(!isValidAddress) {
    resp = "personalUpload_invalidAddress";
  } else if(!isValidEps) {
    resp = "personalUpload_invalidEpsName";
  } else if(!isValidEpsNumber) {
    resp = "personalUpload_invalidEpsNumber";
  } else if(!isValidEpsExpirationDate) {
    resp = "personalUpload_invalidEpsExpirationDate";
  } else if(!isValidArl) {
    resp = "personalUpload_invalidArlName";
  }else if(!isValidArlNumber) {
    resp = "personalUpload_invalidArlNumber";
  } else if(!isValidArlExpirationDate) {
    resp = "personalUpload_invalidArlExpirationDate";
  } else if(!isValidRh) {
    resp = "personalUpload_invalidRh";
  } else if(!isValidHeadOfFamily) {
    resp = "personalUpload_invalidHeadOfFamily";
  } else if(!isValidDisability) {
    resp = "personalUpload_invalidDisability";
  } else if(!isValidSchooling) {
    resp = "personalUpload_invalidSchooling";
  } else if(!isValidFirstJob) {
    resp = "personalUpload_invalidFirstJob";
  } else if(!isValidBCE) {
    resp = "personalUpload_invalidBCE";
  } else if(!isValidVLI) {
    resp = "personalUpload_invalidVLI";
  } else if(!isValidEthnicGroup) {
    resp = "personalUpload_invalidEthnicGroup";
  } else if(!isValidCAC) {
    resp = "personalUpload_invalidCAC";
  } else if(!isValidCity) {
    resp = "personalUpload_invalidCity";
  } else if(!isValidSCI) {
    resp = "personalUpload_invalidSCI";
  } else if(!isValidSCICity) {
    resp = "personalUpload_invalidSCICity";
  } else if(!isValidPosition) {
    resp = "personalUpload_invalidPosition";
  } else if(!isValidContractStart) {
    resp = "personalUpload_invalidContractStart";
  } else if(!isValidContractEnd) {
    resp = "personalUpload_invalidContractEnd";
  } else if(!isValidLocalLabor) {
    resp = "personalUpload_invalidLocalLabor";
  } else if(!isValidLaborQualification) {
    resp = "personalUpload_invalidLaborQualification";
  } else if (!isValidLaborIsTechnical) {
    resp = "personalUpload_invalidLaborIsTechnical";
  } else if (!isValidDailySalary) {
    resp = "personalUpload_invalidDailySalary";
  } else if (!isValidObjectContract) {
    resp = "personalUpload_invalidObjectContract";
  }

  return {
    status: isValidContractorType && isValidcontractorNit && isValidName && isValidDocumentType && isValidDocument && isValidBirthCity && isValidBirthDate && isValidGender && isValidEmail && isValidPhone && isValidArea && isValidAddress && isValidEps && isValidEpsNumber && isValidEpsExpirationDate && isValidArl && isValidArlNumber && isValidArlExpirationDate && isValidRh && isValidHeadOfFamily && isValidDisability && isValidSchooling && isValidFirstJob && isValidBCE && isValidVLI && isValidEthnicGroup && isValidCAC && isValidCity && isValidPosition && isValidSCI && isValidSCICity && isValidContractStart && isValidContractEnd && isValidLocalLabor && isValidLaborQualification && isValidLaborIsTechnical && isValidDailySalary && isValidObjectContract,
    message: (resp !== "") ? resp : "excelUpload_validDataRow"}
}

export const doesPersonalDataExists = (rowObj, persons, places) => {
  let errorMsg = "";
  let personExists = false;
  let birthCityExists = false;
  let cityExists = false;
  let SCICityExists = false;
  let relatedIds = [0, 0, 0]

  persons?.forEach(person => {
    if (person.email === rowObj.email) {
      personExists = true;
      errorMsg = "personalUpload_emailAlreadyExists";
    }
    if ((person.document === rowObj.document) && (person.documentType.name === rowObj.documentType)) {
      personExists = true;
      errorMsg = "personalUpload_personAlreadyExists";
    }
  });

  places.forEach(place => {
    if(place.name === rowObj.birthCity) {
      birthCityExists = true;
      relatedIds[0] = place.id;
    }
    if(place.name === rowObj.city) {
      cityExists = true;
      relatedIds[1] = place.id;
    }
    if(place.name === rowObj.SCICity) {
      SCICityExists = true;
      relatedIds[2] = place.id;
    }
  })

  if(!birthCityExists) {
    errorMsg = 'personalUpload_birthCityDoesntExists';
  } else if(!cityExists) {
    errorMsg = 'personalUpload_cityDoesntExists';
  } else if(!SCICityExists) {
    errorMsg = 'personalUpload_SCICityDoesntExists';
  }
  return {status: !personExists && birthCityExists && cityExists && SCICityExists,
          relatedIds,
          message: (errorMsg !== "") ? errorMsg : "personalUpload_validDataRow" 
         }
}