import {
    //TYPES
    EXCEL_UPLOAD_CONTRACTORS_OPEN,
    EXCEL_UPLOAD_CONTRACTORS_CLOSE,
    EXCEL_UPLOAD_CONTRACTORS_SAVE,
    EXCEL_UPLOAD_CONTRACTORS_SAVE_RESULT,
    EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE,
    EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    loadingPercent: null,
    contractors: [],
    loadingContractors: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case EXCEL_UPLOAD_CONTRACTORS_OPEN:
            return { ...state, isOpen: true }

        case EXCEL_UPLOAD_CONTRACTORS_CLOSE:
            return { ...state, ...INIT_STATE }

        case EXCEL_UPLOAD_CONTRACTORS_SAVE:
            return { ...state, loading: true, result: null }

        case EXCEL_UPLOAD_CONTRACTORS_SAVE_RESULT:
            return { ...state, loading: false, loadingPercent: action.payload.progress, result: action.payload.result }

        case EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE:
          return {...state, loadingContractors: true}  
    
        case EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE_RESULT:
          return {...state, contractors: action.payload, loadingContractors: false}
        

        case CLEAR_REDUX:
            return ["", "EXCEL_UPLOAD_CONTRACTORS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
