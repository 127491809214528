import {
    //TYPES
    USER_LIST_SEARCH,
    USER_LIST_SEARCH_RESULT
} from 'config/redux/actionTypes';

export const userListSearch = (openSearch, limit, offset, status) => ({
    type: USER_LIST_SEARCH,
    payload: { openSearch, limit, offset, status },
});

export const userListSearchResult = (data) => ({
    type: USER_LIST_SEARCH_RESULT,
    payload: data,
});
