//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_DETALLE_SEGUIMIENTO_OPEN,
    EDIT_DETALLE_SEGUIMIENTO_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editDetalleSeguimientoOpenResult,
    editDetalleSeguimientoSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editDetalleSeguimientoOpenRequest() {
    yield takeEvery(EDIT_DETALLE_SEGUIMIENTO_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;

            if (!id) {
                yield put(editDetalleSeguimientoOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getDetalleSeguimientoById($id: Int){
                    detalle: getDetalleSeguimientoById(id: $id){
                        IdDetSeguimiento
                        IdSeguimiento
                        FechaHoraReporte
                        Usuario
                        UsuarioCreacionInfo{
                            Nombre
                        }
                        PuntoReporte
                        SN
                        Observacion
                        FechaModificacion
                        UsuarioModificacion
                        Files {
                            id
                            name
                            description
                            url
                        }
                        Longitude
                        Latitude
                        UsuarioModificacionInfo{
                            Nombre
                        }
                    }
                }`,
                {
                    id: parseInt(id)
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editDetalleSeguimientoOpenResult(data.detalle))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editDetalleSeguimiento/redux/saga', 'editDetalleSeguimientoOpenRequest');
            yield put(editDetalleSeguimientoOpenResult());

        }
    });
}

function* editDetalleSeguimientoSaveRequest() {
    yield takeEvery(EDIT_DETALLE_SEGUIMIENTO_SAVE, function* ({ payload }) {
        try {
            const { data } = payload;
            let query
            console.log("payload:", payload)

            if (data.IdDetSeguimiento) {
                query = yield call(
                    clientMutation,
                    `mutation updateDetalleSeguimiento(
                        $IdDetSeguimiento: Int!
                        $IdSeguimiento: Int!
                        $PuntoReporte: String
                        $SN: Int
                        $Observacion: String
                      ){
                    detalle: updateDetalleSeguimiento(
                        IdDetSeguimiento: $IdDetSeguimiento
                        IdSeguimiento: $IdSeguimiento
                        PuntoReporte: $PuntoReporte
                        SN: $SN
                        Observacion: $Observacion
                        ){
                        IdDetSeguimiento
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editDetalleSeguimientoSaveResult({ status: true }));

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createDetalleSeguimiento(
                        $IdSeguimiento: Int!
                        $PuntoReporte: String
                        $SN: Int
                        $Observacion: String
                      ){
                    detalle: createDetalleSeguimiento(
                        IdSeguimiento: $IdSeguimiento
                        PuntoReporte: $PuntoReporte
                        SN: $SN
                        Observacion: $Observacion
                        ){ 
                        IdDetSeguimiento
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editDetalleSeguimientoSaveResult({ status: true, id: query.detalle.IdDetSeguimiento }));

            }


        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editDetalleSeguimiento/redux/saga', 'editDetalleSeguimientoSaveRequest');
            yield put(editDetalleSeguimientoSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editDetalleSeguimientoOpenRequest),
        fork(editDetalleSeguimientoSaveRequest)
    ]);
}
