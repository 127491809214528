//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    SEGUIMIENTO_LIST_SEARCH
} from 'config/redux/actionTypes';

//actions
import {
    seguimientoListSearchResult,
    clearRedux
} from 'config/redux/actions';


function* seguimientoListSearchRequest() {
    yield takeEvery(SEGUIMIENTO_LIST_SEARCH, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, status, otherFilters } = payload;
            let data = yield call(
                clientQuery,
                `query searchSeguimientos(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $status: Int, 
                    $filterBy: String){
                    seguimientos: searchSeguimientos(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        status: $status,
                        filterBy: $filterBy
                    ){
                        count
                        items{
                        IdSeguimiento
                        IdCliente
                        ClienteInfo{
                            RazonSocial
                            Nit
                        }
                        IdCarro
                        CarroInfo{
                            Placa
                            Marca
                            Modelo
                            Color
                        }
                        IdCiudadOrigen
                        CiudadOrigenInfo{
                            CityCode
                            CityName
                        }
                        IdCiudadDestino
                        CiudadDestinoInfo{
                            CityCode
                            CityName
                        }
                        Orden
                        Link
                        Manifiesto
                        Aseguradora
                        Ruta
                        Kilometros
                        TiempoEstimado
                        Restriccion
                        Observacion
                        FechaSalida
                        FechaLlegada
                        IdEstado
                        EstadoInfo{
                            IdEstado
                            NombreEstado
                        }
                        NombreCond
                        CelularCond
                        IdAcompanante
                        AcompananteInfo{
                            Nombre
                            Cedula
                            Celular
                            VehiculoInfo{
                                Placa
                                Marca
                                Clase
                                Modelo
                                Color
                            }
                        }
                        FechaCreacion
                        }
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    status,
                    filterBy: otherFilters? JSON.stringify(otherFilters) : undefined
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(seguimientoListSearchResult(data.seguimientos || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/seguimientosList/redux/saga', 'seguimientoListSearchRequest');
            yield put(seguimientoListSearchResult([]));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(seguimientoListSearchRequest)
    ]);
}
