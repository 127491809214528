import {
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO_RESULT,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLES,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLES_RESULT,
    DETALLESEGUIMIENTO_DETAIL_SET_PREVPAGE,
    DELETE_DETALLESEGUIMIENTO,
    DELETE_DETALLESEGUIMIENTO_RESULT
} from 'config/redux/actionTypes';

export const detalleSeguimientoDetailGetDetalleSeguimiento = (id) => ({
    type: DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO,
    payload: { id },
});

export const detalleSeguimientoDetailGetDetalleSeguimientoResult = (response) => ({
    type: DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO_RESULT,
    payload: { response },
});

export const detalleSeguimientoDetalleGetDetalles = (openSearch, limit, offset, id) => ({
    type: DETALLESEGUIMIENTO_DETAIL_GET_DETALLES,
    payload: { openSearch, limit, offset, id },
});

export const detalleSeguimientoDetalleGetDetallesResult = (response) => ({
    type: DETALLESEGUIMIENTO_DETAIL_GET_DETALLES_RESULT,
    payload: { response },
});

export const detalleSeguimientoDetalleSetPrevPage = (prevPage) => ({
    type: DETALLESEGUIMIENTO_DETAIL_SET_PREVPAGE,
    payload: { prevPage },
});

export const deleteDetalleSeguimiento = (idDetSeguimiento) => ({
    type: DELETE_DETALLESEGUIMIENTO,
    payload: { idDetSeguimiento }
});

export const deleteDetalleSeguimientoResult = (result) => ({
    type: DELETE_DETALLESEGUIMIENTO_RESULT,
    payload: result
});