import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";

import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import HeaderPage from "containers/headerPage";
import { Icons } from "components";
import {
  Card,
  Tabs,
  Button,
  Form,
  Input,
  Checkbox,
  Spin,
  notification,
  Tag,
  Dropdown,
  Menu,
  Tooltip,
  Breadcrumb,
  Modal,
  DatePicker,
  Image,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";

import EditVehicle from "../../components/editVehicle";
import {
  editVehicleOpen,
  vehicleDetailGetDetail,
  addFileVehicleOpen,
  getVehiclePhotos,
  clearRedux,
} from "config/redux/actions";
import VehicleHistoryListView from "./components/history";
import VehicleFiles from "./components/files";
import Gallery from "components/Gallery";
import AddFileVehicle from "./components/addFile";

const DetailView = ({ history }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { messages } = useIntl();

  const { loading, data, error } = useSelector(
    (state) => state.vehicleDetailRedux
  );
  const { user } = useSelector((state) => state.authRedux);

  useEffect(() => {
    if (id) {
      dispatch(vehicleDetailGetDetail(id));
      dispatch(getVehiclePhotos(id));
    } else {
      history.goBack();
    }
    return () => {
      dispatch(clearRedux("VEHICLE_DETAIL"));
    };
  }, []);

  const openAddPhoto = () => {
    dispatch(addFileVehicleOpen(null, data, true));
  };

  return (
    <div className="container">
      <HeaderPage title={messages["vehicleDetail_title"]}>
        <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlineEdit" />}
            onClick={() => dispatch(editVehicleOpen(data?.IdCarro))}
          >
            {messages["vehicleDetail_editVehicle"]}
          </Button>
        </div>
      </HeaderPage>
      <Breadcrumb className="mb-5">
        <Breadcrumb.Item>
          <Link to={"/app/admin/vehicleslist"}>
            <Icons family="md" name="MdTrendingUp" className="icon" />
            <span className="ms-1">{messages["admin_vehicleList_menu"]}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{messages["vehicleDetail_title"]}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <Tabs
          size="large"
          type="card"
          defaultActiveKey="1"
          items={[
            {
              label: messages["vehicleDetail_detailInfo"],
              key: "1",
              children: <GeneralInfo data={data} messages={messages} addImage={openAddPhoto}/>,
            },
            {
              label: messages["vehicleDetail_history"],
              key: "2",
              children: <VehicleHistoryListView placa={data?.Placa} />,
            },
            {
              label: messages["vehicleDetail_files"],
              key: "3",
              children: (
                <VehicleFiles vehicle={{ ...data, isVehiclePhoto: 0 }} />
              ),
            },
          ]}
        />
      </Spin>
      <EditVehicle
        onClose={(refresh) => refresh && dispatch(vehicleDetailGetDetail(id))}
      />
       <AddFileVehicle onClose={(refresh) => refresh && dispatch(getVehiclePhotos(id))} />
    </div>
  );
};

export default DetailView;

const GeneralInfo = ({ data, messages, addImage }) => {
  const { vehiclePhotos } = useSelector(
    (state) => state.vehicleFilesRedux
  );
  return (
    <>
      <Card className="mb-3" style={{ borderTopColor: "transparent" }}>
        <div className="container">
          <div className="row justify-content-end align-items-center">
            <Button
              className="btn btn-outline-primary btn-icon btn-icon-start mb-2 ms-2 w-100 w-md-auto"
              icon={<Icons family="ai" name="AiOutlineEdit" />}
              onClick={addImage}
            >
              {messages["vehicleDetail_addPhotos"]}
            </Button>
          </div>
          <div className="row justify-content-start align-items-center">
            <div className="col-xl-4 col-md-4">
              <div className="card h-100">
                <div className="card-body">
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_plate"]}:
                      </p>
                    </div>
                    <p className="m-0">{data?.Placa}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_brand"]}:
                      </p>
                    </div>
                    <p className="m-0">{data?.Marca}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_model"]}:
                      </p>
                    </div>
                    <p className="m-0">{data?.Modelo}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_color"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Color}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_client"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.ClienteInfo?.RazonSocial}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_capacity"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Capacidad}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_container"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Contenedor}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_serials"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Seriales}</p>
                  </div>
                  <div className="my-2 d-flex flex-column gap-1 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_accompanist"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">
                      {data?.isAccompanist ? (
                        <Tag color="green">Sí</Tag>
                      ) : (
                        <Tag color="red">No</Tag>
                      )}
                    </p>
                  </div>
                  <div className="my-2 d-flex flex-column gap-1 col-xl-12 col-lg-4 col-md-12 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <p className="m-0 text-cyan">
                        {messages["vehiclesList_status"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">
                      {data?.estado ? (
                        <Tag color="blue">Activo</Tag>
                      ) : (
                        <Tag color="red">Inactivo</Tag>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-8">
              <Gallery images={vehiclePhotos?.length ? vehiclePhotos?.map(v => v.url) : []} />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

{
  /* <Card className="mb-3 pt-3" style={{ borderTopColor: "transparent" }}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
          </div>
        </div>
      </Card> */
}
