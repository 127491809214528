import React from 'react';
import { IntlProvider } from 'react-intl'
import { Switch, Route, Redirect } from 'react-router-dom'

import locales from 'config/locales'
import LoginView from 'security/views/login'
import ErrorView from 'security/views/error'
import ModulesRouter from 'modules'

import packageJSON from '../../../package.json'

const MainRouter = ({ location }) => {

    if (location.pathname === '/') {
        return (<Redirect to={'/app'} />);
    }

    console.log(`Running app ${packageJSON.name} v.${packageJSON.version}`)

    return (
        <IntlProvider locale='es' messages={locales['es']}>
            <Switch>
                <Route path='/app' component={ModulesRouter} />
                <Route path='/error' component={ErrorView} />
                <Route path='/login' component={LoginView} />
                <Redirect to='/error' />
            </Switch>
        </IntlProvider>

    )
}

export default MainRouter