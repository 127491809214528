import {
  //TYPES
  LOGIN_LOGIN,
  LOGIN_LOGIN_RESULT,
  CLEAR_REDUX,
  //----------------------------------------
  LOGIN_RECOVER_PASSWORD_OPENCLOSE,
  LOGIN_RECOVER_PASSWORD,
  LOGIN_RECOVER_PASSWORD_RESULT,
  LOGIN_VERIFY_CODE,
  LOGIN_VERIFY_CODE_RESULT,
} from "config/redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  result: null,
  //----------------------------------------
  recoverPasswordModal: false,
  loadingRemindPassword: false,
  resultRemindPassword: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_LOGIN:
      return { ...state, loading: true };

    case LOGIN_LOGIN_RESULT:
      return { ...state, loading: false, result: action.payload };
    //----------------------------------------
    case LOGIN_RECOVER_PASSWORD_OPENCLOSE:
      return { ...state, recoverPasswordModal: true };

    case LOGIN_RECOVER_PASSWORD:
      return {
        ...state,
        loadingRemindPassword: true,
        resultRemindPassword: null
      };

    case LOGIN_RECOVER_PASSWORD_RESULT:
      return {
        ...state,
        loadingRemindPassword: false,
        resultRemindPassword: action.payload.response,
      };

    case CLEAR_REDUX:
      return ["", "LOGIN"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : state;

    default:
      return state;
  }
};
