import {
    COMMON_GET_CATALOG,
    COMMON_GET_CATALOG_RESULT,
    COMMON_CATALOG_LOADINGS,
    CLEAR_REDUX
} from 'config/redux/actionTypes';

const INIT_STATE = {
    loadingRoles: false,
    roles: [],

    loadingClients: false,
    clients: [],

    loadingStates: false,
    states: [],

    loadingStatuses: false,
    statuses: [],

    loadingCities: false,
    cities: [],

    loadingPlaces: false,
    places: [],

    loadingVehicles: false,
    vehicles: [],

    loadingAccompanistVehicles: false,
    accompanistVehicles: [],

    loadingAccompanist: false,
    accompanists: [],

    loadingDocumentTypes: false,
    documentTypes: [],

    loadingEpsList: false,
    epsList: [],

    loadingArls: false,
    arls: [],

    loadingOrderVisitsAvailable: false,
    orderVisitsAvailable: [],

    loadingArCertificatesAvailable: false,
    arCertificatesAvailable: [],

    loadingPersons: false,
    persons: [],

    loadingUsers: false,
    users: [],

    loadingContracts: false,
    contracts: [],

    loadingWorkPermissions: false,
    workPermissions: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case COMMON_GET_CATALOG:
            return state;

        case COMMON_GET_CATALOG_RESULT:
            return { ...state, ...action.payload };

        case COMMON_CATALOG_LOADINGS:
            return { ...state, ...action.payload };

        case CLEAR_REDUX:
            return ["", "COMMON"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
