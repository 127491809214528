//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    SEGUIMIENTO_FILES_LIST,
    DELETE_SEGUIMIENTO_FILE
} from 'config/redux/actionTypes';

//actions
import {
    seguimientoFilesListSearchResult,
    deleteSeguimientoFileResult
} from 'config/redux/actions';


function* seguimientoFilesListSearchRequest() {
    yield takeEvery(SEGUIMIENTO_FILES_LIST, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, idSeguimiento } = payload;
            let data = yield call(
                clientQuery,
                `query searchSeguimientoFiles(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $idSeguimiento: Int!){
                    seguimientoFiles: searchSeguimientoFiles(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        idSeguimiento: $idSeguimiento
                    ){
                        count
                        items{
                            id
                            idSeguimiento
                            type
                            name
                            description
                            url
                            createdOn
                        }
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    idSeguimiento
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(seguimientoFilesListSearchResult(data?.seguimientoFiles || null))

        } catch (exc) {
            ExceptionManager(exc, 'modules/track/components/views/seguimientoFilesListSearchRequest/redux/saga', 'seguimientoFilesListSearchRequest');
            yield put(seguimientoFilesListSearchResult(null));
        }
    });
}

function* deleteSeguimientoFileRequest() {
    yield takeEvery(DELETE_SEGUIMIENTO_FILE, function* ({ payload }) {
        try {
            const { idSeguimiento, idFile } = payload;
            const result = yield call(
                clientMutation,
                `mutation deleteSeguimientoFile(
                    $idSeguimiento: Int!
                    $idFile: Int!
                ){
                    result: deleteSeguimientoFile(
                        idSeguimiento: $idSeguimiento
                        idFile: $idFile
                    )
                }`,
                {
                    idSeguimiento,
                    idFile
                },
                endpoints.GRAPHQL_GENERAL
              );

            yield put(deleteSeguimientoFileResult({isSuccess: true}))

        } catch (exc) {
            ExceptionManager(exc, 'modules/track/components/views/seguimientosDetail/components/files/redux/saga', 'deleteSeguimientoFileRequest');
            yield put(deleteSeguimientoFileResult({isError: true, error: exc.error }));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(seguimientoFilesListSearchRequest),
        fork(deleteSeguimientoFileRequest),
    ]);
}
