import {
    //TYPES
    EDIT_VEHICLE_OPEN,
    EDIT_VEHICLE_OPEN_RESULT,
    EDIT_VEHICLE_CLOSE,
    EDIT_VEHICLE_SAVE,
    EDIT_VEHICLE_SAVE_RESULT
} from 'config/redux/actionTypes';

export const editVehicleOpen = (id) => ({
    type: EDIT_VEHICLE_OPEN,
    payload: { id }
});

export const editVehicleOpenResult = (data) => ({
    type: EDIT_VEHICLE_OPEN_RESULT,
    payload: data
});

export const editVehicleClose = () => ({
    type: EDIT_VEHICLE_CLOSE,
    //payload: data
});

export const editVehicleSave = (data) => ({
    type: EDIT_VEHICLE_SAVE,
    payload: {data}
});

export const editVehicleSaveResult = (result) => ({
    type: EDIT_VEHICLE_SAVE_RESULT,
    payload: result
});