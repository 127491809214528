import {
    GET_LASTEST_SEGUIMIENTOS,
    GET_LASTEST_SEGUIMIENTOS_RESULT
} from 'config/redux/actionTypes';


export const getLatestWorkPermissionNews = (openSearch, limit, offset, status) => ({
    type: GET_LASTEST_SEGUIMIENTOS,
    payload: {openSearch, limit, offset, status}
});

export const getLatestWorkPermissionNewsResult = (response) => ({
    type: GET_LASTEST_SEGUIMIENTOS_RESULT,
    payload: { response },
});

