import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader
} from "antd";
import { InputFile } from "components";

import { getCatalog, editVehicleClose, editVehicleSave, clearRedux } from "config/redux/actions";

import "./styles.css"
import { useCitiesCascader } from "../../../../config/helpers/Hooks";

//otros componentes

const EditVehicleComp = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [id, setId] = useState();
  const [clientId, setClientId] = useState(0);

  const { user } = useSelector(state => state.authRedux)
  const { isOpen, data, loading, result } = useSelector(
    (state) => state.editVehicleRedux
  );
  const {clients, loadingClients } = useSelector(
    (state) => state.commonRedux
  );

  const isEdit = data ? true : false;

  useEffect(() => {
    dispatch(getCatalog(["clients"])); 
  }, [])
  
  //carga inicial de un valor de edicion
  useEffect(() => {
    if(user.IdRol !== 1){
      form.setFieldValue("IdCliente", user.IdCliente)
    }
    if (data && !id) {
      //Se guarda el id
      setId(data.IdCarro);
      //Se hace set al formulario
      form.setFields([
        { name: "IdCliente", value: data?.IdCliente },
        { name: "Placa", value: data?.Placa },
        { name: "Marca", value: data?.Marca },
        { name: "Clase", value: data?.Clase },
        { name: "Modelo", value: data?.Modelo },
        { name: "Color", value: data?.Color },
        { name: "Capacidad", value: data?.Capacidad},
        { name: "Contenedor", value: data?.Contenedor },
        { name: "Seriales", value: data?.Seriales },
        { name: "isAccompanist", value: data?.isAccompanist },
      ]);
    }
  }, [data]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result.status) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(editVehicleClose());
    form.resetFields();
    setId();
    if (refresh) onClose(refresh);
  });


  return (
    <Modal
      title={
        isEdit ? messages["editVehicle_titleUpdate"] : messages["editVehicle_title"]
      }
      visible={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin
                tip="Loading..."
                spinning={loading || loadingClients}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(
                      editVehicleSave({
                        ...values,
                        IdCarro: data?.IdCarro,
                      }
                      )
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Placa"
                        label={messages["editVehicle_plate"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Marca"
                        label={messages["editVehicle_brand"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    
                  </div>

                  <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Item
                        name="Modelo"
                        label={messages["editVehicle_model"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdCliente"
                        label={messages["editVehicle_client"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Select
                        onChange={(v) => {
                          setClientId(v);
                        }}
                        disabled={user.IdRol !== 1}
                        >
                            {clients &&
                              clients.map((client, index) => {
                                return (
                                  <Select.Option key={index} value={client.IdCliente}>
                                    {client.RazonSocial}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-12 col-md-6">
                      <Form.Item
                        name="Clase"
                        label={messages["editVehicle_class"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Color"
                        label={messages["editVehicle_color"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Capacidad"
                        label={messages["editVehicle_capacity"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                         <Input />
                      </Form.Item>
                    </div>
                      <div className="col-12 col-md-6">
                        <Form.Item
                          name="Contenedor"
                          label={messages["editVehicle_container"]}
                          rules={[
                            {
                              required: true,
                              message: messages["required_field"],
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                          <Form.Item
                            name="Seriales"
                            label={messages["editVehicle_serials"]}
                            rules={[
                              {
                                required: true,
                                message: messages["required_field"],
                              },
                            ]}
                          >
                          <Input />
                          </Form.Item>
                        </div>
                      <div className="col-12 col-md-6">
                          <Form.Item
                            name="isAccompanist"
                            label={messages["editVehicle_accompanist"]}
                            rules={[
                              {
                                required: true,
                                message: messages["required_field"],
                              },
                            ]}
                          >
                          <Select>
                            <Select.Option value={1}>
                              Sí
                            </Select.Option>
                            <Select.Option value={0}>
                              No
                            </Select.Option>
                          </Select>
                          </Form.Item>
                        </div>
                    </div>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button
                      loading={loading || loadingClients}
                      type="primary"
                      className="btn btn-alternate btn-end"
                      onClick={() => close()}
                    >
                      {messages["cancel"]}
                    </Button>
                    <Button
                      loading={loading || loadingClients}
                      type="primary"
                      htmlType="submit"
                      className="btn btn-primary btn-end"
                    >
                      {messages["save"]}
                    </Button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditVehicleComp;
