import {
    //TYPES
    CLEAR_REDUX,
    GET_LASTEST_SEGUIMIENTOS,
    GET_LASTEST_SEGUIMIENTOS_RESULT
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_LASTEST_SEGUIMIENTOS:
            return { ...state, loading: true  };
        
        case GET_LASTEST_SEGUIMIENTOS_RESULT:
            return { ...state, loading: false, data: action.payload.response };

        case CLEAR_REDUX:
            return ["", "DASHBOARD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state;

        default:
            return state;
    }
};
