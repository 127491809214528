import {
    CHANGE_THEME_CONFIG
} from 'config/redux/actionTypes';

import { htmlInitialAttributes } from 'config/constants'

const INIT_STATE = {
    lang: "en",
    "data-footer": "true",
    ...htmlInitialAttributes
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CHANGE_THEME_CONFIG:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};
