//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EXCEL_UPLOAD_SAVE,
    EXCEL_UPLOAD_GET_USERS_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    excelUploadSaveResult,
    getUsersSaveResult
} from 'config/redux/actions';

function* excelUploadSaveRequest() {
    yield takeEvery(EXCEL_UPLOAD_SAVE, function* ({ payload }) {
        try {

            for (let i in payload) {
            
            let lastUser = i == payload.length - 1;
            let percent = Math.floor(((i+1) / payload.length) * 100);
            const { username, email, firstName, lastName, companyId, roles } = payload[i];

                let data = yield call(
                    clientMutation,
                    `mutation createUser ($username: String, $email: String, $firstName: String, $lastName: String, $imageUrl: String, $companyId: Int, $roles: [Int]) {
                        newUser: createUser (username: $username, email: $email, firstName: $firstName, lastName: $lastName, imageUrl: $imageUrl, companyId: $companyId, roles: $roles) {
                            id
                        }
                    }`,
                    {
                        username,
                        email,
                        firstName,
                        lastName,
                        companyId,
                        roles
                    },
                    endpoints.GRAPHQL_GENERAL
                );


                yield put(excelUploadSaveResult(lastUser ? { status: true} : null, lastUser ? 100 : percent));    
            }
          

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/excelUpload/redux/saga', 'excelUploadSaveRequest');
            yield put(excelUploadSaveResult({ status: false, error: exc.error}, null));

        }
    }); 
}

function* excelUploadGetUsersRequest() {
  yield takeEvery(EXCEL_UPLOAD_GET_USERS_SAVE, function* ({ payload }) {
    try { 
      let data = yield call(
        clientQuery,
        `query  getUsers ($all: Boolean){ 
          users: getUsers (all: $all) {
            id
            firstName
            lastName
            username
            email
            roles {
              id
            }
          }
        }`,
        {
          all: true
        },
        endpoints.GRAPHQL_GENERAL
      );
      yield put(getUsersSaveResult(data.users));                   
    } catch (exc) {
      ExceptionManager(exc, 'modules/admin/components/excelUpload/redux/saga', 'excelUploadGetUsersRequest');
      yield put(getUsersSaveResult([]));
    }
  }); 
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(excelUploadSaveRequest),
        fork(excelUploadGetUsersRequest)
    ]);
}
