import {
    //TYPES
    EDIT_CLIENT_OPEN,
    EDIT_CLIENT_OPEN_RESULT,
    EDIT_CLIENT_CLOSE,
    EDIT_CLIENT_SAVE,
    EDIT_CLIENT_SAVE_RESULT
} from 'config/redux/actionTypes';

export const editClientOpen = (id, isDelete = false) => ({
    type: EDIT_CLIENT_OPEN,
    payload: { id, isDelete }
});

export const editClientOpenResult = (data) => ({
    type: EDIT_CLIENT_OPEN_RESULT,
    payload: data
});

export const editClientClose = () => ({
    type: EDIT_CLIENT_CLOSE,
    //payload: data
});

export const editClientSave = (data, isDelete = false) => ({
    type: EDIT_CLIENT_SAVE,
    payload: { data, isDelete }
});

export const editClientSaveResult = (result) => ({
    type: EDIT_CLIENT_SAVE_RESULT,
    payload: result
});