//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    AUTH_GET_USER_PROFILE,
    AUTH_LOGOUT
} from 'config/redux/actionTypes';

//actions
import {
    authGetUserProfileResult,
    authLogout,
    clearRedux
} from 'config/redux/actions';


function* authGetUserProfileRequest() {
    yield takeEvery(AUTH_GET_USER_PROFILE, function* ({ payload }) {
        const { history } = payload;
        try {
            let data = yield call(
                clientQuery,
                `{
                    User: getUserProfile {
                        IdUsuario
                        Nombre
                        Correo
                        Identificacion
                        Password
                        Telefono
                        IdCliente
                        Cargo
                        CiudadId
                        IdRol
                    }
                }`,
                {},
                endpoints.GRAPHQL_GENERAL,
            );

            if (data && data.User) {

                localStorage.setItem("username", data.User.Nombre)
                yield put(authGetUserProfileResult(data.User));
            }
            else
                throw 'not user';

        } catch (exc) {
            ExceptionManager(exc, 'containers/auth/redux/saga', 'authGetUserProfileRequest');
            yield put(authLogout(history));
            yield put(authGetUserProfileResult());

        }
    });
}

function* authLogoutRequest() {
    yield takeEvery(AUTH_LOGOUT, function* ({ payload }) {
        const { history } = payload;

        try {
            yield put(clearRedux(""));
            localStorage.removeItem('token');
            let keys = Object.keys(sessionStorage)
            keys.forEach(key => {
                sessionStorage.removeItem(key)
            })
            console.log('logout');
            // normal logout
            history.push('/login');

        } catch (exc) {

            ExceptionManager(exc, 'containers/auth/redux/saga', 'authLogoutRequest');
            // catch throw
            if (history)
                history.push('/login');
        }
    }
    );
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(authGetUserProfileRequest),
        fork(authLogoutRequest)
    ]);
}
