import React, { useEffect, useState } from 'react'
import { getCatalog } from "config/redux/actions";
import { useDispatch, useSelector } from 'react-redux';


export const useCitiesCascader = () => {
    const dispatch = useDispatch();
    const [cascaderOptions, setCascaderOptions] = useState([])

    const { cities, loadingCities, states, loadingStates } = useSelector(
        (state) => state.commonRedux
      );

    useEffect(() => {
        dispatch(getCatalog(["states", "cities"], true, {states: {PaisCode: 170}, cities: {PaisCode: 170}})); 
    }, [])

    useEffect(() => {
      if (states && states.length>0 && cities && cities.length > 0) {
        let optionsMap = states.map(state =>
            ({
                label: state.DepartmentName,
                value: state.DepartmentCode,
                children: cities.filter(v => v.DepartmentCode == state.DepartmentCode).map(city => ({
                    label: city.CityName,
                    value: city.CityCode,
                }))
            })
        )
        setCascaderOptions(optionsMap);
      }
    }, [states, cities])

    return{
        cascaderOptions, cities, loadingCities, states, loadingStates
    }

}