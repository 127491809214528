//Global keys
let locales = {
};

//Merge Modules
import login from '../../views/login/lan/es';

locales = {
    ...login,
};

export default locales;