//imports
import { all } from 'redux-saga/effects';

import dashboardSagas from '../../views/main/redux/sagas';
//merge sagas
export default function* rootSaga() {
    yield all([
        dashboardSagas(),
    ]);
}
