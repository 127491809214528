//imports
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { clientQuery, clientMutation } from "config/helpers/GraphQLApi";
import { endpoints } from "config/constants";

import { ExceptionManager } from "config/helpers/Logging";

//action types
import { ADD_FILE_SEGUIMIENTO_SAVE } from "config/redux/actionTypes";

//actions
import { addFileSeguimientoSaveResult } from "config/redux/actions";

function* addFileSeguimientoSaveRequest() {
  yield takeEvery(ADD_FILE_SEGUIMIENTO_SAVE, function* ({ payload }) {
    try {
      const { data } = payload;
      let query = null;

      if (data && data?.idFile) {
        query = `mutation editSeguimientoFile(
              $idSeguimiento: Int!
              $idFile: Int!
              $name: String!
              $description: String
          ){
              result: editSeguimientoFile(
                  idSeguimiento: $idSeguimiento
                  idFile: $idFile
                  name: $name
                  description: $description
              ){
                id
              }
          }`;
      } else {
        query = `mutation createSeguimientoFile(
              $idSeguimiento: Int!
              $type: String!
              $name: String!
              $description: String
              $file: Upload!
          ){
              result: createSeguimientoFile(
                  idSeguimiento: $idSeguimiento
                  type: $type
                  name: $name
                  description: $description
                  file: $file
              ){
                  id
              }
          }`;
      }
      //create
      const result = yield call(
        clientMutation,
        query,
        data,
        endpoints.GRAPHQL_GENERAL
      );

      yield put(
        addFileSeguimientoSaveResult({
          result: result?.result,
          isSuccess: true,
        })
      );
    } catch (exc) {
      ExceptionManager(
        exc,
        "modules/seguimientoDetail/components/addFile/redux/saga",
        "addFileSeguimientoSaveRequest"
      );
      yield put(
        addFileSeguimientoSaveResult({
          result: null,
          isError: true,
          error: exc.error,
        })
      );
    }
  });
}

//MERGE SAGA
export default function* rootSaga() {
  yield all([fork(addFileSeguimientoSaveRequest)]);
}
