import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { Button, Dropdown, Menu, Tag, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";
import { Link } from "react-router-dom";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditVehicle from "../../components/editVehicle";
import ExcelUpload from "../../components/excelUpload";
import SidePanelFilters from "components/SidePanelFilters";

import { validateRoles } from "config/helpers/Utils";

import { vehicleListSearch, editVehicleOpen, getCatalog, editVehicleSave } from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";

const UserListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();

  const [statusFilter, setStatusFilter] = useState(1)
  const { user } = useSelector((state) => state.authRedux);
  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.vehiclesListRedux);
  const { companies, roles } = useSelector((state) => state.commonRedux);

  //Inicio del componente
  useEffect(() => {
    dispatch(getCatalog(["companies", "roles"]));
    tableviewRef.current?.refresh();
  }, []);
  
  //definicion de columnas Tabla
  const columns = useMemo(() => [
    {
      title: messages["vehiclesList_plate"],
      dataIndex: "Placa",
      key: "Placa",
      render: (v, values) => <Link to={`/app/admin/vehicleDetail/${values?.IdCarro}`} >{v}</Link>
    },
    {
      title: messages["vehiclesList_brand"],
      dataIndex: "Marca",
      key: "Marca",
    },
    {
      title: messages["vehiclesList_model"],
      dataIndex: "Modelo",
      key: "Modelo",
    },
    {
      title: messages["vehiclesList_color"],
      dataIndex: "Color",
      key: "Color",
    },
    {
      title: messages["vehiclesList_client"],
      dataIndex: "Client",
      key: "Client",
      render: (v, values) => <span>{values?.ClienteInfo?.RazonSocial}</span>,
    },
    {
      title: messages["vehiclesList_capacity"],
      dataIndex: "Capacidad",
      key: "Capacidad",
      
    },
    {
      title: messages["vehiclesList_container"],
      dataIndex: "Contenedor",
      key: "Contenedor",
    },
    {
      title: messages["vehiclesList_serials"],
      dataIndex: "Seriales",
      key: "Seriales",
    },
    {
      title: messages["vehiclesList_accompanist"],
      dataIndex: "isAccompanist",
      key: "isAccompanist",
      render: (val) =>
  (val > 0) ? (
    <Tag color="green">Sí</Tag>
    ) : (
      <Tag color="red">No</Tag>
      ),
    },
    {
      title: messages["vehiclesList_status"],
      dataIndex: "estado",
      key: "estado",
      render: (val) =>
      (val > 0) ? (
        <Tag color="blue">Activo</Tag>
        ) : (
          <Tag color="red">Inactivo</Tag>
          ),
        },
        {
          title: messages["createdBy"],
          dataIndex: "UsuarioCreacionInfo",
          key: "createdBy",
          render: (val, values) => val?.Nombre
        },
        {
          key: "options",
          width: 50,
          render: (v, values) =>
          /* validateRoles([1], user) && */ (
          <Dropdown
            overlay={() => (
              <Menu>
                {values?.estado === 1 && (
                  <Menu.Item key="mu1">
                    <Button
                      type="text"
                      onClick={() => dispatch(editVehicleOpen(values?.IdCarro))}
                    >
                      {messages["edit"]}
                    </Button>
                  </Menu.Item>
                )}

                <Menu.Item key="mu2">
                  <Button 
                  onClick={() => dispatch(editVehicleSave({IdCarro: values?.IdCarro, estado: 0}))}
                  type="text">{messages["delete"]}</Button>
                </Menu.Item>
              </Menu>
            )}
            placement="bottomCenter"
          >
            <MoreOutlined className="icon" />
          </Dropdown>
        ),
    },
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(vehicleListSearch(openSearch || "", limit, offset, statusFilter));
  });

  const onSearch = useCallback((openSearch, limit, offset) => {
    dispatch(vehicleListSearch(openSearch || "", limit, offset, statusFilter));
  });

  const onClear = useCallback(() => {
    dispatch(vehicleListSearch('', 50,0, 1));
  });


  console.log("Data: ", data.items)

  return (
    <div className="container">
      <HeaderPage title={messages["vehiclesList_title"]}>
        <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlinePlus" />}
            onClick={() => dispatch(editVehicleOpen())}
          >
            {messages["vehiclesList_add"]}
          </Button>
        </div>
      </HeaderPage>

      <SidePanelFilters
        loading={loading}
        onRefreshTable={onSearch}
        onClear={onClear}
        searchTitle="Placa/Marca/Modelo"
      >
        <div className="col-12 mt-2">
          <div>{messages["vehiclesList_status"]}</div>
          <Select
            className="w-100"
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          >
                <Select.Option key={1} value={1}>
                  Activo
                </Select.Option>
                <Select.Option key={2} value={0}>
                  Inactivo
                </Select.Option>
          </Select>
        </div>
      </SidePanelFilters>

      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            Placa: value?.Placa,
            Marca: value?.Marca,
            Modelo: value?.Modelo,
            Color: value?.Color,
            Empresa: value?.ClienteInfo?.RazonSocial,
            Color: value?.Color,
            Capacidad: value?.Capacidad,
            Contenedor: value?.Contenedor,
            Escolta	: value?.isAccompanist == 0 ? "No" : "Sí",
            Estado: value?.estado == 0 ? "Inactivo" : "Activo",
          };
        })}
      />

      <EditVehicle
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
      <ExcelUpload refresh={() => tableviewRef.current?.refresh()} />
    </div>
  );
};

export default UserListView;
