//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EXCEL_UPLOAD_CONTRACTORS_SAVE,
    EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    excelUploadContractorsSaveResult,
    getContractorsSaveResult
} from 'config/redux/actions';

function* excelUploadContractorsSaveRequest() {
    yield takeEvery(EXCEL_UPLOAD_CONTRACTORS_SAVE, function* ({ payload }) {
        try {

            for (let i in payload) {

            let lastUser = i == payload.length - 1;
            let percent = Math.floor(((i+1) / payload.length) * 100);
            const { cityId, name, documentTypeId, documentNumber, phoneNumber, status, address, email } = payload[i];

                let data = yield call(
                    clientMutation,
                    `mutation createCompany ($documentTypeId: Int!, $cityId: Int!, $name: String!, $documentNumber: String!, $phoneNumber: String!, $status: Int, $address: String!, $email: String!) {
                        newCompany: createCompany (documentTypeId: $documentTypeId, cityId: $cityId, name: $name, documentNumber: $documentNumber, phoneNumber: $phoneNumber, status: $status, address: $address, email: $email) {
                            id
                        }
                    }`,
                    {
                        cityId,
                        name,
                        documentNumber,
                        phoneNumber,
                        status,
                        address,
                        documentTypeId,
                        email
                    },
                    endpoints.GRAPHQL_GENERAL
                );


                yield put(excelUploadContractorsSaveResult(lastUser ? { status: true} : null, lastUser ? 100 : percent));    

            }
          

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/excelUpload/redux/saga', 'excelUploadSaveRequest');
            yield put(excelUploadContractorsSaveResult({ status: false, error: exc.error}, null));

        }
    }); 
}

function* excelUploadContractorsGetContractorsRequest() {
  yield takeEvery(EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE, function* ({ payload }) {
    try { 
      let data = yield call(
        clientQuery,
        `query { 
          companies: getCompanies {
            id
            name
            documentNumber
          }
        }`,
        {},
        endpoints.GRAPHQL_GENERAL
      );
      yield put(getContractorsSaveResult(data.companies));                   
    } catch (exc) {
      ExceptionManager(exc, 'modules/admin/components/excelUpload/redux/saga', 'excelUploadContractorsGetContractorsRequest');
      yield put(getContractorsSaveResult([]));
    }
  }); 
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(excelUploadContractorsSaveRequest),
        fork(excelUploadContractorsGetContractorsRequest)
    ]);
}
