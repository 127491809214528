let locales = {
    excelUpload_title: 'Importar Datos por Excel',
    excelDownload_template: 'Descargar plantilla',
    excelUpload_save: 'Cargar',
    excelUpload_titleUpload: 'Haga click o arrastre el archivo para subirlo',
    excelUpload_message: 'Suba su archivo de excel con la información correspondiente a los usuarios que desea subir a la plataforma',
    excelUpload_templateExample: `El archivo excel debe cumplir con la siguiente plantilla: 
                                  Nombre de usuario, Correo, Nombre, Apellido, Compañía a la que pertenece 
                                  y los roles que se le asignarán. Cada uno de los campos es obligatorio y no debe estar vacío, 
                                  cumpliendo con sus respectivas validaciones (Usuario no repetido y con un máximo de 50 caracteres, correo válido con menos de 50 caracteres,  
                                  nombres y apellidos correctos sin caracteres especiales y con máximo 40 caracteres cada uno, roles existentes y empresa contratista (de necesitarse) existente).`,
    excelUpload_moreThanFiftyUsers: `Ha excedido nuestro límite de 50 usuarios por archivo, 
                                    por lo cual hemos incluido sólo los primeros 50 registros.`,
    excelUpload_fileContainErrors: `NOTA: Algunos registros del archivo contienen errores de validación,
                                    por lo que éstos se ignorarán y sólo se importarán los válidos.
                                    Por favor, verifique la información.`,
    excelUpload_fileEveryRegisterInvalid: `ATENCIÓN: Todos los registros del archivo contienen errores de validación,
                                            por lo cual no es posible importarlos. Por favor, verifique la información`,

    excelUpload_invalidUsername: 'Nombre de usuario inválido',
    excelUpload_invalidEmail: 'Email inválido',
    excelUpload_invalidName: 'Nombre inválido',
    excelUpload_invalidLastname: 'Apellido inválido',
    excelUpload_userAlreadyExists: 'El Usuario ya existe',
    excelUpload_companyDoesntExists: 'Compañía inexistente',
    excelUpload_rolDoesntExists: 'Rol inexistente',
    excelUpload_repeatedData: 'Datos Repetidos',
    excelUpload_emptyData: 'Campo(s) vacío(s)',
    excelUpload_validDataRow: 'Usuario válido',
    excelUpload_hseAndSTInvalid: 'El usuario no puede ser HSE y Supervisor Técnico a la vez.',
};

export default locales;