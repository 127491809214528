import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, Spin, spin } from "antd";
import { useIntl } from "react-intl";

const DeleteAlert = forwardRef((props, ref) => {
  const { title, description, onDelete, onClose, onOpen, children } = props;
  const { messages } = useIntl();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: openModal,
      close: closeModal,
    }),
    []
  );
  function openModal(values) {
    setOpen(true);
    setValues(values);
    if (onOpen) {
      onOpen();
    }
  }
  function closeModal() {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }
  const handleDelete = async () => {
    if (onDelete) {
      setIsLoading(true);
      await onDelete(values).finally(() => setIsLoading(false));
      closeModal()
    }
  };
  return (
    <Modal
      title={title || messages["deleteTitle"]}
      open={open}
      onOk={() => {}}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin spinning={isLoading}>
                <p>{description || messages["deleteMessage"]}</p>
                {children}
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                    loading={isLoading}
                    type="primary"
                    className="btn btn-alternate btn-end"
                    onClick={closeModal}
                  >
                    {messages["cancel"]}
                  </Button>
                  <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    onClick={handleDelete}
                    className="btn btn-primary btn-end"
                  >
                    {messages["continue"]}
                  </Button>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default DeleteAlert;
