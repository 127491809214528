//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_ACCOMPANIST_OPEN,
    EDIT_ACCOMPANIST_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editAccompanistOpenResult,
    editAccompanistSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editAccompanistOpenRequest() {
    yield takeEvery(EDIT_ACCOMPANIST_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;

            if (!id) {
                yield put(editAccompanistOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getAccompanistById($id: Int){
                    accompanist: getAccompanistById(id: $id){
                        IdAcompanante
                        Nombre
                        Cedula
                        Celular
                        IdVehiculo
                        estado
                    }
                }`,
                {
                    id: id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editAccompanistOpenResult(data.accompanist))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editAccompanist/redux/saga', 'editAccompanistOpenRequest');
            yield put(editAccompanistOpenResult());

        }
    });
}

function* editAccompanistSaveRequest() {
    yield takeEvery(EDIT_ACCOMPANIST_SAVE, function* ({ payload }) {
        try {
            const { data } = payload;
            let query
            console.log("payload:", payload)

            if (data.IdAcompanante) {
                query = yield call(
                    clientMutation,
                    `mutation updateAccompanist(
                        $IdAcompanante: Int!
                        $Nombre: String
                        $Cedula: String
                        $Celular: String
                        $IdVehiculo: Int
                        $estado: Int
                      ){
                    accompanist: updateAccompanist(
                        IdAcompanante: $IdAcompanante
                        Nombre: $Nombre
                        Cedula: $Cedula
                        Celular: $Celular
                        IdVehiculo: $IdVehiculo
                        estado: $estado
                        ){
                        IdAcompanante
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editAccompanistSaveResult({ status: true }));

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createAccompanist(
                        $Nombre: String
                        $Cedula: String
                        $Celular: String
                        $IdVehiculo: Int
                      ){
                    accompanist: createAccompanist(
                        Nombre: $Nombre
                        Cedula: $Cedula
                        Celular: $Celular
                        IdVehiculo: $IdVehiculo
                        ){
                        IdAcompanante
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editAccompanistSaveResult({ status: true, id: query.accompanist.IdAcompanante }));

            }


        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editAccompanist/redux/saga', 'editAccompanistSaveRequest');
            yield put(editAccompanistSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editAccompanistOpenRequest),
        fork(editAccompanistSaveRequest)
    ]);
}
