//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_VEHICLE_OPEN,
    EDIT_VEHICLE_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editVehicleOpenResult,
    editVehicleSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editVehicleOpenRequest() {
    yield takeEvery(EDIT_VEHICLE_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;
            if (!id) {
                yield put(editVehicleOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getVehicleById($id: Int){
                    vehicle: getVehicleById(id: $id){
                        IdCarro
                        IdCliente
                        Placa
                        Marca
                        Clase
                        Modelo
                        Color
                        Capacidad
                        Contenedor
                        Seriales
                        isAccompanist
                    }
                }`,
                {
                    id: id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editVehicleOpenResult(data.vehicle))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editVEHICLE/redux/saga', 'editVehicleOpenRequest');
            yield put(editVehicleOpenResult());

        }
    });
}

function* editVehicleSaveRequest() {
    yield takeEvery(EDIT_VEHICLE_SAVE, function* ({ payload }) {
        try {
            const { data } = payload;
            let query
            console.log("payload:", payload)

            if (data.IdCarro) {
                query = yield call(
                    clientMutation,
                    `mutation updateVehicle(
                        $IdCarro: Int!
                        $IdCliente: Int
                        $Placa: String
                        $Marca: String
                        $Clase: String
                        $Modelo: String
                        $Color: String
                        $Capacidad: String
                        $Contenedor: String
                        $Seriales: String
                        $isAccompanist: Int
                        $estado: Int
                      ){
                    vehicle: updateVehicle(
                        IdCarro: $IdCarro
                        IdCliente: $IdCliente
                        Placa: $Placa
                        Marca: $Marca
                        Clase: $Clase
                        Modelo: $Modelo
                        Color: $Color
                        Capacidad: $Capacidad
                        Contenedor: $Contenedor
                        Seriales: $Seriales
                        isAccompanist: $isAccompanist
                        estado: $estado
                        ){
                        IdCarro
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editVehicleSaveResult({ status: true }));

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createVehicle(
                        $IdCliente: Int
                        $Placa: String
                        $Marca: String
                        $Clase: String
                        $Modelo: String
                        $Color: String
                        $Capacidad: String
                        $Contenedor: String
                        $Seriales: String
                        $isAccompanist: Int
                      ){
                    vehicle: createVehicle(
                        IdCliente: $IdCliente
                        Placa: $Placa
                        Marca: $Marca
                        Clase: $Clase
                        Modelo: $Modelo
                        Color: $Color
                        Capacidad: $Capacidad
                        Contenedor: $Contenedor
                        Seriales: $Seriales
                        isAccompanist: $isAccompanist
                        ){
                        IdCarro
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editVehicleSaveResult({ status: true, id: query.vehicle.IdCarro }));

            }


        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editVEHICLE/redux/saga', 'editVehicleSaveRequest');
            yield put(editVehicleSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editVehicleOpenRequest),
        fork(editVehicleSaveRequest)
    ]);
}
