import { all } from 'redux-saga/effects';

import commonSagas from 'config/redux/common/sagas';
import containersSagas from 'containers/.config/redux/sagas';
import securitySagas from 'security/.config/redux/sagas';
import adminSagas from 'modules/admin/.config/redux/sagas';
import dashboardSagas from 'modules/dashboard/.config/redux/sagas';
import trackSagas from 'modules/track/.config/redux/sagas';

export default function* rootSaga() {
    yield all([
        commonSagas(),
        containersSagas(),
        securitySagas(),
        adminSagas(),
        dashboardSagas(),
        trackSagas()
    ]);
}