import {
    //TYPES
    SEGUIMIENTO_LIST_SEARCH,
    SEGUIMIENTO_LIST_SEARCH_RESULT
} from 'config/redux/actionTypes';

export const seguimientoListSearch = (openSearch, limit, offset, status = null, otherFilters) => ({
    type: SEGUIMIENTO_LIST_SEARCH,
    payload: { openSearch, limit, offset, status, otherFilters },
});

export const seguimientoListSearchResult = (data) => ({
    type: SEGUIMIENTO_LIST_SEARCH_RESULT,
    payload: data,
});
