import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Spin, Tabs, Tag, Button } from "antd";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditDetalleSeguimiento from "modules/track/views/seguimientosDetail/components/editDetalleSeguimiento";

import { Icons } from "components";
import GoogleMaps from "components/GoogleMaps";
import { validateRoles } from "config/helpers/Utils";
import {
  getLatestWorkPermissionNews,
  editDetalleSeguimientoOpen,
  detalleSeguimientoDetalleSetPrevPage,
} from "config/redux/actions";
import LittleCard from "../../components/LittleCard";
import List from "../../components/ListItems";
//estilos para que el spin no afecte altura de mapa
import "./css/styles.css";
import moment from "moment";

const MainView = ({ history }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();
  const mapRef = useRef();
  const { user } = useSelector((state) => state.authRedux);
  const { loading, data } = useSelector((state) => state.dashboardRedux);

  useEffect(() => {
    onRefreshTable();
    let intervalDataRequest = setInterval(() => {
      onRefreshTable();
    }, 300000);
    return () => {
      clearInterval(intervalDataRequest);
    };
  }, []);

  useEffect(() => {
    if (data && !loading) {
      addNewsMarkers(data?.items)
    }
  }, [data]);

  const addNewsMarkers = (items) => {
    if (!items) {
      return;
    }
    mapRef.current?.clearMarkers();
    //mapRef.current?.clearZoom();
    items.forEach((item) => {
      mapRef.current?.addMarker(
        item?.IdSeguimiento,
        { lng: item?.UltimoDetalleSeguimiento?.Longitude, lat: item?.UltimoDetalleSeguimiento?.Latitude },
        "Reporte",
        `<div> 
            <p><b>${messages["seguimientoDetail_tabledetails_creationDate"]}:</b> ${moment(item?.UltimoDetalleSeguimiento?.FechaHoraReporte).format("lll")}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_withoutNovedad"]}:</b> ${item?.UltimoDetalleSeguimiento?.SN ? "Sin Novedad" : "Con Novedad"}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_reportPoint"]}:</b> ${item?.UltimoDetalleSeguimiento?.PuntoReporte}</p>
            <p><b>${messages["seguimientoDetail_tabledetails_observation"]}:</b> ${item?.UltimoDetalleSeguimiento?.Observacion
        }</p>  
          </div>`
      );
    });
  }

  // const columns = useMemo(() => [
  //   {
  //     title: messages["dashboard_semaforo"],
  //     dataIndex: "SemaforoColor",
  //     key: "SemaforoColor",
  //     align: "left",
  //     width: 90,
  //     render: (v, values) => {
  //       let semaforoInfo = {
  //         1: {
  //           className: "text-danger",
  //           name: "AiFillCloseCircle",
  //         },
  //         2: {
  //           className: "text-warning",
  //           name: "AiFillExclamationCircle",
  //         },
  //         3: {
  //           className: "text-success",
  //           name: "AiFillCheckCircle",
  //         },
  //       };
  //       return (
  //         <>
  //           <Link
  //             onClick={() =>
  //               dispatch(detalleSeguimientoDetalleSetPrevPage("dashboard"))
  //             }
  //             to={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`}
  //           >
  //             <Icons
  //               family="ai"
  //               name={semaforoInfo[v]?.name || "AiFillExclamationCircle"}
  //               className={
  //                 `${semaforoInfo[v]?.className || "text-gray"}` + " fs-1"
  //               }
  //             />
  //           </Link>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: messages["dashboard_orderCar"],
  //     dataIndex: "Orden",
  //     key: "Orden",
  //     align: "left",
  //     width: 160,
  //     render: (v, values) => (
  //       <a
  //         className="mx-2"
  //         href={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`}
  //         target="blank"
  //       >
  //         {" "}
  //         <span>{`${v} - ${values?.CarroInfo?.Placa}`}</span>
  //       </a>
  //     ),
  //   },
  //   {
  //     title: messages["seguimientosList_client"],
  //     dataIndex: "Client",
  //     key: "Client",
  //     align: "left",
  //     width: 200,
  //     render: (v, values) => <span>{values?.ClienteInfo?.RazonSocial}</span>,
  //   },
  //   {
  //     title: messages["dashboard_cities"],
  //     dataIndex: "Ciudades",
  //     key: "Ciudades",
  //     align: "left",
  //     width: 150,
  //     render: (v, values) => (
  //       <span>{`${
  //         values?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() +
  //         values?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase()
  //       } - ${
  //         values?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() +
  //         values?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase()
  //       }`}</span>
  //     ),
  //   },
  //   {
  //     title: messages["dashboard_lastNovedad"],
  //     dataIndex: "UltimoDetalleSeguimiento",
  //     key: "UltimoDetalleSeguimiento",
  //     align: "left",
  //     width: 200,
  //     render: (v) => (
  //       <>
  //         <span className="text-muted text-small">
  //           {moment(v?.FechaHoraReporte).format("lll")}
  //         </span>
  //         <br />
  //         <span className={v?.SN == 0 ? "text-danger" : ""}>
  //           {v?.Observacion || "Sin Observación"}
  //         </span>
  //       </>
  //     ),
  //   },
  //   {
  //     title: messages["dashboard_startService"],
  //     dataIndex: "FechaSalida",
  //     key: "FechaSalida",
  //     align: "left",
  //     width: 200,
  //     render: (v) => <span>{moment(v).format("lll")}</span>,
  //   },
  //   {
  //     width: 200,
  //     title: messages["seguimientosList_accompanist"],
  //     dataIndex: "Acompanante",
  //     key: "Acompanante",
  //     render: (val, values) => <span>{values?.AcompananteInfo?.Nombre}</span>,
  //   },
  //   {
  //     width: 200,
  //     title: messages["dashboard_driverPhonerNumber"],
  //     dataIndex: "CelularCond",
  //     key: "CelularCond",
  //     render: (val, values) => <span>{values?.CelularCond}</span>,
  //   },
  // ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(getLatestWorkPermissionNews(openSearch, limit, offset, 2));
  });

  return (
    <div className="container">
      <HeaderPage title="Dashboard"></HeaderPage>
      <Card>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              {validateRoles([1], user) && (
                <div className="d-inline-block">
                  <Button
                    className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
                    icon={<Icons family="ai" name="AiOutlinePlus" />}
                    onClick={() => dispatch(editDetalleSeguimientoOpen())}
                  >
                    {messages["seguimientoDetail_titleAdd"]}
                  </Button>
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-around mt-4">
              <LittleCard
                title="Al día"
                icon={
                  <Icons
                    family="ai"
                    name="AiFillCheckCircle"
                    className="text-success fs-1"
                  />
                }
              />
              <LittleCard
                title="En espera"
                icon={
                  <Icons
                    family="ai"
                    name="AiFillExclamationCircle"
                    className="text-warning fs-1"
                  />
                }
              />
              <LittleCard
                title="Sin notificación"
                icon={
                  <Icons
                    family="ai"
                    name="AiFillCloseCircle"
                    className="text-danger fs-1"
                  />
                }
              />
            </div>
            <List mapRef={mapRef} items={data?.items} loading={loading} count={data?.count} onRefresh={onRefreshTable} />
            {/* <TableView
              ref={tableviewRef}
              items={data?.items}
              loading={loading}
              columns={columns}
              count={data?.count}
              //scroll={{ x: 1050 }}
              onRefreshTable={onRefreshTable}
              gridDataForPrinting={data?.items?.map((value) => {
                let semaforoInfo = {
                  1: "rojo",
                  2: "amarillo",
                  3: "verde",
                };
                return {
                  Semaforo:
                    semaforoInfo[value?.SemaforoColor] || "No hay detalles",
                  Empresa: value?.ClienteInfo?.RazonSocial,
                  Vehículo: value?.CarroInfo?.Placa,
                  Link: value?.Link,
                  "Ciudad Origen":
                    value?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() +
                    value?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase(),
                  "Ciudad Destino":
                    value?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() +
                    value?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase(),
                  "Fecha Salida": moment(value?.FechaSalida).format("lll"),
                  Escolta: value?.AcompananteInfo?.Nombre,
                  "Celular del conductor": value?.CelularCond,
                };
              })}
            /> */}
          </div>
          <div style={{ height: "70vh" }} className="col-12 col-md-6">
            <GoogleMaps ref={mapRef} />
          </div>
        </div>
      </Card>
      <EditDetalleSeguimiento
        onClose={(refresh) => refresh && onRefreshTable()}
      />
    </div>
  );
};

export default MainView;
