import {
    //TYPES
    AUTH_GET_USER_PROFILE,
    AUTH_GET_USER_PROFILE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    user: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case AUTH_GET_USER_PROFILE:
            return { ...state, loading: true }

        case AUTH_GET_USER_PROFILE_RESULT:
            return { ...state, loading: false, user: action.payload }

        case CLEAR_REDUX:
            return ["", "AUTH"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
