//imports
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { clientQuery, clientMutation } from "config/helpers/GraphQLApi";
import { endpoints } from "config/constants";

import { ExceptionManager } from "config/helpers/Logging";

//action types
import { ADD_FILE_VEHICLE_SAVE } from "config/redux/actionTypes";

//actions
import { addFileVehicleSaveResult } from "config/redux/actions";

function* addFileVehicleSaveRequest() {
  yield takeEvery(ADD_FILE_VEHICLE_SAVE, function* ({ payload }) {
    try {
      const { data } = payload;
      let query = null;

      if (data && data?.idFile) {
        query = `mutation editVehicleFile(
              $idCarro: Int!
              $idFile: Int!
              $isVehiclePhoto: Int!
              $name: String!
              $description: String
          ){
              result: editVehicleFile(
                  idCarro: $idCarro
                  isVehiclePhoto: $isVehiclePhoto
                  idFile: $idFile
                  name: $name
                  description: $description
              ){
                id
              }
          }`;
      } else {
        query = `mutation createVehicleFile(
              $idCarro: Int!
              $type: String!
              $name: String!
              $isVehiclePhoto: Int
              $description: String
              $file: Upload!
          ){
              result: createVehicleFile(
                  idCarro: $idCarro
                  type: $type
                  isVehiclePhoto: $isVehiclePhoto
                  name: $name
                  description: $description
                  file: $file
              ){
                  id
              }
          }`;
      }
      //create
      const result = yield call(
        clientMutation,
        query,
        data,
        endpoints.GRAPHQL_GENERAL
      );

      yield put(
        addFileVehicleSaveResult({
          result: result?.result,
          isSuccess: true,
        })
      );
    } catch (exc) {
      ExceptionManager(
        exc,
        "modules/admin/views/vehicleDetail/components/files/redux/saga",
        "addFileVehicleSaveRequest"
      );
      yield put(
        addFileVehicleSaveResult({
          result: null,
          isError: true,
          error: exc.error,
        })
      );
    }
  });
}

//MERGE SAGA
export default function* rootSaga() {
  yield all([fork(addFileVehicleSaveRequest)]);
}
