//imports
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

//views
import usersListView from './views/usersList';
import clientsListView from './views/clientsList';
import vehiclesListView from './views/vehiclesList';
import accompanistsListView from './views/accompanistsList';
import vehicleDetailView from './views/vehicleDetail';
//others
import { validateRoles } from 'config/helpers/Utils'
//router
const adminRouter = ({ match }) => {
  const { user } = useSelector(state => state.authRedux)

    return (
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/userslist`} />
            {
              validateRoles([1], user) &&
                <Route path={`${match.url}/userslist`} component={usersListView} />
            }
            {
              validateRoles([1], user) &&
                <Route path={`${match.url}/clientslist`} component={clientsListView} />
            }
            {
            validateRoles([1,2], user) &&
                <Route path={`${match.url}/vehicleslist`} component={vehiclesListView} />
            }
            {
              validateRoles([1,2], user) &&
                <Route path={`${match.url}/accompanistslist`} component={accompanistsListView} />
            }
            {
              validateRoles([1,2], user) &&
                <Route path={`${match.url}/vehicleDetail/:id`} component={vehicleDetailView} />
            }
            <Redirect to="/error" />
        </Switch>
    );
};

export default adminRouter
