import {
    //TYPES
    LOGIN_LOGIN,
    LOGIN_LOGIN_RESULT,
    //----------------------------------------
    LOGIN_RECOVER_PASSWORD_OPENCLOSE,
    LOGIN_RECOVER_PASSWORD,
    LOGIN_RECOVER_PASSWORD_RESULT,
} from 'config/redux/actionTypes';

export const loginSingIn = (email, password, history) => ({
    type: LOGIN_LOGIN,
    payload: { email, password, history },
});

export const loginSingInResult = (result) => ({
    type: LOGIN_LOGIN_RESULT,
    payload: result,
});
//----------------------------------------
export const recoverPasswordOpenClose = ( open ) => ({
    type: LOGIN_RECOVER_PASSWORD_OPENCLOSE,
    payload: { open },
});

export const recoverPasswordRequest = ( email ) => ({
    type: LOGIN_RECOVER_PASSWORD,
    payload: { email },
});

export const recoverPasswordResult = (response) => ({
    type: LOGIN_RECOVER_PASSWORD_RESULT,
    payload: { response },
});