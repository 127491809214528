import {
    //TYPES
    ADD_FILE_VEHICLE_OPEN,
    ADD_FILE_VEHICLE_CLOSE,
    ADD_FILE_VEHICLE_SAVE,
    ADD_FILE_VEHICLE_SAVE_RESULT
} from 'config/redux/actionTypes';

export const addFileVehicleOpen = (fileData, vehicle, isVehiclePhoto) => ({
    type: ADD_FILE_VEHICLE_OPEN,
    payload: { fileData, vehicle, isVehiclePhoto }
});

export const addFileVehicleClose = () => ({
    type: ADD_FILE_VEHICLE_CLOSE,
});

export const addFileVehicleSave = (data) => ({
    type: ADD_FILE_VEHICLE_SAVE,
    payload: {data}
});

export const addFileVehicleSaveResult = (result) => ({
    type: ADD_FILE_VEHICLE_SAVE_RESULT,
    payload: result
});