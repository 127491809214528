//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_CLIENT_OPEN,
    EDIT_CLIENT_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editClientOpenResult,
    editClientSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editClientOpenRequest() {
    yield takeEvery(EDIT_CLIENT_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;

            //cargar catalogos iniciales
            yield put(getCatalog(['roles', 'companies']))

            if (!id) {
                yield put(editClientOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getClientById($id: Int){
                    client: getClientById(id: $id){
                        IdCliente
                        RazonSocial
                        Nit
                        Telefono
                        Direccion
                        Correo
                        PaisId
                        CiudadId
                        estado
                    }
                }`,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editClientOpenResult(data.client))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editClient/redux/saga', 'editClientOpenRequest');
            yield put(editClientOpenResult());

        }
    });
}

function* editClientSaveRequest() {
    yield takeEvery(EDIT_CLIENT_SAVE, function* ({ payload }) {
        try {
            const { data, isDelete } = payload;
            let query;
            console.log(data)

            if (data.IdCliente) {
                if (!isDelete) {
                    query = yield call(
                        clientMutation,
                        `mutation updateClient(
                            $IdCliente: Int!,
                            $RazonSocial: String
                            $Nit: String
                            $Telefono: String
                            $Direccion: String
                            $Correo: String
                            $PaisId: Int
                            $CiudadId: Int
                            $estado: Int
                            ){
                          client: updateClient(
                            IdCliente: $IdCliente
                            RazonSocial: $RazonSocial
                            Nit: $Nit
                            Telefono: $Telefono
                            Direccion: $Direccion
                            Correo: $Correo
                            PaisId: $PaisId
                            CiudadId: $CiudadId
                            estado: $estado
                            ){
                              IdCliente
                          }
                      }`,
                        data,
                        endpoints.GRAPHQL_GENERAL
                    );
    
                    yield put(editClientSaveResult({ status: true }));
                }else{
                    query = yield call(
                        clientMutation,
                        `mutation inactivateClient(
                            $IdCliente: Int!,
                            ){
                          clients: inactivateClient(
                            IdCliente: $IdCliente
                            ){
                              IdCliente
                          }
                      }`,
                        {IdCliente: parseInt(data.IdCliente)},
                        endpoints.GRAPHQL_GENERAL
                    );
    
                    yield put(editClientSaveResult({ status: true }));
                }

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createClient(
                        $RazonSocial: String
                        $Nit: String
                        $Telefono: String
                        $Direccion: String
                        $Correo: String
                        $PaisId: Int
                        $CiudadId: Int){
                      client: createClient(
                        RazonSocial: $RazonSocial
                        Nit: $Nit
                        Telefono: $Telefono
                        Direccion: $Direccion
                        Correo: $Correo
                        PaisId: $PaisId
                        CiudadId: $CiudadId){
                          IdCliente
                      }
                  }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editClientSaveResult({ status: true, id: query.client.IdCliente }));
            }
        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editClient/redux/saga', 'editClientSaveRequest');
            yield put(editClientSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editClientOpenRequest),
        fork(editClientSaveRequest)
    ]);
} 
