import {
    //TYPES
    EDIT_ACCOMPANIST_OPEN,
    EDIT_ACCOMPANIST_OPEN_RESULT,
    EDIT_ACCOMPANIST_CLOSE,
    EDIT_ACCOMPANIST_SAVE,
    EDIT_ACCOMPANIST_SAVE_RESULT
} from 'config/redux/actionTypes';

export const editAccompanistOpen = (id) => ({
    type: EDIT_ACCOMPANIST_OPEN,
    payload: { id }
});

export const editAccompanistOpenResult = (data) => ({
    type: EDIT_ACCOMPANIST_OPEN_RESULT,
    payload: data
});

export const editAccompanistClose = () => ({
    type: EDIT_ACCOMPANIST_CLOSE,
    //payload: data
});

export const editAccompanistSave = (data) => ({
    type: EDIT_ACCOMPANIST_SAVE,
    payload: {data}
});

export const editAccompanistSaveResult = (result) => ({
    type: EDIT_ACCOMPANIST_SAVE_RESULT,
    payload: result
});