import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Button, Dropdown, Menu, Tag, Select, DatePicker, Input } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";

import TableView from "components/TableView";

import { validateRoles } from "config/helpers/Utils";

import {
  seguimientoListSearch,
  editSeguimientoOpen,
  getCatalog,
  editSeguimientoSave,
  detalleSeguimientoDetalleSetPrevPage,
  clearRedux
} from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";
import moment from "moment";

const VehicleHistoryListView = ({ placa }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();

  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.seguimientosListRedux);

  //Inicio del componente
  useEffect(() => {
    tableviewRef.current?.refresh();
    return () => {
      dispatch(clearRedux("SEGUIMIENTO_LIST"))
    }
  }, []);

  //definicion de columnas Tabla
  const columns = useMemo(() => [
    // {
    //   title: messages["seguimientosList_order"],
    //   dataIndex: "Orden",
    //   key: "Orden",
    //   render: (v, values) => <Link onClick={() => dispatch(detalleSeguimientoDetalleSetPrevPage("seguimientos"))} to={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`} >{v}</Link>
    // },
    {
      title: messages["seguimientosList_originCity"],
      dataIndex: "CiudadOrigen",
      key: "CiudadOrigen",
      render: (v, values) => (
        <span>
          {values?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() +
            values?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase()}
        </span>
      ),
    },
    {
      title: messages["seguimientosList_destinyCity"],
      dataIndex: "CiudadDestino",
      key: "CiudadDestino",
      render: (v, values) => (
        <span>
          {values?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() +
            values?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase()}
        </span>
      ),
    },
    {
      title: messages["seguimientosList_startDate"],
      dataIndex: "FechaSalida",
      key: "FechaSalida",
      render: (val) => <span>{moment(val).format("lll")}</span>,
    },
    /* {
      title: messages["seguimientosList_endDate"],
      dataIndex: "FechaLlegada",
      key: "FechaLlegada",
      render: (val) => <span>{moment(val).format("lll")}</span>
    }, */
    {
      title: messages["seguimientosList_driverName"],
      dataIndex: "NombreCond",
      key: "NombreCond",
    },
    {
      title: messages["seguimientosList_accompanist"],
      dataIndex: "Acompanante",
      key: "Acompanante",
      render: (val, values) => (
        <span>
          {values?.AcompananteInfo?.Nombre +
            " - " +
            values?.AcompananteInfo?.Cedula}
        </span>
      ),
    },
    {
      title: messages["seguimientosList_status"],
      dataIndex: "Estado",
      key: "Estado",
      render: (val, values) => {
        let colorTag = {
          1: "default",
          2: "geekblue",
          3: "volcano",
          4: "green",
        };
        return (
          <Tag color={colorTag[values?.EstadoInfo?.IdEstado]}>
            {values?.EstadoInfo?.NombreEstado}
          </Tag>
        );
      },
    },
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    if (placa) {
      dispatch(
        seguimientoListSearch("", limit, offset, null, { Placa: placa })
      );
    }
  });

  return (
    <div className="container">
      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            Orden: value?.Orden,
            Empresa: value?.ClienteInfo?.RazonSocial,
            Carro:
              value?.CarroInfo?.Placa +
              " - " +
              value?.CarroInfo?.Marca +
              " " +
              value?.CarroInfo?.Modelo +
              " " +
              value?.CarroInfo?.Color,
            "Ciudad Origen":
              value?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() +
              value?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase(),
            "Ciudad Destino":
              value?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() +
              value?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase(),
            "Fecha de Salida": moment(value?.FechaSalida).format("lll"),
            "Fecha de Llegada": moment(value?.FechaLlegada).format("lll"),
            "Nombre del Conductor": value?.NombreCond,
            Escolta:
              value?.AcompananteInfo?.Nombre +
              " - " +
              value?.AcompananteInfo?.Cedula,
            Estado: value?.estado == 0 ? "Inactivo" : "Activo",
          };
        })}
      />
    </div>
  );
};

export default VehicleHistoryListView;
