//Global keys
let locales = {
    ok: 'Aceptar',
    close: 'Cerrar',
    add: 'Agregar',
    edit: 'Editar',
    delete: 'Eliminar',
    seeDetails: 'Ver Detalle',
    cancel: 'Cancelar',
    print: 'Imprimir',
    show: 'Ver',
    search: 'Buscar',
    continue: 'Continuar',
    back: 'Atras',
    save: "Guardar",
    new: "Nuevo",
    required_field: 'Campo Requerido',
    clear: 'Limpiar',
    select: 'Selecccionar',
    rows: 'Registros',
    actions: 'Acciones',
    invalid_email: 'Email Invalido',
    DB_NoAffectedRows: "No se afectaron registros",
    approve: "Aprobar",
    approved: "Aprobado",
    complete: "Completar",
    reject: "Rechazar",
    rejected: "Rechazado",
    pending: "Pendiente",
    planning: "En Planeación",
    created: "Creado",
    sendHSEReview: "Enviado revisión HSE",
    sendElectricReview: "Enviado revisión área eléctrica",
    sendTechnicalReview: "Enviado revisión área técnica",
    sended: "Enviado",
    hseReview: "Revisión HSE",
    sfReview: "Revisión SF",
    inVerification: "En verificación",
    inAAVerification: "En verificación de AA",
    programmed: "Programado",
    programmingCompleted: "Programación completada",
    programmedML: "Programado ML",
    "checked-st": "Revisado ST",
    "checked-hse": "Revisado HSE",
    "second-checking": "Segunda Revisión",
    "checked-st2": "Revisado ST2",
    scheduled: "Programado",
    "scheduled-completed": "Programado Completado",
    "executed-pending": "Ejecución Pendiente de Validación",
    "executed-approved": "Ejecución Aprobada",
    canceled: "Cancelado",
    stRefused: "Rechazado por ST",
    suspended: "Suspendido",
    pending: "Pendiente",
    securityCode_expired: "Código de seguridad incorrecto, por favor intente de nuevo",
    lowArReview: "Revisión AR bajo",
    highArReview: "Revisión AR alto",
    arApproved: "AR aprobado",
    authorized: "Autorizado",
    authorizedWorkDay: "Autorizado día de trabajo",
    permissionExtension: "Extensión de permiso",
    closureActivity: "Cierre de actividad",
    finished: "Terminado",
    createdBy: "Creado por",
    deleteTitle: "¿Estás seguro de eliminar este registro?",
    deleteMessage: "¡Atención! Estás a punto de eliminar un registro. Esta acción es irreversible. ¿Estás seguro de que deseas proceder con la eliminación? Por favor, confirma tu elección para continuar o cancela para mantener el registro."
};

//Merge Modules
import security from 'security/.config/lan/es'
import admin from 'modules/admin/.config/lan/es';
import dashboard from 'modules/dashboard/.config/lan/es';
import track from 'modules/track/.config/lan/es';

locales = {
    ...locales,
    ...admin,
    ...dashboard,
    ...track,
    ...security,
};

export default locales;