import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Button, Dropdown, Menu, Tag, Select, DatePicker, Input } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditSeguimiento from "../../components/editSeguimiento";
import SidePanelFilters from "components/SidePanelFilters";

import { validateRoles } from "config/helpers/Utils";

import { seguimientoListSearch, editSeguimientoOpen, getCatalog, editSeguimientoSave, detalleSeguimientoDetalleSetPrevPage } from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";
import moment from "moment";


const SeguimientoListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();
  const [statusFilter, setStatusFilter] = useState(null)
  const [otherFilters, setOtherFilters] = useState({})

  const { user } = useSelector((state) => state.authRedux);
  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.seguimientosListRedux);
  const { clients, loadingClients} = useSelector((state) => state.commonRedux);

  //Inicio del componente
  useEffect(() => {
    dispatch(getCatalog(["clients", "roles"]));
    tableviewRef.current?.refresh();
  }, []);
  
  //definicion de columnas Tabla
  const columns = useMemo(() => [
    {
      title: messages["seguimientosList_order"],
      dataIndex: "Orden",
      key: "Orden",
      render: (v, values) => <Link onClick={() => dispatch(detalleSeguimientoDetalleSetPrevPage("seguimientos"))} to={`/app/track/seguimientosDetail/${values?.IdSeguimiento}`} >{v}</Link>
    },
    {
      title: messages["seguimientosList_client"],
      dataIndex: "Client",
      key: "Client",
      render: (v, values) => <span>{values?.ClienteInfo?.RazonSocial}</span>
    },
    {
      title: messages["seguimientosList_vehicle"],
      dataIndex: "Vehicle",
      key: "Vehicle",
      render: (v, values) => <span>{values?.CarroInfo?.Placa + " - " + values?.CarroInfo?.Marca + " " + values?.CarroInfo?.Modelo + " " + values?.CarroInfo?.Color}</span>
    },
    {
      title: messages["seguimientosList_originCity"],
      dataIndex: "CiudadOrigen",
      key: "CiudadOrigen",
      render: (v, values) => <span>{values?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() + values?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase()}</span>,
    },
    {
      title: messages["seguimientosList_destinyCity"],
      dataIndex: "CiudadDestino",
      key: "CiudadDestino",
      render: (v, values) => <span>{values?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() + values?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase()}</span>,
    },
    {
      title: messages["seguimientosList_startDate"],
      dataIndex: "FechaSalida",
      key: "FechaSalida",
      render: (val) => <span>{moment(val).format("lll")}</span>
    },
    /* {
      title: messages["seguimientosList_endDate"],
      dataIndex: "FechaLlegada",
      key: "FechaLlegada",
      render: (val) => <span>{moment(val).format("lll")}</span>
    }, */
    {
      title: messages["seguimientosList_driverName"],
      dataIndex: "NombreCond",
      key: "NombreCond",
    },
    {
      title: messages["seguimientosList_accompanist"],
      dataIndex: "Acompanante",
      key: "Acompanante",
      render: (val, values) => <span>{(values?.AcompananteInfo?.Nombre || "") + " - " + (values?.AcompananteInfo?.Cedula || "")}</span>
    },
    {
      title: messages["seguimientosList_status"],
      dataIndex: "Estado",
      key: "Estado",
      render: (val, values) => {
        let colorTag = {
          1: "default",
          2: "geekblue",
          3: "volcano",
          4: "green"
        }
        return(
          <Tag color={colorTag[values?.EstadoInfo?.IdEstado]}>{values?.EstadoInfo?.NombreEstado}</Tag>
        )
      }
    },
    validateRoles([1], user) ? {
      key: "options",
      width: 50,
      render: (v, value) =>
         (
          <Dropdown
            menu={{items: [
              {
                label: messages["edit"],
                onClick: () => dispatch(editSeguimientoOpen(value.IdSeguimiento)),
                key: '1',
              },
              (validateRoles([1], user) ? {
                label: messages["delete"],
                onClick: () => dispatch(editSeguimientoSave({IdSeguimiento: value.IdSeguimiento, estado: 3, isDelete: true})),
                key: '2',
              } : null)
            ]}}
            placement="bottomCenter"
          >
            <MoreOutlined className="icon" />
          </Dropdown>
        ),
    } : {},
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(seguimientoListSearch(openSearch, limit, offset, statusFilter));
  });

  const onSearch = useCallback((openSearch, limit, offset) => {
    let filterBy = {
      ...otherFilters,
      fechaSalidaFrom: otherFilters?.FechaSalida? otherFilters.FechaSalida[0].format("YYYY-MM-DD") : undefined,
      fechaSalidaTo: otherFilters?.FechaSalida? otherFilters.FechaSalida[1].format("YYYY-MM-DD") : undefined,
    }
    dispatch(seguimientoListSearch(openSearch, limit, offset, statusFilter, filterBy));
  });

  const onClear = useCallback(() => {
    setOtherFilters({});
    dispatch(seguimientoListSearch('', 10,0, null));
  });

  const handleOtherFilters = (key) => (e) => {
    const filterBy = {
      ...otherFilters,
      [key]: e,
    }
    if(key === 'Placa'){
      //const plateRegex = /[aA-zZ]{3}[0-9]{3}/
      filterBy.Placa = e.target.value;
    }
    setOtherFilters(filterBy)
  }

  //console.log("Data: ", data.items)

  return (
    <div className="container">
      <HeaderPage title={messages["seguimientosList_title"]}>
        {validateRoles([1], user) && <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlinePlus" />}
            onClick={() => dispatch(editSeguimientoOpen())}
          >
            {messages["seguimientosList_add"]}
          </Button>
        </div>}
      </HeaderPage>

      <SidePanelFilters
        loading={loading}
        onRefreshTable={onSearch}
        onClear={onClear}
        searchTitle="Orden/Nombre Conductor/Manifiesto/Aseguradora"
      >
        <div className="col-12 mt-2">
          <div>{messages["seguimientosList_status"]}</div>
          <Select
            className="w-100"
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          >
                <Select.Option key={0} value={null}>
                  Todos
                </Select.Option>
                <Select.Option key={1} value={1}>
                  {messages['seguimientosList_filters_planned']}
                </Select.Option>
                <Select.Option key={2} value={2}>
                  {messages['seguimientosList_filters_active']}
                </Select.Option>
                <Select.Option key={3} value={3}>
                  {messages['seguimientosList_filters_cancelled']}
                </Select.Option>
                <Select.Option key={4} value={4}>
                  {messages['seguimientosList_filters_finished']}
                </Select.Option>
          </Select>
        </div>
        <div className="col-12 mt-2">
            <div>{messages["seguimientosList_vehiclePlate"]}</div>
            <Input maxLength={6} value={otherFilters?.Placa || ''} onChange={handleOtherFilters('Placa')}/>
        </div>
        <div className="col-12 mt-2">
          <div>{messages["seguimientosList_startDate"]}</div>
          <DatePicker.RangePicker  className="w-100" value={otherFilters?.FechaSalida} onChange={handleOtherFilters('FechaSalida')}/>
        </div>
        {validateRoles([1], user) && <div className="col-12 mt-2">
          <div>{messages["seguimientosList_client"]}</div>
          <Select
            className="w-100"
            value={otherFilters?.IdCliente}
            onChange={handleOtherFilters('IdCliente')}
            loading={loadingClients}
          >
              <Select.Option key={0} value={null} />
              {clients &&
                clients.map((client, index) => {
                  return (
                    <Select.Option key={index} value={client.IdCliente}>
                      {client.RazonSocial}
                    </Select.Option>
                  );
              })}
          </Select>
        </div>}
      </SidePanelFilters>

      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            Orden: value?.Orden,
            Empresa: value?.ClienteInfo?.RazonSocial,
            Carro: value?.CarroInfo?.Placa + " - " + value?.CarroInfo?.Marca + " " + value?.CarroInfo?.Modelo + " " + value?.CarroInfo?.Color,
            "Ciudad Origen": value?.CiudadOrigenInfo?.CityName.charAt(0).toUpperCase() + value?.CiudadOrigenInfo?.CityName.slice(1).toLowerCase(),
            "Ciudad Destino": value?.CiudadDestinoInfo?.CityName.charAt(0).toUpperCase() + value?.CiudadDestinoInfo?.CityName.slice(1).toLowerCase(),
            "Fecha de Salida": moment(value?.FechaSalida).format("lll"),
            "Fecha de Llegada": moment(value?.FechaLlegada).format("lll"),
            "Nombre del Conductor": value?.NombreCond,
            "Escolta": value?.AcompananteInfo?.Nombre + " - " + value?.AcompananteInfo?.Cedula,
            Estado: value?.estado == 0 ? "Inactivo" : "Activo",
          };
        })}
      />

      <EditSeguimiento
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
    </div>
  );
};

export default SeguimientoListView;
