//Global keys
let locales = {
};

//Merge Modules
import dashboard from '../../views/main/lan/es';

locales = {
    ...locales,
    ...dashboard
};

export default locales;