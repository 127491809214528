//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    USER_LIST_SEARCH
} from 'config/redux/actionTypes';

//actions
import {
    userListSearchResult,
    clearRedux
} from 'config/redux/actions';


function* userListSearchRequest() {
    yield takeEvery(USER_LIST_SEARCH, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, status   } = payload;
            let data = yield call(
                clientQuery,
                `query searchUsers($openSearch: String, $limit: Int, $offset: Int, $status: Int){
                    users: searchUsers(openSearch: $openSearch, limit: $limit, offset: $offset, status: $status) {
                        count
                            items{
                            IdUsuario
                            Nombre
                            Correo
                            Identificacion
                            Password
                            Telefono
                            ClienteInfo {
                                RazonSocial
                            }
                            Cargo
                            Ciudad {
                                CityCode
                                CityName
                            }
                            estado
                            RolInfo {
                                IdRol
                                Rol
                            }
                            UsuarioCreacionInfo {
                                Nombre
                            }
                        }
                    }
                 }`,
                {
                    openSearch,
                    limit,
                    offset,
                    status,
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(userListSearchResult(data.users || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/usersList/redux/saga', 'userListSearchRequest');
            yield put(userListSearchResult([]));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(userListSearchRequest)
    ]);
}
