//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    VEHICLE_DETAIL_GET_DETAIL,
} from 'config/redux/actionTypes';

//actions
import {
    vehicleDetailGetDetailResult,
} from 'config/redux/actions';


function* vehicleDetailGetDetailRequest() {
    yield takeEvery(VEHICLE_DETAIL_GET_DETAIL, function* ({ payload }) {
        try {
            const { id } = payload;

            const response = yield call(
                clientQuery,
                `query getVehicleById($id: Int){
                    vehicle: getVehicleById(id: $id){
                        IdCarro
                        IdCliente
                        ClienteInfo{
                            RazonSocial
                            Nit
                        }
                        Placa
                        Marca
                        Clase
                        Modelo
                        Color
                        Capacidad
                        Contenedor
                        Seriales
                        isAccompanist
                        estado
                        FechaCreacion
                        UsuarioCreacion
                    }
                }`,
                {
                    id: parseInt(id)
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(vehicleDetailGetDetailResult({result: response?.vehicle}));
        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/vehicleDetail/redux/sagas', 'vehicleDetailGetDetailRequest');
            yield put(vehicleDetailGetDetailResult({error: exc.error}));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(vehicleDetailGetDetailRequest),
    ]);
}