import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";

import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import HeaderPage from "containers/headerPage";
import { Icons } from "components";
import {
  Card,
  Tabs,
  Button,
  Form,
  Input,
  Checkbox,
  Spin,
  notification,
  Tag,
  Dropdown,
  Menu,
  Tooltip,
  Breadcrumb,
  Modal,
  DatePicker,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";

import moment from "moment";

import EditDetalleSeguimiento from "./components/editDetalleSeguimiento";
import EditSeguimiento from "../../components/editSeguimiento";
import SeguimientoFiles from "./components/files";

import { validateRoles } from "config/helpers/Utils";

import TableView from "components/TableView";
import SidePanel from "components/SidePanel";

import {
  detalleSeguimientoDetailGetDetalleSeguimiento,
  editDetalleSeguimientoOpen,
  detalleSeguimientoDetalleGetDetalles,
  editSeguimientoOpen,
  editDetalleSeguimientoSave,
  editSeguimientoSave,
  deleteDetalleSeguimiento,
  clearRedux,
} from "config/redux/actions";

import "./style.css";
import DeleteAlert from "../../../../components/DeleteAlert";

const DetailView = ({ history }) => {
  const dispatch = useDispatch();
  const tableviewRef = useRef();
  const deleteModalRef = useRef();
  const { id } = useParams();
  const { messages } = useIntl();
  const { loading, data, dataDetails, loadingDetails, prevPage, resultDelete } = useSelector(
    (state) => state.seguimientosDetailRedux
  );
  const [showFinishModal, setShowFinishModal] = useState(false);

  const { loading: loadingUpdate, result} = useSelector(
    (state) => state.editSeguimientoRedux
  );
  const { user } = useSelector((state) => state.authRedux);

  useEffect(() => {
    dispatch(detalleSeguimientoDetailGetDetalleSeguimiento(id));
    tableviewRef.current?.refresh();
    return () => {
      dispatch(clearRedux("DETALLESEGUIMIENTO_DETAIL", { data: null }));
    };
  }, [result]);

  useEffect(() => {
    if (result !== null) setShowFinishModal(false);
  }, [result]);

  useEffect(() => {
    if (resultDelete) {
      if (resultDelete?.isSuccess) {
        notification.success({
          message: "Bien!!",
          description: "Registro eliminado exitosamente!!",
        });
        tableviewRef.current?.refresh();
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [resultDelete]);

  let colorTag = {
    1: "default",
    2: "geekblue",
    3: "volcano",
    4: "green",
  };
  const columns = useMemo(() => [
    {
      title: messages["seguimientoDetail_tabledetails_user"],
      dataIndex: "Usuario",
      key: "Usuario",
      render: (v, values) => <span>{values?.UsuarioCreacionInfo?.Nombre || data?.AcompananteInfo?.Nombre || ''}</span>,
    },
    {
      title: messages["seguimientoDetail_tabledetails_creationDate"],
      dataIndex: "FechaHoraReporte",
      key: "FechaHoraReporte",
      render: (v, values) => <span>{moment(v).format("lll")}</span>,
    },
    {
      title: messages["seguimientoDetail_tabledetails_reportPoint"],
      dataIndex: "PuntoReporte",
      key: "PuntoReporte",
    },
    {
      title: messages["seguimientoDetail_tabledetails_withoutNovedad"],
      dataIndex: "SN",
      key: "SN",
      render: (v, values) => {
        let tag =
          v == 1 ? (
            <Tag color={"success"}>Sin Novedad</Tag>
          ) : (
            <Tag color={"volcano"}>Con Novedad</Tag>
          );
        return tag;
      },
    },
    {
      title: messages["seguimientoDetail_tabledetails_observation"],
      dataIndex: "Observacion",
      key: "Observacion",
    },
    {
      key: "options",
      width: 50,
      render: (v, value) => (
        validateRoles([1,2], user) && <Dropdown
          menu={{items: [
            {
              label: messages["edit"],
              onClick: () => dispatch(editDetalleSeguimientoOpen(value.IdDetSeguimiento)),
              disabled: data?.IdEstado == 3 || data?.IdEstado == 4,
              key: '1',
            },
            {
              label: messages["delete"],
              onClick: () => deleteModalRef?.current?.open(value),
              disabled: data?.IdEstado == 3 || data?.IdEstado == 4,
              key: '2',
            },
            (value?.Files?.length || (value?.Latitude && value?.Longitude) ? {
              label: messages["seguimientoDetail_resources"],
              onClick: () => dispatch(editDetalleSeguimientoOpen(value.IdDetSeguimiento, true)),
              key: '3',
            } : null)
          ] }}
          placement="bottomCenter"
        >
          <MoreOutlined className="icon" />
        </Dropdown>
      ),
    },
  ]);

  const handleDeleteDetalleSeguimiento = async (value) => {
    await dispatch(deleteDetalleSeguimiento(value.IdDetSeguimiento))
  }

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(
      detalleSeguimientoDetalleGetDetalles(
        openSearch,
        limit,
        offset,
        parseInt(id)
      )
    );
  });

  return (
    <div className="container">
      <HeaderPage
        title={
          messages["seguimientoDetail_title"] + ` - ${data?.Orden || "..."}`
        }
      >
       {validateRoles([1], user) && <div>
          {data?.IdEstado !== 2 && (
            <Button
              className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
              icon={<Icons family="ai" name="AiOutlineEdit" />}
              onClick={() => dispatch(editSeguimientoOpen(parseInt(id)))}
            >
              {messages["seguimientoDetail_titleEdit"]}
            </Button>
          )}
        </div>}
      </HeaderPage>
      <Breadcrumb className="mb-5">
        {prevPage === "dashboard" ? (
          <Breadcrumb.Item>
            <Link to={"/app/dashboard/main"}>
              <Icons family="md" name="MdDashboard" className="icon" />
              <span className="ms-1">Dashboard</span>
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item>
            <Link to={"/app/track/seguimientosList"}>
              <Icons family="md" name="MdTrendingUp" className="icon" />
              <span className="ms-1">Seguimientos</span>
            </Link>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>
          <span>Detalle de seguimiento</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loadingDetails || loading}>
        <Tabs
          size="large"
          type="card"
          defaultActiveKey="1"
          items={[
            {
              label: messages["seguimientoDetail_title"],
              key: "1",
              children: (
                <TrackingDetails
                  messages={messages}
                  data={data}
                  dispatch={dispatch}
                  setShowFinishModal={setShowFinishModal}
                  tableviewRef={tableviewRef}
                  dataDetails={dataDetails}
                  loadingDetails={loadingDetails}
                  columns={columns}
                  onRefreshTable={onRefreshTable}
                  user={user}
                />
              ),
            },
            {
              label: messages["seguimientoDetail_title_generalInfo"],
              key: "2",
              children: (
                <GeneralInfo
                  data={data}
                  messages={messages}
                  colorTag={colorTag}
                />
              ),
            },
            {
              label: messages["seguimientoDetail_tabFiles"],
              key: "3",
              children: (
                <SeguimientoFiles seguimiento={data} />
              ),
            },
          ]}
        />
      </Spin>
      <Modal
        title={messages["seguimientoDetail_titleFinish"]}
        open={showFinishModal}
        onOk={() => {}}
        onCancel={() => setShowFinishModal(false)}
        width={800}
        footer={null}
        maskClosable={false}
      >
        <Card>
          <Form
            layout="vertical"
            onFinish={(values) => {
              dispatch(
                editSeguimientoSave({
                  IdSeguimiento: data?.IdSeguimiento,
                  IdEstado: 4,
                  ...values,
                })
              );
            }}
          >
            <div className="row">
              <div className="col-12">
                <p>{messages["seguimientoDetail_titleFinish_message"]}</p>
              </div>

              <Form.Item
                name="FechaLlegada"
                label={messages["editSeguimiento_endDate"]}
                rules={[
                  {
                    required: true,
                    message: messages["required_field"],
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button
                loading={loadingUpdate}
                type="primary"
                className="btn btn-alternate btn-end"
                onClick={() => setShowFinishModal(false)}
              >
                {messages["cancel"]}
              </Button>
              <Button
                loading={loadingUpdate}
                type="primary"
                htmlType="submit"
                className="btn btn-primary btn-end"
              >
                {messages["save"]}
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
      <EditDetalleSeguimiento
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
        idSeguimiento={id}
      />
      <EditSeguimiento
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
      <DeleteAlert ref={deleteModalRef} onDelete={handleDeleteDetalleSeguimiento}/>
    </div>
  );
};

export default DetailView;

const TrackingDetails = ({
  messages,
  data,
  dispatch,
  setShowFinishModal,
  tableviewRef,
  dataDetails,
  loadingDetails,
  columns,
  onRefreshTable,
  user
}) => (
  <>
    <div className="row">
      <div className="col-12">
        <Card style={{ borderTopColor: "transparent" }}>
          {validateRoles([1,2], user) && <div className="container">
            <Tooltip
              title={
                data?.IdEstado == 3 || data?.IdEstado == 4
                  ? messages["editDetalleSeguimiento_seguimientoInactive"]
                  : ""
              }
            >
              <div className="d-inline-block">
                <Button
                  className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
                  icon={<Icons family="ai" name="AiOutlinePlus" />}
                  onClick={() => dispatch(editDetalleSeguimientoOpen())}
                  disabled={data?.IdEstado == 3 || data?.IdEstado == 4}
                >
                  {messages["seguimientoDetail_titleAdd"]}
                </Button>
              </div>
            </Tooltip>
            {data?.IdEstado !== 4 && (
              <Button
                className="btn btn-outline-danger btn-icon btn-icon-start mt-2 ms-2 w-md-auto"
                icon={<Icons family="ai" name="AiOutlineClose" />}
                onClick={() => setShowFinishModal(true)}
              >
                {messages["seguimientoDetail_titleFinish"]}
              </Button>
            )}
          </div>}
          <TableView
            ref={tableviewRef}
            items={dataDetails?.items}
            loading={loadingDetails}
            columns={columns}
            count={dataDetails?.count}
            scroll={{ x: 1050 }}
            onRefreshTable={onRefreshTable}
            gridDataForPrinting={dataDetails?.items?.map((value) => {
              return {
                Usuario: value?.UsuarioCreacionInfo?.Nombre,
                "Fecha de Creación": moment(value?.FechaHoraReporte).format(
                  "lll"
                ),
                "Punto de Reporte": value?.PuntoReporte,
                Novedad: value?.SN == 0 ? "Con novedad" : "Sin novedad",
                Observación: value?.Observacion,
              };
            })}
          />
        </Card>
      </div>
    </div>
  </>
);

const GeneralInfo = ({ data, messages, colorTag }) => (
  <>
    <Card className="mb-3 pt-3" style={{ borderTopColor: "transparent" }}>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-4 col-md-12 mb-4 col-sm-10 col-12">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title mb-3">
                  {messages["seguimientoDetail_title_generalInfo"]}
                </h3>
                <div className="card-info row justify-content-center align-items-center flex-xl-column">
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdDomain"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_client"]}:
                      </p>
                    </div>
                    <p className="m-0">{data?.ClienteInfo?.RazonSocial}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdOutlineSummarize"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_order"]}:
                      </p>
                    </div>
                    <p className="m-0">{data?.Orden}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdChromeReaderMode"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_manifest"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Manifiesto}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdOutlineSecurity"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_insurance"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Aseguradora}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdOutlineDateRange"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_creationDate"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">
                      {moment(data?.FechaCreacion).format("lll")}
                    </p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdPersonPin"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_creationUser"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.UsuarioCreacionInfo?.Nombre}</p>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdTrendingUp"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_status"]}:{" "}
                      </p>
                    </div>
                    <Tag color={colorTag[data?.EstadoInfo?.IdEstado]}>
                      {data?.EstadoInfo?.NombreEstado}
                    </Tag>
                  </div>
                  <div className="my-2 d-flex gap-2 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdDataExploration"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_link"]}:{" "}
                      </p>
                    </div>
                    <a href={data?.Link} target="blank">
                      {data?.Link}
                    </a>
                  </div>
                  <div className="my-2 d-flex flex-column gap-1 col-xl-12 col-lg-4 col-md-6 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdOutlineHideSource"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_restriction"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Restriccion}</p>
                  </div>
                  <div className="my-2 d-flex flex-column gap-1 col-xl-12 col-lg-4 col-md-12 col-12">
                    <div className="d-flex align-items-center gap-1">
                      <Icons
                        family="md"
                        name="MdInsertComment"
                        className="icon text-cyan"
                      />
                      <p className="m-0 text-cyan">
                        {messages["seguimientoDetail_observation"]}:{" "}
                      </p>
                    </div>
                    <p className="m-0">{data?.Observacion}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-12 col-sm-10 col-12">
            <div className="row h-100">
              <div className="col-xl-6 col-md-6">
                <div className="card h-100">
                  <div className="card-body">
                    <h3 className="card-title mb-3">
                      {messages["seguimientoDetail_title_track"]}
                    </h3>
                    <div className="card-info">
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdLocationCity"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_originCity"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {data?.CiudadOrigenInfo?.CityName.charAt(
                            0
                          ).toUpperCase() +
                            data?.CiudadOrigenInfo?.CityName.slice(
                              1
                            ).toLowerCase()}
                        </p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdLocationCity"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_destinyCity"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {data?.CiudadDestinoInfo?.CityName.charAt(
                            0
                          ).toUpperCase() +
                            data?.CiudadDestinoInfo?.CityName.slice(
                              1
                            ).toLowerCase()}
                        </p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdOutlineMap"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_kilometers"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.Kilometros}</p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdAltRoute"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_route"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.Ruta}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="card h-100">
                  <div className="card-body">
                    <h3 className="card-title mb-3">
                      {messages["seguimientoDetail_title_seguimientoDates"]}
                    </h3>
                    <div className="card-info">
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdOutlineDateRange"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_startDate"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {moment(data?.FechaSalida).format("lll")}
                        </p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdOutlineDateRange"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_endDate"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {data?.FechaLlegada !== null
                            ? moment(data?.FechaLlegada).format("lll")
                            : "Aún no ha llegado"}
                        </p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdAccessTime"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_estimatedTime"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.TiempoEstimado}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="card mt-3">
                  <div className="card-body">
                    <h3 className="card-title  mb-3">
                      {messages["seguimientoDetail_title_driverInfo"]}
                    </h3>
                    <div className="card-info">
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdPerson"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_driverName"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.NombreCond}</p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdPhone"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_driverPhone"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.CelularCond}</p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdDirectionsCar"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_vehicle"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {data?.CarroInfo?.Placa +
                            " - " +
                            data?.CarroInfo?.Marca +
                            " " +
                            data?.CarroInfo?.Modelo +
                            " " +
                            data?.CarroInfo?.Color}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="card mt-3">
                  <div className="card-body">
                    <h3 className="card-title mb-3">
                      {messages["seguimientoDetail_title_accompanistInfo"]}
                    </h3>
                    <div className="card-info">
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdPerson"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_driverName"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.AcompananteInfo?.Nombre}</p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdPhone"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_driverPhone"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">{data?.AcompananteInfo?.Celular}</p>
                      </div>
                      <div className="my-2 d-flex align-items-start gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <Icons
                            family="md"
                            name="MdDirectionsCar"
                            className="icon text-cyan"
                          />
                          <p className="m-0 text-cyan">
                            {messages["seguimientoDetail_accompanistVehicle"]}:{" "}
                          </p>
                        </div>
                        <p className="m-0">
                          {data?.AcompananteInfo?.VehiculoInfo?.Placa +
                            " - " +
                            data?.AcompananteInfo?.VehiculoInfo?.Marca +
                            " " +
                            data?.AcompananteInfo?.VehiculoInfo?.Modelo +
                            " " +
                            data?.AcompananteInfo?.VehiculoInfo?.Color}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </>
);
