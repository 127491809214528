//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO,
    DETALLESEGUIMIENTO_DETAIL_GET_DETALLES,
    DELETE_DETALLESEGUIMIENTO
} from 'config/redux/actionTypes';

//actions
import {
    detalleSeguimientoDetailGetDetalleSeguimientoResult,
    detalleSeguimientoDetalleGetDetallesResult,
    deleteDetalleSeguimientoResult
} from 'config/redux/actions';


function* detalleSeguimientoDetailGetDetalleSeguimientoRequest() {
    yield takeEvery(DETALLESEGUIMIENTO_DETAIL_GET_DETALLESEGUIMIENTO, function* ({ payload }) {
        try {
            const { id } = payload;

            const response = yield call(
                clientQuery,
                `query getSeguimientoById($id: Int){
                    seguimiento: getSeguimientoById(id: $id){
                        IdSeguimiento
                        IdCliente
                        ClienteInfo{
                            RazonSocial
                            Nit
                        }
                        IdCarro
                        CarroInfo{
                            Placa
                            Marca
                            Modelo
                            Color
                        }
                        IdCiudadOrigen
                        CiudadOrigenInfo{
                            CityCode
                            CityName
                        }
                        IdCiudadDestino
                        CiudadDestinoInfo{
                            CityCode
                            CityName
                        }
                        Orden
                        Manifiesto
                        Aseguradora
                        Ruta
                        Kilometros
                        TiempoEstimado
                        Restriccion
                        Observacion
                        Link
                        FechaSalida
                        FechaLlegada
                        IdEstado
                        EstadoInfo{
                            IdEstado
                            NombreEstado
                        }
                        NombreCond
                        CelularCond
                        IdAcompanante
                        AcompananteInfo{
                            Nombre
                            Cedula
                            Celular
                            VehiculoInfo{
                                Placa
                                Marca
                                Clase
                                Modelo
                                Color
                            }
                        }
                        FechaCreacion
                        UsuarioCreacionInfo{
                            Nombre
                        }
                        
                    }
                }`,
                {
                    id: parseInt(id)
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(detalleSeguimientoDetailGetDetalleSeguimientoResult(response.seguimiento));
        } catch (exc) {
            ExceptionManager(exc, 'modules/track/views/seguimientosDetail/redux/sagas', 'detalleSeguimientoDetailGetDetalleSeguimientoRequest');
            yield put(detalleSeguimientoDetailGetDetalleSeguimientoResult(exc.error));
        }
    });
}

function* detalleSeguimientoDetalleGetDetalles() {
    yield takeEvery(DETALLESEGUIMIENTO_DETAIL_GET_DETALLES, function* ({ payload }) {
        try{

            const { openSearch, limit, offset, id } = payload;

            const response = yield call(
                clientQuery,
                `query searchDetalleSeguimientos(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int
                    $id: Int
                    ){
                    detalle: searchDetalleSeguimientos(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        id: $id
                    ){
                        count
                        items{
                            IdDetSeguimiento
                            IdSeguimiento
                            PuntoReporte
                            SN
                            Observacion
                            FechaHoraReporte
                            UsuarioCreacionInfo{
                                Nombre
                            }
                            Files {
                                id
                            }
                            Longitude
                            Latitude
                        }
                    }
                }`,
                {
                    openSearch, limit, offset, id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(detalleSeguimientoDetalleGetDetallesResult(response.detalle));
        } catch (exc) {
            ExceptionManager(exc, 'modules/track/views/seguimientosDetail/redux/sagas', 'detalleSeguimientoDetalleGetDetalles');
            yield put(detalleSeguimientoDetalleGetDetallesResult(exc.error));
        }
    })
}

function* deleteDetalleSeguimientoRequest() {
    yield takeEvery(DELETE_DETALLESEGUIMIENTO, function* ({ payload }) {
        try {
            const { idDetSeguimiento } = payload;
            const result = yield call(
                clientMutation,
                `mutation deleteDetalleSeguimiento(
                    $idDetSeguimiento: Int!
                ){
                    result: deleteDetalleSeguimiento(
                        idDetSeguimiento: $idDetSeguimiento
                    )
                }`,
                {
                    idDetSeguimiento
                },
                endpoints.GRAPHQL_GENERAL
              );

            yield put(deleteDetalleSeguimientoResult({isSuccess: true}))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/seguimientosDetail/redux/saga', 'deleteDetalleSeguimientoRequest');
            yield put(deleteDetalleSeguimientoResult({isError: true, error: exc.error }));
        }
    });
}
//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(detalleSeguimientoDetailGetDetalleSeguimientoRequest),
        fork(detalleSeguimientoDetalleGetDetalles),
        fork(deleteDetalleSeguimientoRequest),
    ]);
}