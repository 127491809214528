//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    ACCOMPANIST_LIST_SEARCH
} from 'config/redux/actionTypes';

//actions
import {
    accompanistListSearchResult,
    clearRedux
} from 'config/redux/actions';


function* accompanistListSearchRequest() {
    yield takeEvery(ACCOMPANIST_LIST_SEARCH, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, status } = payload;
            let data = yield call(
                clientQuery,
                `query searchAccompanists(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $status: Int){
                    accompanists: searchAccompanists(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        status: $status
                    ){
                        count
                            items{
                            IdAcompanante
                            Nombre
                            Cedula
                            Celular
                            VehiculoInfo{
                                Placa
                                Marca
                                Modelo
                                Color
                            }
                            estado
                            UsuarioCreacionInfo {
                                Nombre
                            }
                        } 
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    status
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(accompanistListSearchResult(data.accompanists || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/accompanistsList/redux/saga', 'accompanistListSearchRequest');
            yield put(accompanistListSearchResult([]));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(accompanistListSearchRequest)
    ]);
}
