//imports
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

//views
import seguimientosListView from './views/seguimientosList';
import seguimientosDetailView from './views/seguimientosDetail';
//others
import { validateRoles } from 'config/helpers/Utils'
//router
const adminRouter = ({ match }) => {
  const { user } = useSelector(state => state.authRedux)

    return (
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/seguimientoslist`} />
            {
              // validateRoles([1], user) &&
                <Route path={`${match.url}/seguimientoslist`} component={seguimientosListView} />
            }
            {
              // validateRoles([1], user) &&
                <Route path={`${match.url}/seguimientosdetail/:id`} component={seguimientosDetailView} />
            }
            <Redirect to="/error" />
        </Switch>
    );
};

export default adminRouter
