import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { Button, Dropdown, Menu, Tag, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Icons } from "components";

import HeaderPage from "containers/headerPage";
import TableView from "components/TableView";
import EditClient from "../../components/editClient";
import ExcelUpload from "../../components/excelUpload";
import SidePanelFilters from "components/SidePanelFilters";

import { validateRoles } from "config/helpers/Utils";

import { clientListSearch, editClientOpen, getCatalog, editClientSave } from "config/redux/actions";

import { excelUploadOpen } from "config/redux/actions";

const ClientListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const tableviewRef = useRef();
  const [statusFilter, setStatusFilter] = useState(1)


  const { user } = useSelector((state) => state.authRedux);
  //obtener la data del redux
  const { loading, data } = useSelector((state) => state.clientListRedux);

  //Inicio del componente
  useEffect(() => {
    tableviewRef.current?.refresh();
  }, []);
  
  //definicion de columnas Tabla
  const columns = useMemo(() => [
    {
      title: messages["clientList_socialReason"],
      dataIndex: "RazonSocial",
      key: "RazonSocial",
      width: 100,
    },
    {
      title: messages["clientList_nit"],
      dataIndex: "Nit",
      key: "Nit",
    },
    {
      title: messages["clientList_phone"],
      dataIndex: "Telefono",
      key: "Telefono",
    },
    {
      title: messages["clientList_address"],
      dataIndex: "Direccion",
      key: "Direccion",
    },
    {
      title: messages["clientList_email"],
      dataIndex: "Correo",
      key: "Correo",
    },
    {
      title: messages["clientList_country"],
      dataIndex: "PaisId",
      key: "PaisId",
      render: (v) => <span>{v == 170 ? "Colombia" : v}</span>,
      width: 150,
    },
    {
      title: messages["clientList_city"],
      dataIndex: "Ciudad",
      key: "Ciudad",
      render: (v, values) => <span>{values?.Ciudad?.CityName.charAt(0).toUpperCase() + values?.Ciudad?.CityName.slice(1).toLowerCase()}</span>,
    },
    {
      title: messages["clientList_status"],
      dataIndex: "estado",
      key: "estado",
      render: (val, values) =>
        (values.estado > 0) ? (
          <Tag color="blue">Activo</Tag>
        ) : (
          <Tag color="red">Inactivo</Tag>
        ),
    },
    {
      title: messages["createdBy"],
      dataIndex: "UsuarioCreacionInfo",
      key: "createdBy",
      render: (val, values) => val?.Nombre
    },
    {
      key: "options",
      width: 50,
      render: (v, value) =>
        /* validateRoles([1], user) && */ (
          <Dropdown
            overlay={() => (
              <Menu>
                {value.estado === 1 && (
                  <Menu.Item key="mu1">
                    <Button
                      type="text"
                      onClick={() => dispatch(editClientOpen(value.IdCliente))}
                    >
                      {messages["edit"]}
                    </Button>
                  </Menu.Item>
                )}

                <Menu.Item key="mu2">
                  <Button 
                  type="text"
                  onClick={() => dispatch(editClientOpen(value.IdCliente, true))}
                  >{messages["delete"]}</Button>

                </Menu.Item>
              </Menu>
            )}
            placement="bottomCenter"
          >
            <MoreOutlined className="icon" />
          </Dropdown>
        ),
    },
  ]);

  const onRefreshTable = useCallback((openSearch, limit, offset) => {
    dispatch(clientListSearch(openSearch, limit, offset, statusFilter));
  });
  const onSearch = useCallback((openSearch, limit, offset) => {
    dispatch(clientListSearch(openSearch, limit, offset, statusFilter));
  });

  const onClear = useCallback(() => {
    dispatch(clientListSearch('', 50,0, 1));
  });


  return (
    <div className="container">
      <HeaderPage title={messages["clientList_title"]}>
        <div>
          <Button
            className="btn btn-outline-primary btn-icon btn-icon-start mt-2 ms-2 w-100 w-md-auto"
            icon={<Icons family="ai" name="AiOutlinePlus" />}
            onClick={() => dispatch(editClientOpen())}
          >
            {messages["clientList_add"]}
          </Button>
        </div>
      </HeaderPage>

      <SidePanelFilters
        loading={loading}
        onRefreshTable={onSearch}
        onClear={onClear}
        searchTitle="Razón Social/Nit/Email"
      >
        <div className="col-12 mt-2">
          <div>{messages["clientList_status"]}</div>
          <Select
            className="w-100"
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          >
                <Select.Option key={1} value={1}>
                  Activo
                </Select.Option>
                <Select.Option key={2} value={0}>
                  Inactivo
                </Select.Option>
          </Select>
        </div>
      </SidePanelFilters>

      <TableView
        ref={tableviewRef}
        items={data.items}
        loading={loading}
        columns={columns}
        count={data.count}
        scroll={{ x: 1050 }}
        onRefreshTable={onRefreshTable}
        gridDataForPrinting={data?.items?.map((value) => {
          return {
            RazonSocial: value.RazonSocial,
            Nit: value.Nit,
            Telefono: value.Telefono,
            Direccion: value.Direccion,
            Correo: value.Correo,
            PaisId: value.PaisId == 170 ? "Colombia" : value.PaisId,
            Ciudad: value?.Ciudad?.CityName.charAt(0).toUpperCase() + value?.Ciudad?.CityName.slice(1).toLowerCase(),
            estado: value.estado == 0 ? "Inactivo" : "Activo",
          };
        })}
      />

      <EditClient
        onClose={(refresh) => refresh && tableviewRef.current.refresh()}
      />
      <ExcelUpload refresh={() => tableviewRef.current?.refresh()} />
    </div>
  );
};

export default ClientListView;
