import React, { Fragment, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router-dom"

import { htmlInitialAttributes } from 'config/constants'
import {
    loginSingIn,
    recoverPasswordOpenClose,
    clearRedux
} from 'config/redux/actions'


//desing
import { Icons } from 'components'
import { Form, Input, Button, Checkbox, Alert } from 'antd';
import RecoverPassword from './components/recoverPassword';

import './login.scss'

const LoginView = ({ history }) => {

    const { messages } = useIntl();
    const dispatch = useDispatch()
    const { loading, result } = useSelector(state => state.loginRedux);
    const [errorMessage, setErrorMessage] = useState()
    const navigate = useHistory();
    //necesaro dejar los attributos basicos
    const html = document.querySelector('html');
    html.getAttributeNames().forEach(x => html.removeAttribute(x))
    Object.keys(htmlInitialAttributes).forEach(key => {
        if (htmlInitialAttributes[key])
            html.setAttribute(key, htmlInitialAttributes[key])
        else
            html.removeAttribute(key)
    });

    useEffect(() => {
        if (result) {
            //
            console.log("result", result)
            setErrorMessage(result.error.error)
            dispatch(clearRedux("LOGIN", { result: null }))
        }
    }, [result])

    return (
        <div className="h-100">

            <div className="fixed-background">
                <img src='assets/images/background/background_orion.jpg' />
            </div>

            <div className="container-fluid p-0 h-100 position-relative">
                <div className="row g-0 h-100">

                    <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
                        <div className="min-h-100 d-flex align-items-center">
                            <div className="w-100 w-lg-75 w-xxl-50">
                                <div>
                                    <div className="mb-5">
                                        <h1 className="display-3 text-white">ServiLogística Orion</h1>
                                    </div>
                                    <p className="h6 text-white lh-1-5 mb-5">
                                        Aplicación para el manejo de seguimiento de transporte con Escoltas, donde podremos realizar la gestion y uso de manera fácil y eficiente.
                                    </p>
                                    <div className="mb-5">
                                        <a className="btn btn-lg btn-outline-white" href="index.html">Leer Más</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                        <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                            <div className="sw-lg-50 px-5">
                                <div className="">
                                    <a href="index.html">
                                        <div className="logo-default"></div>
                                        <img src="/assets/images/logo/logo_full.jpg" alt="logo" style={{ width: '100%', height: 'auto' }} />
                                    </a>
                                </div>
                                <div className="mb-5">
                                    {/* <h2 className="cta-1 mb-0 text-primary">
                                        <FormattedMessage id='login_title' values={{ br: <br /> }} />
                                    </h2> */}
                                </div>
                                <div className="mb-5">
                                    <p className="h6">{messages['login_subtitle']}</p>
                                    <p className="h6">
                                        {messages['login_subtitle2']}
                                        <a href="register">{messages['login_register']}</a>
                                        .
                                    </p>
                                </div>
                                <div>
                                    <Form
                                        initialValues={{ remember: true }}
                                        onFinish={(values) => {
                                            setErrorMessage()
                                            dispatch(loginSingIn(values.email, values.password, navigate))
                                        }}
                                        //onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        className="tooltip-end-bottom"
                                    >
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: messages['required_field'] }]}
                                            className="mb-3"
                                        >
                                            <div className="filled form-group tooltip-end-top">
                                                <Icons family="fa" name="FaRegEnvelope" />
                                                <Input className="form-control" placeholder={messages['login_email']} />
                                            </div>
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: messages['required_field'] }]}
                                            className="mb-3"
                                        >
                                            <div className="filled form-group tooltip-end-top">
                                                <Icons family="fa" name="FaLock" />
                                                <Input.Password placeholder={messages['login_password']} />
                                                <Button  
                                                    type="link" 
                                                    className="text-small position-absolute t-2 e-0" 
                                                    onClick={() => {dispatch(recoverPasswordOpenClose(true))}}
                                                >
                                                    {messages['login_forgotPassword']}
                                                </Button>
                                            </div>
                                        </Form.Item>

                                        {/*                                         <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item> */}

                                        <Form.Item>
                                            <Button type="primary" loading={loading} htmlType='submit' className="btn btn-lg btn-primary">
                                                {messages['login_singin']}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    {
                                        errorMessage &&
                                        <Fragment>
                                            <Alert message={errorMessage} type="error" showIcon closable />
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <RecoverPassword/>
        </div>
    )
}

export default LoginView;