import { combineReducers } from 'redux';

import commonRedux from './common/reducer';
import themeRedux from './theme/reducer';
import containers from 'containers/.config/redux/reducers';
import security from 'security/.config/redux/reducers';
import admin from 'modules/admin/.config/redux/reducers';
import dashboard from 'modules/dashboard/.config/redux/reducers';
import track from 'modules/track/.config/redux/reducers';


const reducersModules = {
  commonRedux,
  themeRedux,
  ...containers,
  ...security,
  ...admin,
  ...dashboard,
  ...track
};

const reducers = combineReducers(reducersModules);

export default reducers;