//imports
import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Actions
import {
    authGetUserProfile
} from 'config/redux/actions';

//component
const AuthContainer = ({ children, history }) => {

    const dispatch = useDispatch()
    const { user, loading } = useSelector(state => state.authRedux);

    const authCheck = useCallback(() => {
        let token = localStorage.getItem('token');

        if (!user && token) {
            dispatch(authGetUserProfile(history));
        } else {
            if (!token) history.push('/login');
        }
    })

    useEffect(() => {
        authCheck();
    }, [])

    return (
        <Fragment>
            {/*
                loading &&
                <Loading full />
            */}
            {
                user && (
                    <Fragment>
                        {children}
                    </Fragment>
                )
            }
        </Fragment>
    );

}

//Export Component
export default AuthContainer
