import React from "react";

const HeaderPage = ({ title,subTitle, children }) => {

    return (
        <div className="page-title-container">
            <div className="row g-0">

                <div className="col-auto mb-3 mb-md-0 me-auto">
                    <div className="w-auto">
                        <a href="#" className="muted-link pb-1 d-inline-block breadcrumb-back">
                            <i data-cs-icon="chevron-left" data-cs-size="13"></i>
                            <span className="text-small align-middle">{subTitle}</span>
                        </a>
                        <h1 className="mb-0 pb-0 display-4" id="title">{title}</h1>
                    </div>
                </div>
                <div className="w-100 d-md-none"></div>
                <div className="col-12 col-sm-6 col-md-auto d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                    {
                        children
                    }
                </div>
            </div>
        </div>
    )
}

export default HeaderPage;