import {
    //TYPES
    EXCEL_UPLOAD_OPEN,
    EXCEL_UPLOAD_CLOSE,
    EXCEL_UPLOAD_SAVE,
    EXCEL_UPLOAD_SAVE_RESULT,
    EXCEL_UPLOAD_GET_USERS_SAVE,
    EXCEL_UPLOAD_GET_USERS_SAVE_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  isOpen: false,
  data: null,
  result: null,
  loadingPercent: null,
  users: [],
  loadingUsers: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case EXCEL_UPLOAD_OPEN:
            return { ...state, isOpen: true }

        case EXCEL_UPLOAD_CLOSE:
            return { ...state, ...INIT_STATE }

        case EXCEL_UPLOAD_SAVE:
            return { ...state, loading: true, result: null }

        case EXCEL_UPLOAD_SAVE_RESULT:
            return { ...state, loading: false, loadingPercent: action.payload.progress !== 100 ? action.payload.progress : null, result: action.payload.result }

        case EXCEL_UPLOAD_GET_USERS_SAVE:
          return {...state, loadingUsers: true}  
    
        case EXCEL_UPLOAD_GET_USERS_SAVE_RESULT:
          return {...state, users: action.payload, loadingUsers: false}


        case CLEAR_REDUX:
            return ["", "EXCEL_UPLOAD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
