let locales = {
    usersList_title: 'Usuarios',
    usersList_add: 'Agregar usuario',
    usersList_uploadExcel: 'Cargar por excel',
    usersList_name: 'Nombres y Apellidos',
    usersList_email: 'Email',
    usersList_identification: 'Número de identificación',
    usersList_phone: 'Teléfono',
    usersList_client: 'Empresa',
    usersList_position: 'Cargo',
    usersList_city: 'Ciudad',
    usersList_rol: 'Rol',
    usersList_status: 'Estado',
    usersList_password: 'Contraseña',
};

export default locales;