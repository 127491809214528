import {
    //TYPES
    EXCEL_UPLOAD_CONTRACTORS_OPEN,
    EXCEL_UPLOAD_CONTRACTORS_CLOSE,
    EXCEL_UPLOAD_CONTRACTORS_SAVE,
    EXCEL_UPLOAD_CONTRACTORS_SAVE_RESULT,
    EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE,
    EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE_RESULT
} from 'config/redux/actionTypes';

export const excelUploadContractorsOpen = () => ({
    type: EXCEL_UPLOAD_CONTRACTORS_OPEN,
    // payload: data
});

export const excelUploadContractorsClose = () => ({
    type: EXCEL_UPLOAD_CONTRACTORS_CLOSE,
    //payload: data
});

export const excelUploadContractorsSave = (data) => ({
    type: EXCEL_UPLOAD_CONTRACTORS_SAVE,
    payload: data
});  

export const excelUploadContractorsSaveResult = (result, progress) => ({
    type: EXCEL_UPLOAD_CONTRACTORS_SAVE_RESULT,
    payload: {result, progress}
});

export const getContractors = () => ({
  type: EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE
});  

export const getContractorsSaveResult = (contractors) => ({
  type: EXCEL_UPLOAD_CONTRACTORS_GET_CONTRACTORS_SAVE_RESULT,
  payload: contractors
});  