let locales = {
    login_email: 'Correo',
    login_username: 'Usuario',
    login_password: 'Contraseña',
    login_register: 'Registrarse',
    login_title: 'Bienvenido,{br}vamos a iniciar!!',
    login_subtitle: 'Ingrese con sus credenciales.',
    login_subtitle2: 'Si no es miembro, por favor  ',
    login_singin: 'Iniciar',
    //---------------------------------
    login_forgotPassword: 'Olvidé la contraseña',
    login_forgotPassword_title: 'Recuperar contraseña',
    login_forgotPassword_description: 'Por favor ingrese su correo y le enviaremos una notificación con su contraseña.',
    login_forgotPassword_sendCode: 'Generar Código',
    login_forgotPassword_sendCodeSuccces: 'Código de seguridad enviado',
    login_forgotPassword_verifyCode: 'Ingrese el código recibido.',
    login_forgotPassword_verifyButton: 'Validar Código',
    login_forgotPassword_verifyCodeSuccces: 'Verificación correcta, se ha cambiado la contraseña.',
    login_forgotPassword_verifyCodeError: 'El código ingresado no es valido',
    login_forgotPassword_newPassword: 'Ingresar nueva contraseña',
    login_forgotPassword_newPassword_error: 'Las contraseñas deben concidir.',
    login_forgotPassword_verifyPassword: 'Confirmar contraseña',
    login_forgotPassword_success_title: 'Se ha cambiado la contraseña correctamente',
    login_forgotPassword_success_descripcion: '',
    login_loginCorreoDoesntExists: "No existe el usuario con el correo especificado."
};

export default locales;