//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    EDIT_SEGUIMIENTO_OPEN,
    EDIT_SEGUIMIENTO_SAVE
} from 'config/redux/actionTypes';

//actions
import {
    editSeguimientoOpenResult,
    editSeguimientoSaveResult,
    getCatalog
} from 'config/redux/actions';


function* editSeguimientoOpenRequest() {
    yield takeEvery(EDIT_SEGUIMIENTO_OPEN, function* ({ payload }) {
        try {
            const { id } = payload;

            if (!id) {
                yield put(editSeguimientoOpenResult())
                return
            }

            let data = yield call(
                clientQuery,
                `query getSeguimientoById($id: Int){
                    seguimiento: getSeguimientoById(id: $id){
                        IdSeguimiento
                        IdCliente
                        IdCarro
                        IdCiudadOrigen
                        IdCiudadDestino
                        Orden
                        Manifiesto
                        Aseguradora
                        Ruta
                        Kilometros
                        TiempoEstimado
                        Restriccion
                        Link
                        Observacion
                        FechaSalida
                        FechaLlegada
                        IdEstado
                        NombreCond
                        CelularCond
                        IdAcompanante
                        FechaCreacion
                    }
                }`,
                {
                    id: id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(editSeguimientoOpenResult(data.seguimiento))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editSeguimiento/redux/saga', 'editSeguimientoOpenRequest');
            yield put(editSeguimientoOpenResult());

        }
    });
}

function* editSeguimientoSaveRequest() {
    yield takeEvery(EDIT_SEGUIMIENTO_SAVE, function* ({ payload }) {
        try {
            const { data } = payload;
            let query
            console.log("payload:", payload)

            if (data.IdSeguimiento) {
                query = yield call(
                    clientMutation,
                    `mutation updateSeguimiento(
                        $IdSeguimiento: Int!
                        $IdCliente: Int
                        $IdCarro: Int
                        $IdCiudadOrigen: Int
                        $IdCiudadDestino: Int
                        $Orden: String
                        $Manifiesto: String
                        $Aseguradora: String
                        $Ruta: String
                        $Kilometros: String
                        $TiempoEstimado: String
                        $Restriccion: String
                        $Observacion: String
                        $FechaSalida: Datetime
                        $FechaLlegada: Datetime
                        $NombreCond: String
                        $CelularCond: String
                        $Link: String
                        $IdAcompanante: Int
                        $IdEstado: Int
                        $isDelete: Boolean
                      ){
                    seguimiento: updateSeguimiento(
                        IdSeguimiento: $IdSeguimiento
                        IdCliente: $IdCliente
                        IdCarro: $IdCarro
                        IdCiudadOrigen: $IdCiudadOrigen
                        IdCiudadDestino: $IdCiudadDestino
                        Orden: $Orden
                        Manifiesto: $Manifiesto
                        Aseguradora: $Aseguradora
                        Ruta: $Ruta
                        Kilometros: $Kilometros
                        TiempoEstimado: $TiempoEstimado
                        Restriccion: $Restriccion
                        Observacion: $Observacion
                        FechaSalida: $FechaSalida
                        FechaLlegada: $FechaLlegada
                        NombreCond: $NombreCond
                        Link: $Link
                        CelularCond: $CelularCond
                        IdAcompanante: $IdAcompanante
                        IdEstado: $IdEstado
                        isDelete: $isDelete
                        ){
                        IdSeguimiento
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editSeguimientoSaveResult({ status: true }));

            }
            else {
                //create
                query = yield call(
                    clientMutation,
                    `mutation createSeguimiento(
                        $IdCliente: Int
                        $IdCarro: Int
                        $IdCiudadOrigen: Int
                        $IdCiudadDestino: Int
                        $Orden: String
                        $Manifiesto: String
                        $Aseguradora: String
                        $Ruta: String
                        $Kilometros: String
                        $TiempoEstimado: String
                        $Restriccion: String
                        $Observacion: String
                        $FechaSalida: Datetime
                        $FechaLlegada: Datetime
                        $NombreCond: String
                        $Link: String
                        $CelularCond: String
                        $IdAcompanante: Int
                        $IdEstado: Int
                      ){
                    seguimiento: createSeguimiento(
                        IdCliente: $IdCliente
                        IdCarro: $IdCarro
                        IdCiudadOrigen: $IdCiudadOrigen
                        IdCiudadDestino: $IdCiudadDestino
                        Orden: $Orden
                        Manifiesto: $Manifiesto
                        Aseguradora: $Aseguradora
                        Ruta: $Ruta
                        Kilometros: $Kilometros
                        TiempoEstimado: $TiempoEstimado
                        Restriccion: $Restriccion
                        Observacion: $Observacion
                        FechaSalida: $FechaSalida
                        FechaLlegada: $FechaLlegada
                        NombreCond: $NombreCond
                        CelularCond: $CelularCond
                        Link: $Link
                        IdAcompanante: $IdAcompanante
                        IdEstado: $IdEstado
                        ){
                        IdSeguimiento
                    }
                }`,
                    data,
                    endpoints.GRAPHQL_GENERAL
                );

                yield put(editSeguimientoSaveResult({ status: true, id: query.seguimiento.IdSeguimiento }));

            }


        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/components/editSeguimiento/redux/saga', 'editSeguimientoSaveRequest');
            yield put(editSeguimientoSaveResult({ status: false, error: exc.error }));

        }
    });
}


//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(editSeguimientoOpenRequest),
        fork(editSeguimientoSaveRequest)
    ]);
}
