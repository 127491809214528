import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

const COLOMBIA_LOCATION = {
    lat: 4.205345,
    lng: -74.3390061
}

const GoogleMaps = (props, ref) => {

    const { onLoadMap } = props
    const mapRef = useRef(null);

    const [mapObj, setMapObj] = useState();
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        if (mapRef.current && !mapObj) {
            const mapValue = new window.google.maps.Map(mapRef.current, { center: COLOMBIA_LOCATION, zoom: 5.8 })
            setMapObj(mapValue);
            onLoadMap && onLoadMap(mapFunctions(mapValue))
        }
    }, [mapRef, mapObj]);


    const mapFunctions = (currentMap) => {
        let map = mapObj || currentMap
        return (
            {
                map: map,
                addKML: (src) => {
                    new google.maps.KmlLayer(src, {
                        //suppressInfoWindows: true,
                        //preserveViewport: false,
                        map: map
                    })
                },
                addMarker: (id, position, title, infoWindowContent, icon) => {
                    let marker = markers.find(x => x.id == id);
                    const infowindow = new google.maps.InfoWindow({
                        content: infoWindowContent || `<div>${title}</div>`,
                    });
                    if (marker) {
                        marker.value.setMap(map);
                        marker.value.setOptions({ position, title, icon })
                    }
                    else {
                        marker = {
                            id: id,
                            value: new google.maps.Marker({
                                position,
                                map,
                                title,
                                icon
                            })
                        }
                        marker.value.addListener("click", () => { infowindow.open({ anchor: marker.value, map }); })
                        markers.push(marker)
                        setMarkers(markers)
                    }
                },
                centerMarker: (id) => {
                    let marker = markers.find(x => x.id == id);
                    if (marker) {
                        map.setCenter(marker.value.getPosition())
                        map.setZoom(12)
                    }
                },
                clearMarkers: (ids = []) => {
                    if (markers && markers.length > 0) {
                        markers
                        .filter(v => (ids && ids.length > 0) ? ids.includes(v.id) : v)
                        .forEach(marker => marker.value.setMap(null))
                        let markersLeft = (ids && ids.length > 0) 
                                            ? markers.filter(v => !ids.includes(v.id)) 
                                            : []
                        setMarkers(markersLeft)
                    }
                     
                },
                clearZoom: () => {
                  map?.setZoom(8);
                },
                addClickEvent: (onClick) => {
                    if (map) {
                        google.maps.event.clearListeners(map, 'click')
                        map.addListener("click", (e) => {
                            onClick && onClick(e)
                        });
                    }
                }
            })
    }

    useImperativeHandle(ref, () => mapFunctions())


    return (
        <div ref={mapRef} style={{ width: '100%', height: '100%', minHeight: 300 }}></div>
    )
}

export default forwardRef(GoogleMaps);