let locales = {
    clientList_title: 'Clientes',
    clientList_add: 'Agregar Cliente',
    clientList_uploadExcel: 'Cargar por excel',
    clientList_socialReason: 'Razón Social',
    clientList_nit: 'NIT',
    clientList_phone: 'Teléfono',
    clientList_address: 'Dirección',
    clientList_email: 'Correo',
    clientList_country: 'País',
    clientList_city: 'Ciudad',
    clientList_status: 'Estado',
};

export default locales;