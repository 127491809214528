let locales = {
    vehiclesList_title: 'Vehículos',
    vehiclesList_add: 'Agregar vehículo',
    vehiclesList_uploadExcel: 'Cargar excel',
    vehiclesList_client: 'Empresa',
    vehiclesList_plate: 'Placa',
    vehiclesList_brand: 'Marca',
    vehiclesList_class: 'Clase',
    vehiclesList_model: 'Modelo',
    vehiclesList_color: 'Color',
    vehiclesList_capacity: 'Capacidad',
    vehiclesList_container: 'Contenedor',
    vehiclesList_serials: 'Seriales',
    vehiclesList_accompanist: 'Escolta',
    vehiclesList_status: 'Estado',
};

export default locales;