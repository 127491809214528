import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import store from './config/redux/store'
import { Provider } from 'react-redux'

import MainRouter from './containers/main'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'assets/sass/styles.scss';

const App = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path='/' component={MainRouter} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
