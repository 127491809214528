//imports
import { all } from 'redux-saga/effects';

import userListSagas from '../../views/usersList/redux/sagas';
import clientListSagas from '../../views/clientsList/redux/sagas';
import vehiclesListSagas from '../../views/vehiclesList/redux/sagas';
import accompanistsListSagas from '../../views/accompanistsList/redux/sagas';
import editUserSagas from '../../components/editUser/redux/sagas';
import editVehicleSagas from '../../components/editVehicle/redux/sagas';
import editAccompanistSagas from '../../components/editAccompanist/redux/sagas';
import editClientSagas from '../../components/editClient/redux/sagas';
import excelUploadSagas from '../../components/excelUpload/redux/sagas';
import excelUploadContractorsSagas from '../../components/excelUploadContractors/redux/sagas';
import vehiclesDetailSagas from '../../views/vehicleDetail/redux/sagas';
import vehicleAddFileSagas from '../../views/vehicleDetail/components/addFile/redux/sagas';
import vehicleFileSagas from '../../views/vehicleDetail/components/files/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        userListSagas(),
        editUserSagas(),
        excelUploadSagas(),
        excelUploadContractorsSagas(),
        clientListSagas(),
        editClientSagas(),
        editVehicleSagas(),
        vehiclesListSagas(),
        editAccompanistSagas(),
        accompanistsListSagas(),
        vehiclesDetailSagas(),
        vehicleAddFileSagas(),
        vehicleFileSagas(),
    ]);
}
