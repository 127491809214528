let locales = {
    vehicleDetail_title: "Detalle de vehículo",
    vehicleDetail_detailInfo: "Información general",
    vehicleDetail_addPhotos: "Añadir fotos",
    vehicleDetail_editVehicle: "Editar vehículo",
    vehicleDetail_history: "Historial",
    vehicleDetail_files: "Documentos",
    //vehicleDetail_: "",
};

export default locales;