import {
    VEHICLE_DETAIL_GET_DETAIL,
    VEHICLE_DETAIL_GET_DETAIL_RESULT,
    VEHICLE_DETAIL_GET_FILES,
    VEHICLE_DETAIL_GET_FILES_RESULT,
    CLEAR_REDUX,
} from 'config/redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
    loadingFiles: false,
    dataFiles: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case VEHICLE_DETAIL_GET_DETAIL:
            return { ...state, loading: true, data: null, error: null };

        case VEHICLE_DETAIL_GET_DETAIL_RESULT:
            return { ...state, loading: false, data: action?.payload?.result, error: action?.payload.error};

        case VEHICLE_DETAIL_GET_FILES:
            return{...state, loadingFiles: true, dataFiles: null, errorFiles: null}

        case VEHICLE_DETAIL_GET_FILES_RESULT:
            return { ...state, loadingFiles: false, dataFiles: action?.payload?.response, error: action?.payload.error };

        case CLEAR_REDUX:
            return ["", "VEHICLE_DETAIL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : state

        default:
            return state;
    }
};
