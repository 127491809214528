import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AutContainer from 'containers/auth'
import Sidebar from 'containers/sidebar';
import { validateRoles } from 'config/helpers/Utils'

import AdminRouter from './admin'
import DashboardRouter from './dashboard'
import TrackRouter from './track';

import { Wrapper, Status } from "@googlemaps/react-wrapper";

const ModulesRouter = ({ history, match }) => {
    const theme = useSelector(state => state.themeRedux)
    const { user } = useSelector(state => state.authRedux)

    //console.log("theme", theme)
    const html = document.querySelector('html');
    Object.keys(theme).forEach(key => {
        if (theme[key])
            html.setAttribute(key, theme[key])
        else
            html.removeAttribute(key)
    });

    const render = (status) => {
        console.log("Status", status)
        return <h1>{status}</h1>;
    };
    return (
        <AutContainer history={history}>
            {user &&
                <>
                    <Sidebar history={history} />
                    <Wrapper apiKey="AIzaSyC9bc-uXvBd3Pbsv0yU54YDLzS5f-qxw7I" render={render}>
                        <main>
                            <Switch>
                                <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
                                <Route path={`${match.url}/dashboard`} component={DashboardRouter} />
                                {
                                    validateRoles([1,2], user) &&
                                    <Route path={`${match.url}/admin`} component={AdminRouter} />
                                }
                                {
                                    //validateRoles([1, 2, 3, 4, 5, 6, 7, 8], user) &&
                                    <Route path={`${match.url}/track`} component={TrackRouter} />
                                }
                                {/* <Route path={`${match.url}/notification`} component={NotificationRouter} /> */}
                                <Redirect to="/error" />
                            </Switch>
                        </main>
                    </Wrapper>
                </>
            }
        </AutContainer>
    )
}

export default ModulesRouter;