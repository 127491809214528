let locales = {
    seguimientoDetail_title: "Detalle de Seguimiento",
    seguimientoDetail_titleAdd: "Añadir detalle de Seguimiento",
    seguimientoDetail_titleFinish: "Terminar Seguimiento",
    seguimientoDetail_titleFinish_message: "¿Desea terminar el seguimiento sobre este vehículo?",
    seguimientoDetail_titleEdit: "Editar Seguimiento",
    seguimientoDetail_client: 'Empresa',
    seguimientoDetail_order: "Sello Satelital",
    seguimientoDetail_vehicle: 'Vehículo',
    seguimientoDetail_manifest: 'Manifiesto',
    seguimientoDetail_insurance: 'Aseguradora',
    seguimientoDetail_creationDate: 'Fecha Creación',
    seguimientoDetail_creationUser: 'Creado por',
    seguimientoDetail_originCity: 'Ciudad Origen',
    seguimientoDetail_destinyCity: 'Ciudad Destino',
    seguimientoDetail_route: 'Ruta',
    seguimientoDetail_kilometers: 'Kilómetros',
    seguimientoDetail_estimatedTime: 'Tiempo estimado',
    seguimientoDetail_startDate: 'Fecha Salida',
    seguimientoDetail_endDate: 'Fecha Llegada',
    seguimientoDetail_driverName: 'Nombre',
    seguimientoDetail_driverPhone: 'Teléfono',
    seguimientoDetail_accompanist: 'Escolta',
    seguimientoDetail_accompanistVehicle: 'Vehículo',
    seguimientoDetail_restriction: 'Restricción',
    seguimientoDetail_observation: 'Observación',
    seguimientoDetail_status: 'Estado',
    seguimientoDetail_link: 'Link',
    seguimientoDetail_title_generalInfo: "Información General",
    seguimientoDetail_title_track: "Trayecto a seguir",
    seguimientoDetail_title_seguimientoDates: "Fechas de Seguimiento",
    seguimientoDetail_title_driverInfo: "Información del Conductor",
    seguimientoDetail_title_accompanistInfo: "Información del Escolta",

    seguimientoDetail_tabledetails_user: "Usuario",
    seguimientoDetail_tabledetails_creationDate: "Fecha Creación",
    seguimientoDetail_tabledetails_reportPoint: "Punto de Reporte",
    seguimientoDetail_tabledetails_withoutNovedad: "Novedad",
    seguimientoDetail_tabledetails_observation: "Observación",

    seguimientoDetail_tabFiles: "Documentos",
    seguimientoDetail_resources: 'Recursos'
};

export default locales;