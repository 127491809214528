let locales = {
    excelUploadContractors_templateExample: `El archivo excel debe cumplir con la siguiente 
            plantilla: Razón Social, NIT, Ciudad, Dirección, 
            Correo y Teléfono correspondientes a la empresa contratista. 
            Cada uno de los campos es obligatorio, no debe estar vacío y debe 
            cumplir con sus respectivas validaciones (Razón Social sin caracteres especiales y con máximo 50, 
            número NIT válido, ciudad existente, dirección con máximo 20 caracteres, correo válido y con máximo 50 caracteres y teléfono válido,. 
            Los registros no deben estar repetidos).`,
    excelUpload_companyAlreadyExists: "La Compañía ya existe",
    excelUpload_cityDoesntExists: "Ciudad inexistente o mal escrita",
    excelUpload_invalidPhoneNumber: "Teléfono inválido",
    excelUpload_invalidNITNumber: "Número NIT inválido",
    excelUpload_invalidCompanyName: "Nombre de compañía inválido",
    excelUpload_invalidAddress: "Dirección inválida",

    excelUploadContractors_validDataRow: "Compañia válida",
};

export default locales;