//imports
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { clientQuery, clientMutation } from "config/helpers/GraphQLApi";
import { endpoints } from "config/constants";

import { ExceptionManager } from "config/helpers/Logging";

//action types
import {
  GET_LASTEST_SEGUIMIENTOS
} from "config/redux/actionTypes";

//actions
import {
  getLatestWorkPermissionNewsResult
} from "config/redux/actions";

function* getLatestSeguimientosRequest() {
  yield takeEvery(GET_LASTEST_SEGUIMIENTOS, function* ({ payload }) {
    try {
      const { openSearch, limit, offset, status } = payload;

      let data = yield call(
        clientQuery,
        `query searchSeguimientos(
          $openSearch: String, 
          $limit: Int, 
          $offset: Int,
          $status: Int
          $dashboard: Int
          ){
          seguimientos: searchSeguimientos(
              openSearch: $openSearch,
              limit: $limit,
              offset: $offset,
              status: $status
              dashboard: $dashboard
          ){
              count
              items{
                IdSeguimiento
                AcompananteInfo {
                  Nombre
                  Cedula
                }
                ClienteInfo{
                    RazonSocial
                }
                CarroInfo{
                    Placa
                }
                SemaforoColor
                CiudadOrigenInfo{
                    CityName
                }
                CiudadDestinoInfo{
                    CityName
                }
                NombreCond
                CelularCond
                Orden
                Manifiesto
                Restriccion
                Link
                FechaSalida
                FechaLlegada
                EstadoInfo{
                    IdEstado
                    NombreEstado
                }
                FechaCreacion
                UltimoDetalleSeguimiento{
                  PuntoReporte
                  FechaHoraReporte
                  SN
                  Observacion
                  Latitude
                  Longitude
                }
              }
          }
      }`,
        {
          openSearch, limit, offset, status, dashboard: 1
        },
        endpoints.GRAPHQL_GENERAL
      );
        yield put(getLatestWorkPermissionNewsResult(data.seguimientos));
    } catch (exc) {
      ExceptionManager(
        exc,
        "modules/dashboard/views/main/redux/sagas",
        "getLatestSeguimientosRequest"
      );
      yield put(getLatestWorkPermissionNewsResult({status: false, error: exc.error}));
    }
  });
}




//MERGE SAGA
export default function* rootSaga() {
  yield all([
    fork(getLatestSeguimientosRequest)
  ]);
}
