import {
    //TYPES
    VEHICLE_LIST_SEARCH,
    VEHICLE_LIST_SEARCH_RESULT
} from 'config/redux/actionTypes';

export const vehicleListSearch = (openSearch, limit, offset, status) => ({
    type: VEHICLE_LIST_SEARCH,
    payload: { openSearch, limit, offset, status },
});

export const vehicleListSearchResult = (data) => ({
    type: VEHICLE_LIST_SEARCH_RESULT,
    payload: data,
});
