//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'config/helpers/GraphQLApi';
import { endpoints } from 'config/constants';

import { ExceptionManager } from 'config/helpers/Logging'

//action types
import {
    CLIENT_LIST_SEARCH
} from 'config/redux/actionTypes';

//actions
import {
    clientListSearchResult,
    clearRedux
} from 'config/redux/actions';


function* clientListSearchRequest() {
    yield takeEvery(CLIENT_LIST_SEARCH, function* ({ payload }) {
        try {
            const { openSearch, limit, offset, status } = payload;
            let data = yield call(
                clientQuery,
                `query searchClients(
                    $openSearch: String, 
                    $limit: Int, 
                    $offset: Int,
                    $status: Int,
                    ){
                    clients: searchClients(
                        openSearch: $openSearch,
                        limit: $limit,
                        offset: $offset,
                        status: $status,
                    ){
                        count
                        items{
                            IdCliente
                            RazonSocial
                            Nit
                            Telefono
                            Direccion
                            Correo
                            PaisId
                            estado 
                            Ciudad{
                                CityName
                            }
                            UsuarioCreacionInfo {
                                Nombre
                            }
                        }
                    }
                }`,
                {
                    openSearch,
                    limit,
                    offset,
                    status
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(clientListSearchResult(data.clients || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/admin/views/clientsList/redux/saga', 'clientListSearchRequest');
            yield put(clientListSearchResult([]));
        }
    });
}

//MERGE SAGA
export default function* rootSaga() {
    yield all([
        fork(clientListSearchRequest)
    ]);
}
