import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader
} from "antd";
import { InputFile } from "components";

import { getCatalog, editUserClose, editUserSave, clearRedux } from "config/redux/actions";

import "./styles.css"
import { useCitiesCascader } from "../../../../config/helpers/Hooks";

//otros componentes

const EditUserComp = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [id, setId] = useState();
  const [clientId, setClientId] = useState(0);
  const [cityId, setCityId] = useState(0);


  const { isOpen, data, loading, result, isDelete } = useSelector(
    (state) => state.editUserRedux
  );
  const { loadingRoles, roles, clients, loadingClients } = useSelector(
    (state) => state.commonRedux
  );

  const isEdit = data ? true : false;

  const {cascaderOptions, cities, loadingCities, states, loadingStates} = useCitiesCascader();


  useEffect(() => {
    dispatch(getCatalog(["roles", "clients"])); 
  }, [])
  
  //carga inicial de un valor de edicion
  useEffect(() => {
    if (data && !id) {
      //Se guarda el id
      setId(data.id);
      //Se hace set al formulario
      setCityId(data?.CiudadId);
      form.setFields([
        { name: "Nombre", value: data?.Nombre },
        { name: "Identificacion", value: data?.Identificacion },
        { name: "IdCliente", value: data?.IdCliente },
        { name: "Correo", value: data?.Correo },
        { name: "Telefono", value: data?.Telefono },
        { name: "Cargo", value: data?.Cargo },
        { name: "CiudadId", value: [cities.find(v => v.CityCode == data?.CiudadId)?.DepartmentCode, data?.CiudadId]},
        { name: "IdRol", value: data?.IdRol },
      ]);
    }
  }, [data]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result.status) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });
        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(editUserClose());
    form.resetFields();
    setId();
    if (refresh) onClose(refresh);
  });


  return (
    <Modal
      title={
        isDelete ? messages["editUser_titleDelete"] : isEdit ? messages["editUser_titleUpdate"] : messages["editUser_title"]
      }
      visible={isOpen}
      onOk={() => {}}
      onCancel={() => close()}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin
                tip="Loading..."
                spinning={loading || loadingRoles || loadingClients || loadingCities || loadingStates}
              >
                {
                  isDelete
                  ? <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(
                      editUserSave({ IdUsuario: data?.IdUsuario}, true)
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <p>
                        {messages["editUser_userWishDesactivate"]}
                      </p>
                    </div>
                  </div>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                      loading={loading}
                      danger
                      type="primary"
                      htmlType="submit"
                      className="btn btn-danger btn-end"
                    >
                      {messages["delete"]}
                    </Button>
                  </div>
                </Form>
                  :<Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(
                      editUserSave({
                        ...values,
                        IdUsuario: data?.IdUsuario,
                        CiudadId: cityId,
                      }
                      )
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Nombre"
                        label={messages["editUser_name"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Correo"
                        label={messages["editUser_email"]}
                        rules={[
                          { type: "email", message: messages["invalid_email"] },
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Identificacion"
                        label={messages["editUser_identification"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input disabled={isEdit} />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Telefono"
                        label={messages["editUser_phone"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                    <Form.Item
                        name="IdCliente"
                        label={messages["editUser_client"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Select
                        onChange={(v) => {
                          setClientId(v);
                          v === 1 && form.setFields([{name: "IdRol", value: 1}]);
                          v !== 1 && form.setFields([{name: "IdRol", value: 3}]);
                        }}
                        >
                            {clients &&
                              clients.map((client, index) => {
                                return (
                                  <Select.Option key={index} value={client.IdCliente}>
                                    {client.RazonSocial}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Cargo"
                        label={messages["editUser_position"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input
                          minLength={1}
                          maxLength={11}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="CiudadId"
                        label={messages["editUser_city"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                         <Cascader 
                          showSearch={{filter: (inputValue, path) => path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,)}} 
                          options={cascaderOptions} 
                          onChange={v => v !== null && v !== undefined && v.length>1 && setCityId(prev => v[1])} 
                          />
                      </Form.Item>
                    </div>
                      <div className="col-12 col-md-6">
                        <Form.Item
                          name="IdRol"
                          label={messages["editUser_rol"]}
                          rules={[
                            {
                              required: true,
                              message: messages["required_field"],
                            },
                          ]}
                        >
                          <Select
                          value={clientId == 1 && 1}
                          disabled={clientId == 1}
                          >
                            {roles &&
                              roles.map((rol, index) => {
                                return (
                                  <Select.Option key={index} value={rol.IdRol} disabled={rol.IdRol == 1 && clientId !== 1} >
                                    {rol.Rol}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button
                      loading={loading || loadingRoles}
                      type="primary"
                      className="btn btn-alternate btn-end"
                      onClick={() => close()}
                    >
                      {messages["cancel"]}
                    </Button>
                    <Button
                      loading={loading || loadingRoles}
                      type="primary"
                      htmlType="submit"
                      className="btn btn-primary btn-end"
                    >
                      {messages["save"]}
                    </Button>
                  </div>
                </Form>
                }
                
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserComp;
