import React, {useState} from "react";
import SidePanel from "components/SidePanel";
import { useIntl } from "react-intl";

import { Button } from "antd";

export default function SidePanelFilters({loading, onRefreshTable, searchTitle, children, onClear, onSearch}) {
  const { messages } = useIntl();
  const [search, setSearch] = useState('')
  return (
    <>
      <SidePanel>
        <div className="container">
          <div className="col mb-3 h5 fw-bold"
            style={{ color: "#00BC64", borderBottom: "1px solid #00BC64" }}
          >
            {messages["inductionlist_filters"]}
          </div>
          <div className="col-12 pb-1">
            {searchTitle && <div className="col-12">
              <div 
                className="pb-1"
              >
                {searchTitle}:
              </div>
              <input
                disabled={loading}
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={messages["search"]}
              />
            </div>}
            <div className="row">
              {children}
            </div>
            <div className="col-12 pt-3">
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                disabled={loading}
                onClick={() => {
                  onSearch && onSearch()
                  onRefreshTable(search, 10, 0)}}
                className="btn btn-primary btn-end"
              >
                {messages["search"]}
              </Button>
              <Button
                type="primary"
                className="btn btn-primary btn-end"
                disabled={loading}
                onClick={() => {
                  onClear && onClear();
                  onRefreshTable('', 10, 0);
                  setSearch('');
                }}
              >
                {messages["clear"]}
              </Button>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
}
