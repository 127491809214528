let locales = {
    editAccompanist_title: 'Crear Escolta',
    editAccompanist_titleUpdate: 'Editar Escolta',
    editAccompanist_name: 'Nombre',
    editAccompanist_identification: 'Cédula',
    editAccompanist_phone: 'Teléfono',
    editAccompanist_vehicle: 'Vehículo',
    editAccompanist_accompanistExists: 'El vehículo ya se encuentra registrado',
};

export default locales;